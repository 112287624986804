import React from 'react';

const ErrorNotice = (props) => {
    return (
        <div
            className='error-notice'
            style={{ padding: '0.5em 0', color: 'rgb(243, 158, 29)' }}>
            <span>{props.message}</span>
        </div>
    );
};

export default ErrorNotice;
