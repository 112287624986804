import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import axios from "../../../misc/modifiedAxios";
import CreateThemeModal from '../../Theme-Components/CreateThemeModal/create-theme-modal.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import styled from 'styled-components'
import ErrorCatch from '../../Shared/Error/error-catch';
import ThemeItem from '../ThemeItem/theme-item.component';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
import Subthemes from '../Subthemes/subthemes.component'

import './theme-display.styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import EditThemeModal from '../EditThemeModal/edit-theme-modal-component'


const Styles = styled.div`
  padding: 1rem;

  
  table {
    width:100%;
    border-spacing: 0;
    // border: 1px solid black;
    }
   tr:nth-child(even){background-color: #f2f2f2;}
   tr:hover {background-color: #ddd;}

    th{
        border-bottom: 1px solid black;
    },

    td {
      margin: 0;
      padding: 0.5rem;
    //   border-bottom: 1px solid black;
    //   border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const ThemeDisplay = () => {

    const [themes, setThemes] = useState([])
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);


    const organisationId = useSelector((state) => state.user.organisationId);

    const dispatch = useDispatch()

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            try {
                setIsLoading(true)
                const response = await axios.get(
                    `/themes`, {
                    signal: controller.signal
                }
                ).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })
                if (response.data) {
                    setThemes(response.data)
                    controller = null;
                    setIsLoading(false)
                }

                console.log('response.data', response)


            } catch (err) {
                console.log(err)
            }
        })();
        return () => controller?.abort();

    }, [organisationId]);

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change
        // if (themes.length > 0) {

        const setThemesFromRequest = () => {
            dispatch({
                type: 'SET_THEMES',
                payload: themes
            })
        }

        setThemesFromRequest();
        // }

    }, [themes, dispatch]);


    //EDIT cohort details modal state
    const [showCreateModal, setCreateShowModal] = useState(false);
    const [showEditModal, setEditShowModal] = useState(false);
    const [subthemeOpen, setSubthemeOpen] = useState(false);


    const themeData = useSelector((state) => state.theme.themes);




    return (


        !subthemeOpen ? <div className='theme-display'>

            <h1 className='theme-display-header'>
                Themes
            </h1>

            <div className="create-theme-button-container" >
                <FontAwesomeIcon icon={faPlusCircle} className="add-theme-button" onClick={() => {
                    setCreateShowModal(true);
                }} />
                <span className="add-theme-text">Create New Theme</span>
            </div>

            {isLoading ? (
                <div className='spinner'>
                    <SpinnerCircularFixed
                        size={60}
                        thickness={140}
                        speed={120}
                        color='var(--spinner-color)'
                        secondaryColor='rgb(240, 240, 240)
												'
                    />
                    <div className='spinner-text'>
                        <span>Loading Themes...</span>
                    </div>
                </div>
            ) : (

                isDeleting ? (
                    <div className='spinner'>
                        <SpinnerCircularFixed
                            size={60}
                            thickness={140}
                            speed={120}
                            color='var(--spinner-color)'
                            secondaryColor='rgb(240, 240, 240)
                                '
                        />
                        <div className='spinner-text'>
                            <span>Deleting Theme...</span>
                        </div>
                    </div>
                ) : (
                    <Styles>
                        <table style={{ width: "100%", maxWidth: "97%" }}>
                            <thead>
                                <tr>
                                    {/* <th>#</th> */}
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Summary</th>
                                    <th>Subthemes</th>
                                    <th> </th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>


                                {themeData.map(({ id, theme_name, theme_desc, theme_summary, child_themes, sys_default }) =>
                                    <ThemeItem
                                        id={id}
                                        key={id}
                                        theme_name={theme_name}
                                        theme_desc={theme_desc}
                                        theme_summary={theme_summary}
                                        child_themes={child_themes}
                                        sys_default={sys_default}
                                        setSubthemeOpen={setSubthemeOpen}
                                        setEditShowModal={setEditShowModal}
                                        setIsDeleting={setIsDeleting}
                                    />)}


                            </tbody>
                            <CreateThemeModal
                                setShowModal={setCreateShowModal}
                                showModal={showCreateModal}
                            />
                            {showEditModal ? <EditThemeModal
                                setEditShowModal={setEditShowModal}
                                showEditModal={showEditModal} /> : null}
                        </table>
                    </Styles>)
            )}
        </div > : <Subthemes setSubthemeOpen={setSubthemeOpen} />
    );

};

export default ThemeDisplay;




