import React from 'react';

import './subthemes.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components'

import SubthemeItem from '../SubthemeItem/subtheme-item.component'
import CreateSubthemeModal from '../CreateSubthemeModal/create-subtheme-modal.component';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
// import CohortSelection from '../../components/CohortSelection/cohort-selection.component';
import EditSubthemeModal from '../EditSubthemeModal/edit-subtheme-modal-component'


const Styles = styled.div`
  padding: 1rem;

  
  table {
    width:100%;
    border-spacing: 0;
    // border: 1px solid black;
    }
   tr:nth-child(even){background-color: #f2f2f2;}
   tr:hover {background-color: #ddd;}

    th{
        border-bottom: 1px solid black;
    },

    td {
      margin: 0;
      padding: 0.5rem;
    //   border-bottom: 1px solid black;
    //   border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const Subthemes = ({ setSubthemeOpen }) => {

    const currentTheme = useSelector((state) => state.theme.selectedTheme);


    const { child_themes } = currentTheme

    const [showCreateModal, setCreateShowModal] = useState(false);
    const [showEditModal, setEditShowModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);



    return (
        <div className='subthemes'>
            <h1 className='theme-display-header'>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} className="back-button" onClick={() => {
                    // window.location.reload()
                    setSubthemeOpen(false)

                }} />
                {currentTheme.theme_name} - Subthemes
            </h1>

            {currentTheme.sys_default ? null : <div className="create-theme-button-container" >
                <FontAwesomeIcon icon={faPlusCircle} className="add-theme-button" onClick={() => {
                    setCreateShowModal(true);
                }} />
                <span className="add-theme-text">Create New Subtheme</span>
            </div>}
            {isDeleting ? (
                <div className='spinner'>
                    <SpinnerCircularFixed
                        size={60}
                        thickness={140}
                        speed={120}
                        color='var(--spinner-color)'
                        secondaryColor='rgb(240, 240, 240)
                                '
                    />
                    <div className='spinner-text'>
                        <span>Deleting Subtheme...</span>
                    </div>
                </div>
            ) : (
                <Styles>
                    <table style={{ width: "100%", maxWidth: "97%" }}>
                        <thead>
                            <tr>
                                {/* <th>#</th> */}
                                <th>Name</th>
                                <th>Description</th>
                                <th>Summary</th>
                                <th> </th>
                                <th> </th>
                            </tr>
                        </thead>
                        <tbody>

                            {child_themes ? child_themes.map(({ id, sys_default, theme_name, theme_desc, theme_summary }) =>
                                <SubthemeItem
                                    id={id}
                                    key={id}
                                    theme_name={theme_name}
                                    theme_desc={theme_desc}
                                    theme_summary={theme_summary}
                                    sys_default={sys_default}
                                    setEditShowModal={setEditShowModal}
                                    setIsDeleting={setIsDeleting}

                                />
                            ) : null}
                            {showEditModal ? <EditSubthemeModal
                                setEditShowModal={setEditShowModal}
                                themeId={currentTheme.id}
                                showEditModal={showEditModal} /> : null}
                        </tbody>
                        <CreateSubthemeModal
                            setShowModal={setCreateShowModal}
                            showModal={showCreateModal}
                            themeId={currentTheme.id}
                        />
                    </table>
                </Styles>)}


        </div>
    );
};

export default Subthemes;