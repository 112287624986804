import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useRowSelect, useMountedLayoutEffect } from 'react-table'
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// import '../../../Question-Components/QuestionTable/question-table.styles.scss';
import { ColumnFilter } from '../../../QM-Components/ThemeQuestionTable/column-filter.component';
import { DropdownFilter } from '../../../QM-Components/ThemeQuestionTable/q-type-dropdown-filter.component';
import { ThemeDropDown } from '../../../QM-Components/ThemeQuestionTable/theme.dropdown.component';
import { SubthemeDropdown } from '../../../QM-Components/ThemeQuestionTable/subtheme.dropdown.component';
import { ReverseDropdown } from '../../../QM-Components/ThemeQuestionTable/reverse-coded.dropdown.component';


const Styles = styled.div`
padding: 1rem;

overflow-x: auto;
table {
  width:100%;
  border-spacing: 0;
  // border: 1px solid black;
  }
 tr:nth-child(even){background-color: #f2f2f2;}
 tr:hover {background-color: #ddd;}

  th{
      border-bottom: 1px solid black;
      width: 5em
  },

  td {
    margin: 0;
    padding: 0.5rem;
  //   border-bottom: 1px solid black;
  //   border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
`
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

//FILTERS

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}


// const INITIAL_SELECTED_ROW_IDS = {};

function Table({ columns, data, masterData, setMasterData, sectionId }) {
    // Use the state and functions returned from useTable to build your UI

    const [tableData, setTableData] = React.useState([])

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    // const INITIAL_SELECTED_ROW_IDS = {};


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        selectedFlatRows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setAllFilters,
        toggleRowSelected,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data: tableData,

        // initialState: {
        //     pageIndex: 0,
        //     selectedRowIds: selectedRows,
        //     // selectedRowIds: INITIAL_SELECTED_ROW_IDS
        // },
        // autoResetPage: false,
        // autoResetFilters: false,
        // autoResetHiddenColumns: false,

        defaultColumn, // Be sure to pass the defaultColumn option


    }, useFilters, useGlobalFilter, // useGlobalFilter!
        usePagination, useRowSelect,



        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }


    )

    React.useEffect(() => {
        // RG: from masterData, figure out all the available questions + this section's chosenQuestions

        let section = masterData.sections.filter(section => section.sectionId == sectionId)[0]

        let allChosenQuestionsInAllSections = []
        for (let i = 0; i < masterData.sections.length; i++) {
            allChosenQuestionsInAllSections = [...allChosenQuestionsInAllSections, ...masterData.sections[i].chosenQuestions]
        }

        // console.log("allChosenQuestionsInAllSections", allChosenQuestionsInAllSections)

        // RG: Need to compare question ids and not questions.
        let allChosenQuestionIdsInAllSections = allChosenQuestionsInAllSections.map((question) => question.id)

        let allRemainingQuestions = masterData.allAvailableQuestions.filter((question) => {
            return !allChosenQuestionIdsInAllSections.includes(question.id)
        })

        // console.log("allRemainingQuestions", allRemainingQuestions)

        let tableData = [...section.chosenQuestions, ...allRemainingQuestions]
        // console.log("tableData", tableData)

        setTableData(tableData)

    }, [masterData])


    useMountedLayoutEffect(() => {
        // RG: Go through all the rows in the table and select the rows with the same question.id in section.chosenQuestions
        let section = masterData.sections.filter(section => section.sectionId == sectionId)[0]
        let chosenQuestionIds = section.chosenQuestions.map(question => question.id)

        rows.forEach((row) => {
            if (chosenQuestionIds.includes(row.original.id)) {
                toggleRowSelected(row.id, true)
            }
        })

    }, [rows]);


    function saveSelection(e) {
        e.preventDefault();
        // RG: get the selected questions from the selected rows. initially, it should be an empty list if no rows are selected.
        let chosenQuestions = selectedFlatRows.map(row => row.original)
        let chosenQuestionIds = selectedFlatRows.map(row => row.original.id)

        // RG: find the section in masterData corresponding to the sectionId and add the chosenQuestions to that section
        masterData.sections.map((section) => {
            if (section.sectionId == sectionId) {
                section.chosenQuestions = chosenQuestions
            }
        })

        setMasterData({
            allAvailableQuestions: data,
            sections: masterData.sections
        })

    }


    return (
        <>
            {/* RG: This button emulates the "Save" functionality */}
            <div className='selected-question-amount'>Selected Questions: {selectedFlatRows.length}</div>

            <Button style={{ marginBottom: '1em', backgroundColor: 'var(--modal-create-button)', border: 'none' }} onClick={saveSelection}>
                Save Selection
            </Button>
            <table {...getTableProps()}>


                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <br />

            <div className="pagination">
                <div className='pagination-buttons'>
                    <div className='button-container'>
                        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </Button>{' '}
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </Button>{' '}
                        <Button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </Button>{' '}
                        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </Button>{' '}
                    </div>
                    <div className='go-to-container'>
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <span>
                            <strong>| Go to page:{' '} </strong>
                        </span>
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                    </div>
                    <div className='dropdown-container'>
                        <select
                            value={pageSize}
                            className="form-control"
                            // style={{ width: '100px' }}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>

                </div>
            </div>

        </>
    )

}

function SurveyInEditQuestionTable({ sectionId, masterData, setMasterData, setEditModal, setChosenQuestions, chosenQuestions }) {

    const dispatch = useDispatch()

    const [question, setQuestion] = useState(null)



    const questions = masterData.allAvailableQuestions


    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (question !== null) {
            const addNewQuestion = () => {
                dispatch({
                    type: 'SELECT_QUESTION',
                    payload: question
                })
            }

            addNewQuestion();
        }

    }, [dispatch, question]);


    const columnsReady = React.useMemo(

        () => [


            {


                Header: 'Question',
                accessor: 'question_text',
                Filter: ColumnFilter,
                // Cell: ({ cell }) => {
                //     /* Add data-tip */
                //     // return <span data-tip={row.value}>{row.value}</span>;

                //     if (cell.row.original.question_type === 'likert_scale') {

                //         return <>
                //             <OverlayTrigger
                //                 key={cell.row.original.id}
                //                 placement={'right'}
                //                 overlay={
                //                     <Tooltip id={cell.row.original.id} >
                //                         <div className="params">
                //                             <span><strong >Min label:</strong>  {cell.row.original.likert_scale?.min_label}</span>
                //                             <span><strong >Min value:</strong>  {cell.row.original.likert_scale?.min_value}</span>
                //                             <span><strong >Max label:</strong> {cell.row.original.likert_scale?.max_label}</span>
                //                             <span><strong >Max value:</strong>  {cell.row.original.likert_scale?.max_value}</span>
                //                         </div>
                //                     </Tooltip>
                //                 }
                //             >
                //                 <span>{cell.row.original.question_text}</span>
                //             </OverlayTrigger>
                //         </>;
                //     }

                //     if (cell.row.original.question_type === 'mcq' || cell.row.original.question_type === 'checkbox' || cell.row.original.question_type === 'dropdown') {

                //         const choices = cell.row.original?.choices?.map(choice => choice.value)


                //         return <>
                //             <OverlayTrigger
                //                 key={cell.row.original.id}
                //                 placement={'right'}
                //                 overlay={
                //                     <Tooltip id={cell.row.original.id} >
                //                         <div className="params">
                //                             <span><strong >Choices:</strong></span>
                //                             {choices?.join(", ")}
                //                         </div>
                //                     </Tooltip>
                //                 }
                //             >
                //                 <span>{cell.row.original.question_text}</span>
                //             </OverlayTrigger>
                //         </>;
                //     }

                //     if (cell.row.original.question_type === 'short_answer' || cell.row.original.question_type === 'paragraph') {

                //         return <span>{cell.row.original.question_text}</span>
                //     }


                //     if (cell.row.original.question_type === 'grid_mcq') {



                //         const rows = cell.row.original?.grid_choices?.filter(i => i.axis === 'row').map(value => {
                //             if (value.axis === 'row') {
                //                 return value.value
                //             }

                //             return null

                //         })


                //         const columns = cell.row.original?.grid_choices?.filter(i => i.axis === 'col').map(value => {
                //             if (value.axis === 'col') {
                //                 return value.value
                //             }

                //             return null

                //         })

                //         return <>
                //             <OverlayTrigger
                //                 key={cell.row.original.id}
                //                 placement={'right'}
                //                 overlay={
                //                     <Tooltip id={cell.row.original.id} >
                //                         <div className="params">
                //                             <span><strong >Rows: </strong>{rows?.join(", ")}</span>
                //                             <span><strong >Columns: </strong>  {columns?.join(", ")}</span>
                //                         </div>
                //                     </Tooltip>
                //                 }
                //             >
                //                 <span>{cell.row.original.question_text}</span>
                //             </OverlayTrigger>
                //         </>;
                //     }


                //     return <span>{cell.row.original.question_text}</span>

                // }

            },
            {
                Header: 'Theme',
                // defaultCanFilter: true,
                // disableFilters: true,
                Filter: ThemeDropDown,
                accessor: 'parent_theme',
                filter: 'equals',
                Cell: ({ cell }) => {

                    //ORIGINAL
                    // if (cell.row.original.theme && cell.row.original.parent_theme) {
                    //     return cell.row.original.parent_theme.theme_name
                    // }
                    // if (cell.row.original.theme && !cell.row.original.parent_theme) {
                    //     return cell.row.original.theme.theme_name
                    // }

                    //JuST FLATTENED
                    // if (cell.row.original.theme && cell.row.original.parent_theme) {
                    //     return cell.row.original.parent_theme
                    // }
                    // if (cell.row.original.theme && !cell.row.original.parent_theme) {
                    //     return cell.row.original.theme
                    // }

                    //FLATTENED & ALWAYS parent

                    if (cell.row.original.parent_theme) {
                        return cell.row.original.parent_theme
                    }
                    // if (cell.row.original.theme && !cell.row.original.parent_theme) {
                    //     return cell.row.original.theme
                    // }
                    return ''


                }
            },
            {
                Header: 'Subtheme',
                Filter: SubthemeDropdown,
                // disableFilters: true,

                accessor: 'theme',
                Cell: ({ cell }) => {



                    // if (cell.row.original.theme && cell.row.original.parent_theme) {
                    //     return cell.row.original.theme.theme_name
                    // }
                    if (cell.row.original.theme && cell.row.original.parent_theme) {
                        return cell.row.original.theme
                    }
                    return ''


                }
            },
            {
                Header: 'Perspective',
                accessor: 'objective',
                Filter: ReverseDropdown,
                filter: 'equals',
                // disableFilters: true,
                Cell: ({ cell }) => {


                    if (cell.row.original.objective) {
                        return 'Objective'
                    }

                    return 'Subjective'

                }
            },

            {
                Header: 'Question Type',
                accessor: 'question_type', //NEED to change to CELL so that switches likert_scale to Liker Scale returns jsx
                // disableFilters: true,
                Filter: DropdownFilter,
                filter: 'equals',
                Cell: ({ cell }) => {

                    if (cell.row.original.question_type === 'likert_scale') {
                        return 'Likert scale'
                    }

                    if (cell.row.original.question_type === 'mcq') {
                        return 'Multiple Choice'
                    }

                    if (cell.row.original.question_type === 'checkbox') {
                        return 'Checkbox'
                    }

                    if (cell.row.original.question_type === 'dropdown') {
                        return 'Dropdown'
                    }
                    if (cell.row.original.question_type === 'paragraph') {
                        return 'Paragraph'
                    }
                    if (cell.row.original.question_type === 'short_answer') {
                        return 'Short Answer'
                    }

                    if (cell.row.original.question_type === 'grid_mcq') {
                        return 'Grid Multiple Choice'
                    }

                    return null //to be replaced by other question type conditionals and null wont happen

                }
            },



        ],
        [setEditModal]
    )



    // let convertedRowIds
    // if (chosenQuestions?.length > 0) {
    //     convertedRowIds = chosenQuestions.map(x => x?.rowId
    //     )

    // }

    // let currentRows
    // if (convertedRowIds) {
    //     currentRows = convertedRowIds.reduce((a, v) => ({ ...a, [v]: true }), {})
    // } else {
    //     currentRows = {}
    // }


    // const [selectedRows, setSelectedRows] = useState(currentRows);






    return (
        <>
            <Styles>
                <Table
                    dispatch={dispatch}
                    columns={columnsReady}
                    data={questions}
                    setMasterData={setMasterData}
                    masterData={masterData}
                    sectionId={sectionId}

                />
                {/* <button onClick={handleResetFilters}>RESET</button> */}
            </Styles>
        </>
    )
}

export default SurveyInEditQuestionTable
