import React from 'react';
import EmpirisysSenseLogo from '../assets/EmpirisysSenseLogo.svg';
import SCISLogo from '../assets/SCISLogo.svg';
import '../pages/Form/Form.scss';

const FormDisclaimer = (props) => {
    return (
        <div className='disclaimer-container'>
            <span className='disclaimer-text'>Never submit authentication data such as logins and passwords through Empirisys Sense surveys.</span>
            <div className='disclaimer-logos-container'>
                {/* <img className='scis-logo' src={SCISLogo} alt='scis_logo' /> */}
                <img className='empirisys-sense-logo' src={EmpirisysSenseLogo} alt='empirisys_sense_logo' />
            </div>
        </div>
    );
};

export default FormDisclaimer;
