import React, { useState, useEffect, useRef } from 'react';
// import Axios from 'axios';
import { useHistory } from "react-router-dom";
import axios, { cancelToken } from "../../../misc/modifiedAxios";
import { Modal, Button, FormCheck } from 'react-bootstrap';
import Toggle from 'react-toggle'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ErrorCatch from '../../Shared/Error/error-catch';
import { useDispatch, useSelector } from 'react-redux'

import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
import "react-datepicker/dist/react-datepicker.css";
import "./edit-question.styles.scss"
import "../../../_vars.scss"
import EditLikertScaleQuestion from './edit-likert-question/edit-likert-question.component';
import EditChoiceQuestion from './edit-choice-question/edit-choice-questions.component'
import EditGridChoiceQuestion from './edit-grid-question/edit-grid-questions.component'
import EditGridLikertQuestion from './edit-grid-likert-question/edit-grid-likert-questions.component';

const EditQuestionModal = ({ showEditModal, setEditModal }) => {

    const history = useHistory()
    const CancelToken = useRef(cancelToken)
    const cancel = useRef(undefined)

    const [key, setKey] = useState('type'); //Tab initial view


    const [isLoading, setisLoading] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const hasQuestion = useSelector((state) => state.question.selectedQuestion);
    const organisationId = useSelector((state) => state.user.organisationId);
    const selectedTheme = useSelector((state) => state.theme.selectedTheme);


    let selectedQuestion

    if (hasQuestion === null) {
        selectedQuestion = ''
    } else {
        selectedQuestion = hasQuestion
    }


    const questionGroups = useSelector((state) => state.questionGroup.questionGroups);
    const themes = useSelector((state) => state.theme.themes);
    // const [selectedSubthemeId, setSelectedSubthemeId] = useState(null) //initialSelectedSubtheme?.id



    const questionId = selectedQuestion?.id

    let likertScaleParamsId


    if (selectedQuestion?.question_type === 'likert_scale') {
        likertScaleParamsId = selectedQuestion?.likert_scale?.id
    }

    const [currentQuestionDetails, setcurrentQuestionDetails] = useState({
        question_text: '',
        question_type: '',
        question_group: '', //gone
        objective: '', //objective
        start_date: '',//gone
        end_date: '',//gone
        theme: '',
        parent_theme: '',
        theme_id: null,
        parent_theme_id: null,
        locked: null,
    })




    /// SHARED QUESTION DATA
    //reverse_coded_flag
    const { question_text, question_type, objective, start_date, end_date, theme, parent_theme, theme_id, parent_theme_id, locked } = currentQuestionDetails


    /// DATE FORMATTING
    let formatStartDate
    let formatEndDate

    if (start_date) {
        formatStartDate = new Date(start_date)
    }

    if (end_date) {
        formatEndDate = new Date(end_date)
    }


    const dispatch = useDispatch()
    const [updatedQuestionSelection, setUpdatedQuestionSelection] = useState(null)
    const [createDuplicateQuestion, setCreateDuplicateQuestion] = useState(null)

    const [isEnabled, setEnabled] = useState(true)

    //########################################### FILTERING OPTIONS FOR SELECT DROPDOWNS #############################################

    //FOR QUESTION TYPE SELECTION

    // const options = ['Likert scale', 'Multiple Choice', 'Checkbox', 'Dropdown', 'Paragraph', 'Short Answer']

    const mapedOptions = [{
        value: 'Likert scale',
        question_type: 'likert_scale'
    }, {
        value: 'Multiple Choice',
        question_type: 'mcq',
    }, {
        value: 'Checkbox',
        question_type: 'checkbox'
    }, {
        value: 'Dropdown',
        question_type: 'dropdown',
    }, {
        value: 'Paragraph',
        question_type: 'paragraph'
    }, {
        value: 'Short Answer',
        question_type: 'short_answer'
    },
    {
        value: 'Grid Multiple Choice',
        question_type: 'grid_mcq'
    },
    {
        value: 'Grid Likert scale',
        question_type: 'grid_likert_scale'
    }
    ]



    const filteredOptions = mapedOptions.filter((option => option.question_type !== question_type))


    let currentOption

    for (let i = 0; i < mapedOptions.length; i++) {
        if (mapedOptions[i].question_type === question_type) {
            currentOption = mapedOptions[i].value
        }
    }


    let withUnassignedTheme = [...themes, { theme_name: 'None Selected', id: -1 }]

    const filteredThemes = withUnassignedTheme.filter(x => x.id !== parent_theme_id && x.type !== 'all')

    // console.log('@@ Filtered THemes', filteredThemes)

    const requiredTheme = withUnassignedTheme.filter((currentTheme => currentTheme?.id === parent_theme_id))

    // console.log('@@ requiredTheme', requiredTheme)

    const subthemes = requiredTheme[0]?.child_themes?.map(x => {
        if (x.type === 'unassigned') {
            return { ...x, id: -3 }
        }
        return x
    })

    let withUnassignedSubtheme

    if (subthemes) {
        if (theme === 'unassigned') {
            withUnassignedSubtheme = [...subthemes]

        } else {
            withUnassignedSubtheme = [...subthemes, { theme_name: 'None Selected', id: -3 }]
        }
    }


    // console.log('@@ subthemes', subthemes)

    let filteredSubthemes = withUnassignedSubtheme?.filter((option => option.id !== theme_id))

    // console.log('@@ filteredSubthemes', filteredSubthemes)

    //#########################################################################################################################################




    //_________LIKERT SCALE_________________


    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(7)
    const [startLabel, setStartLabel] = useState('Strongly Disagree')
    const [endLabel, setEndLabel] = useState('Strongly Agree')
    const [reverse, setReverse] = useState(false)
    const [notApplicable, setNotApplicable] = useState(false)


    //_________CHOICE__________________________
    // const [choices, setChoices] = useState([])
    const [formValues, setFormValues] = useState([{ value: "" }])
    // const values = { ...formValues.map((value) => value.choice) } //maps through object into a list, then spreads that list into a new object, using index as key
    // const values = formValues.map((value) => value.choice)

    const values = formValues?.map((value, key) => ({ ...value, order: key }))


    //_________GRID____________________________

    const [rowValues, setRowValues] = useState([{ value: "" }])
    const [columnValues, setColumnValues] = useState([{ value: "" }])


    const rows = rowValues?.map(row => ({ ...row, axis: 'row', order: rowValues.indexOf(row) }))
    const columns = columnValues?.map(column => ({ ...column, axis: 'col', order: columnValues.indexOf(column) }))

    const merged = [...rows, ...columns]


    const gridValues = merged.map(value => {

        if (value.axis === 'row') {
            if (value.id) {
                return { id: value.id, axis: 'row', value: value.value, order: value.order, }
            }
            return { axis: 'row', value: value.value, order: value.order, }
        }

        if (value.axis === 'col') {
            if (value.id) {
                return { id: value.id, axis: 'col', value: value.value, order: value.order }
            }
            return { axis: 'col', value: value.value, order: value.order }
        }

        return null
    }
    )

    const duplicate = async () => {

        let themeId


        if (currentQuestionDetails?.parent_theme_id === null || currentQuestionDetails?.parent_theme_id === -1) {

            themeId = null

        }


        if (currentQuestionDetails?.parent_theme_id !== null && currentQuestionDetails?.parent_theme_id !== -1

        ) {


            if (currentQuestionDetails?.theme_id !== null && currentQuestionDetails?.theme_id !== -3) {

                themeId = parseInt(currentQuestionDetails?.theme_id)

                console.log('REAL THEME ID', themeId, currentQuestionDetails)
            } else {
                themeId = currentQuestionDetails?.parent_theme_id
            }
        }





        let duplicateName
        if (currentQuestionDetails.question_text === selectedQuestion.question_text) {
            duplicateName = '[DUPLICATE] ' + selectedQuestion.question_text
        }
        if (currentQuestionDetails.question_text !== selectedQuestion.question_text) {
            duplicateName = currentQuestionDetails.question_text
        }


        let duplicateQuestion


        // const something = true

        try {
            setisLoading(true)
            setIsDuplicate(true)
            if (currentQuestionDetails.question_type === 'likert_scale') {
                // if (something) {



                //________________LIEKRT SCALE________________
                duplicateQuestion =
                {
                    data: {

                        // id: questionId,
                        question_text: duplicateName,
                        reverse_coded_flag: reverse,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        theme: themeId,
                        // theme: currentQuestionDetails.theme.id,

                        likert_scale:
                        {
                            // id: likertScaleParamsId,
                            min_value: minValue,
                            max_value: maxValue,
                            min_label: startLabel,
                            max_label: endLabel,
                            na_flag: notApplicable,
                        },
                        choices: [],
                        grid_choices: [],
                        ogs: organisationId,

                    }
                }

            }
            //________________________________________________




            //________________CHOICE _______________
            if (currentQuestionDetails.question_type === 'mcq' || currentQuestionDetails.question_type === 'checkbox' || currentQuestionDetails.question_type === 'dropdown') {


                duplicateQuestion =
                {
                    data: {


                        question_text: duplicateName,
                        // reverse_coded_flag: currentQuestionDetails.reverse_coded_flag,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        theme: themeId,
                        // theme: currentQuestionDetails.theme.id,
                        // likert_scale: null,
                        choices: values.map(x => {
                            return {
                                order: x.order,
                                value: x.value
                            }
                        }),
                        likert_scale: null,
                        grid_choices: [],
                        ogs: organisationId,
                    }
                }

            }
            if (currentQuestionDetails.question_type === 'paragraph' || currentQuestionDetails.question_type === 'short_answer') {

                //________________PARAGRAPH________________
                duplicateQuestion =
                {

                    data: {
                        question_text: duplicateName,
                        // reverse_coded_flag: currentQuestionDetails.reverse_coded_flag,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        // theme: currentQuestionDetails.theme.id,
                        theme: themeId,
                        choices: [],
                        likert_scale: null,
                        grid_choices: [],
                        ogs: organisationId
                        // question_params: 27
                    }
                }

            }
            //________________GRID______________________


            if (currentQuestionDetails.question_type === 'grid_mcq') {


                duplicateQuestion =
                {
                    data: {
                        question_text: duplicateName,
                        reverse_coded_flag: reverse,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        // theme: currentQuestionDetails.theme.id,
                        theme: themeId,
                        grid_choices: gridValues.map(x => {
                            return {
                                axis: x.axis,
                                order: x.order,
                                value: x.value
                            }
                        }),
                        choices: [],
                        likert_scale: null,
                        ogs: organisationId
                    }
                }

            }

            if (currentQuestionDetails.question_type === 'grid_likert_scale') {


                duplicateQuestion =
                {
                    data: {
                        question_text: duplicateName,
                        reverse_coded_flag: reverse,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        // theme: currentQuestionDetails.theme.id,
                        theme: themeId,
                        grid_likert_scale: {

                            row_choices: gridValues.filter(function (obj) {
                                return obj.axis !== 'col';
                            }),//gridValues,
                            likert_scale:
                            {
                                min_value: minValue,
                                max_value: maxValue,
                                min_label: startLabel,
                                max_label: endLabel
                            },
                        },
                        grid_choices: [],
                        choices: [],
                        likert_scale: null,
                        ogs: organisationId
                    }
                }

            }




            let response

            if (!themeId) {


                if (currentQuestionDetails.question_type && duplicateName) {
                    response = await axios.post(
                        `/questions`,
                        duplicateQuestion
                    ).catch(

                        (error) => {
                            if (error.response.status === 403) {
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')
                            }

                            if (error.response.status === 401) {

                                alert("Session has expired, please re-login.")
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')

                            }
                            // <ErrorCatch error={error} />
                        })

                }



                setEnabled(true)

                setFormValues([{ value: "" }])



                const { id, question_type, question_group, question_text, reverse_coded_flag, objective, start_date, end_date, theme, parent_theme } = response.data

                let likert_scale

                if (response.data.question_type === 'likert_scale') {
                    likert_scale = response.data.likert_scale

                    setCreateDuplicateQuestion({
                        id,
                        question_type,
                        question_text,
                        question_group,
                        reverse_coded_flag,
                        objective,
                        theme,
                        parent_theme,
                        start_date,
                        end_date,
                        likert_scale,
                    })

                }

                let choices
                if (response.data.question_type === 'mcq' || response.data.question_type === 'checkbox' || response.data.question_type === 'dropdown') {
                    choices = response.data.choices

                    setCreateDuplicateQuestion({
                        id,
                        question_type,
                        question_group,
                        question_text,
                        // reverse_coded_flag,
                        objective,
                        theme,
                        parent_theme,
                        start_date,
                        end_date,
                        choices
                    })

                }

                if (response.data.question_type === 'paragraph' || response.data.question_type === 'short_answer') {

                    setCreateDuplicateQuestion({
                        id,
                        question_type,
                        question_group,
                        question_text,
                        // reverse_coded_flag,
                        objective,
                        theme,
                        parent_theme,
                        start_date,
                        end_date,
                    })

                }

                let grid_choices
                if (response.data.question_type === 'grid_mcq') {

                    grid_choices = response.data.grid_choices

                    setCreateDuplicateQuestion({
                        id,
                        question_type,
                        question_group,
                        question_text,
                        reverse_coded_flag,
                        objective,
                        theme,
                        parent_theme,
                        start_date,
                        end_date,
                        grid_choices
                    })
                }

                let grid_likert_scale

                if (response.data.question_type === 'grid_likert_scale') {

                    grid_choices = response.data.grid_choices
                    grid_likert_scale = response.data.grid_likert_scale

                    setCreateDuplicateQuestion({
                        id,
                        question_type,
                        question_group,
                        question_text,
                        reverse_coded_flag,
                        objective,
                        theme,
                        parent_theme,
                        start_date,
                        end_date,
                        grid_choices,
                        grid_likert_scale
                    })
                }

            }


            //_______________________ else UPDATE THEME...____________________
            if (themeId) {


                console.log('themeId', themeId)
                let updateThemeNewQuestion

                if (currentQuestionDetails?.theme_id !== null || currentQuestionDetails?.theme_id !== -3) {
                    updateThemeNewQuestion = {
                        questionOp: "create",
                        parentThemeID: currentQuestionDetails?.parent_theme_id,
                        ...duplicateQuestion
                    }

                } else {
                    updateThemeNewQuestion = {
                        questionOp: "create",
                        ...duplicateQuestion
                    }
                }


                response = await axios.put(
                    `/themes/questionOp/${themeId}`,
                    updateThemeNewQuestion//editedTheme
                ).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })


                // const { theme_name, theme_desc, theme_summary, id, parent_theme, child_themes } = response.data


                console.log('response PUT', response)

                setCreateDuplicateQuestion(

                    response.data
                )


                setEnabled(true)

                setFormValues([{ value: "" }])


            }
            setisLoading(false)
            setIsDuplicate(false)
            setEditModal(false);

        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }

    }

    const submit = async (e) => {

        e.preventDefault();
        setEnabled(false)


        let newQuestion




        try {
            setisLoading(true)
            setIsDuplicate(false)




            if (currentQuestionDetails.question_type === 'likert_scale') {


                console.log('REACHED currentQuestionDetails', currentQuestionDetails)
                newQuestion =
                {
                    questionOp: 'update',
                    questionID: questionId,
                    data: {
                        question_text: currentQuestionDetails.question_text,
                        reverse_coded_flag: reverse,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        // theme: themeId,
                        theme: currentQuestionDetails.theme_id && currentQuestionDetails.theme_id !== -3 ?
                            currentQuestionDetails.theme_id :
                            currentQuestionDetails.parent_theme_id && currentQuestionDetails.parent_theme_id !== -1 ?
                                currentQuestionDetails.parent_theme_id : null,

                        likert_scale:
                        {
                            id: likertScaleParamsId,
                            min_value: minValue,
                            max_value: maxValue,
                            min_label: startLabel,
                            max_label: endLabel,
                            na_flag: notApplicable,

                        },
                        choices: [],
                        grid_choices: [],
                    }
                }

            }

            //________________CHOICE _______________
            if (currentQuestionDetails.question_type === 'mcq' || currentQuestionDetails.question_type === 'checkbox' || currentQuestionDetails.question_type === 'dropdown') {


                newQuestion =
                {

                    questionOp: 'update',
                    questionID: questionId,
                    data: {
                        // id: questionId,
                        question_text: currentQuestionDetails.question_text,
                        reverse_coded_flag: reverse,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        // theme: themeId,
                        theme: currentQuestionDetails.theme_id && currentQuestionDetails.theme_id !== -3 ?
                            currentQuestionDetails.theme_id :
                            currentQuestionDetails.parent_theme_id && currentQuestionDetails.parent_theme_id !== -1 ?
                                currentQuestionDetails.parent_theme_id : null,
                        // theme: currentQuestionDetails.theme.id,
                        // likert_scale: null,
                        choices: values,
                        likert_scale: null,
                        grid_choices: [],
                    }
                }

            }
            if (currentQuestionDetails.question_type === 'paragraph' || currentQuestionDetails.question_type === 'short_answer') {

                //________________PARAGRAPH________________
                newQuestion =
                {

                    questionOp: 'update',
                    questionID: questionId,
                    data: {
                        // id: questionId,
                        question_text: currentQuestionDetails.question_text,
                        // reverse_coded_flag: currentQuestionDetails.reverse_coded_flag,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        // theme: currentQuestionDetails.theme.id,
                        // theme: themeId,
                        theme: currentQuestionDetails.theme_id && currentQuestionDetails.theme_id !== -3 ?
                            currentQuestionDetails.theme_id :
                            currentQuestionDetails.parent_theme_id && currentQuestionDetails.parent_theme_id !== -1 ?
                                currentQuestionDetails.parent_theme_id : null,
                        // question_params: 27
                        likert_scale: null,
                        grid_choices: [],
                        choices: [],
                    }
                }

            }
            //________________GRID______________________


            if (currentQuestionDetails.question_type === 'grid_mcq') {


                newQuestion =
                {
                    questionOp: 'update',
                    questionID: questionId,
                    data: {
                        // id: questionId,
                        question_text: currentQuestionDetails.question_text,
                        reverse_coded_flag: reverse,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        // theme: currentQuestionDetails.theme.id,
                        // theme: themeId,
                        theme: currentQuestionDetails.theme_id && currentQuestionDetails.theme_id !== -3 ?
                            currentQuestionDetails.theme_id :
                            currentQuestionDetails.parent_theme_id && currentQuestionDetails.parent_theme_id !== -1 ?
                                currentQuestionDetails.parent_theme_id : null,
                        grid_choices: gridValues,
                        likert_scale: null,
                        choices: [],
                    }
                }

            }


            if (currentQuestionDetails.question_type === 'grid_likert_scale') {


                newQuestion =
                {
                    questionOp: 'update',
                    questionID: questionId,
                    data: {
                        // id: questionId,
                        question_text: currentQuestionDetails.question_text,
                        reverse_coded_flag: reverse,
                        objective: currentQuestionDetails.objective,
                        // start_date: currentQuestionDetails.start_date,
                        // end_date: currentQuestionDetails.end_date,
                        question_type: currentQuestionDetails.question_type,
                        // question_group: currentQuestionDetails.question_group ? currentQuestionDetails.question_group.id : null,
                        // theme: currentQuestionDetails.theme.id,
                        // theme: themeId,
                        theme: currentQuestionDetails.theme_id && currentQuestionDetails.theme_id !== -3 ?
                            currentQuestionDetails.theme_id :
                            currentQuestionDetails.parent_theme_id && currentQuestionDetails.parent_theme_id !== -1 ?
                                currentQuestionDetails.parent_theme_id : null,

                        grid_likert_scale: {

                            row_choices: gridValues.filter(function (obj) {
                                return obj.axis !== 'col';
                            }),//gridValues,
                            likert_scale:
                            {
                                min_value: minValue,
                                max_value: maxValue,
                                min_label: startLabel,
                                max_label: endLabel
                            },
                        },
                        grid_choices: [],
                        likert_scale: null,
                        choices: [],
                    }
                }

            }





            let response


            if (selectedTheme?.type === 'regular') {

                if (currentQuestionDetails.question_type && currentQuestionDetails.question_text) {
                    response = await axios.put(
                        `themes/questionOp/${selectedTheme?.id}`,//   `/questions/${questionId}`,
                        newQuestion
                    ).catch(

                        (error) => {
                            if (error.response.status === 403) {
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')
                            }

                            if (error.response.status === 401) {

                                alert("Session has expired, please re-login.")
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')

                            }
                            // <ErrorCatch error={error} />
                        })

                }



                setEnabled(true)

                setFormValues([{ value: "" }])

                if (response?.data) {
                    setUpdatedQuestionSelection(response.data)
                }

            }
            if (selectedTheme?.type !== 'regular') {



                let updatedQuestion = {
                    data: {
                        ...newQuestion.data
                    }
                }


                response = await axios.put(
                    `/questions/${questionId}`,
                    updatedQuestion//editedTheme
                ).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })

                setEnabled(true)

                setFormValues([{ value: "" }])

                if (response?.data) {
                    setUpdatedQuestionSelection(response.data)
                }

            }


            setisLoading(false)
            setEditModal(false);

        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
            setEnabled(true)
        }
    };

    useEffect(() => {

        if (updatedQuestionSelection) {
            const updateQuestion = () => {


                if (selectedTheme?.type === 'regular') {

                    console.log('HIT HERE - regular')
                    // if (initialSelectedTheme?.type === 'all') {

                    let allSpecificThemesQuestions = updatedQuestionSelection?.child_themes.map(x => {
                        return x.questions
                    }).flat(1)


                    //needs ORDER FIX
                    dispatch({
                        type: 'SELECT_THEME',
                        payload: { ...selectedTheme, questions: [...allSpecificThemesQuestions, ...updatedQuestionSelection.questions] }
                    })
                    // }
                }

                if (selectedTheme?.type !== 'regular') {

                    console.log('HIT HERE - NOT regular')

                    // if (updatedQuestionSelection.parent_theme === 'unassigned') {

                    dispatch({
                        type: 'SELECT_THEME',
                        payload: { ...selectedTheme, questions: [updatedQuestionSelection, ...selectedTheme?.questions.filter(x => x.id !== updatedQuestionSelection.id)] }
                    })

                    return



                }

            }

            updateQuestion();
        }

    }, [dispatch, updatedQuestionSelection]);





    useEffect(() => {

        console.log('currentQuestionDetails', currentQuestionDetails)
        console.log('selectedTheme', selectedTheme)
        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (createDuplicateQuestion !== null) {
            const setDuplicateQuestion = () => {

                if (selectedTheme?.id === currentQuestionDetails?.parent_theme_id && currentQuestionDetails?.parent_theme_id > 0) {

                    console.log('HIT FOR NORMAL THEME OR SUBTHEME LEVEL', createDuplicateQuestion)

                    let allSpecificThemesQuestions = createDuplicateQuestion.child_themes.map(x => {
                        return x.questions
                    }).flat(1)


                    dispatch({
                        type: 'SELECT_THEME',
                        payload: { ...selectedTheme, questions: [...createDuplicateQuestion.questions, ...allSpecificThemesQuestions] }
                    })

                    return

                }

                if (selectedTheme?.type === 'all' && currentQuestionDetails?.parent_theme_id !== -1) {

                    console.log('HIT ALL QUESTIONS THEME LEVEL - ONLY FOR THEME/sub Qs', selectedTheme)

                    dispatch({
                        type: 'SELECT_THEME',
                        payload: { ...selectedTheme, questions: [createDuplicateQuestion.latestQuestion, ...selectedTheme.questions] }
                    })


                }


                //UNASSIGNED AND ALL both
                if (selectedTheme?.id < 0 && currentQuestionDetails?.parent_theme_id === -1) {

                    console.log('HIT UNASSIGNED, either from ALL QUESTIONS THEME OR UNASSIGNED THEME LEVEL', selectedTheme, currentQuestionDetails)


                    dispatch({
                        type: 'SELECT_THEME',
                        payload: { ...selectedTheme, questions: [createDuplicateQuestion, ...selectedTheme?.questions,] }
                    })

                }




            }

            setDuplicateQuestion();
        }

    }, [dispatch, createDuplicateQuestion]);




    const toggleImplicit = (e) => {
        setcurrentQuestionDetails({
            ...currentQuestionDetails, objective: !objective
        })

    }

    useEffect(() => {

        const loadQuestionDetails = async () => {

            try {

                if (selectedQuestion) {

                    if (cancel.current !== undefined) {//uses .current property as this is how you manipulate the object in useRef
                        cancel.current(); // same as running cancel() because once it is defined it is assigned a function with new CancelToken (also has .current)
                    }
                    setisLoading(true)

                    const response = await axios.get(
                        `/questions/${selectedQuestion?.id}`,
                        {
                            cancelToken: new CancelToken.current(c => {
                                cancel.current = c; // c is the identifier of the request, ON THE FIRST request it DEFINES the cancel variable, meaning
                            }),
                        }
                    ).catch(

                        (error) => {
                            if (error.response.status === 403) {
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')
                            }

                            if (error.response.status === 401) {

                                alert("Session has expired, please re-login.")
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')

                            }
                        }
                    );


                    if (response) {

                        console.log('response', response)

                        // setcurrentQuestionDetails(response.data)
                        setcurrentQuestionDetails({
                            ...response.data,
                            parent_theme_id: response.data.parent_theme === 'unassigned' ? -1 : response.data.parent_theme_id,
                            theme_id: response.data.theme === 'unassigned' ? -3 : response.data.theme_id
                        })

                        if (response.data?.locked) {
                            setEnabled(false)
                        }

                        if (response.data?.question_type === 'likert_scale') {
                            setMinValue(response.data?.likert_scale.min_value)
                            setMaxValue(response.data?.likert_scale.max_value)
                            setStartLabel(response.data?.likert_scale.min_label)
                            setEndLabel(response.data?.likert_scale.max_label)
                            setReverse(!response.data?.reverse_coded_flag ? false : true)
                            setNotApplicable(!response.data?.likert_scale?.na_flag ? false : true)
                        }

                        if (response.data?.question_type === 'mcq' || response.data?.question_type === 'checkbox' || response.data?.question_type === 'dropdown') {
                            // setFormValues(response.data?.choices)
                            setFormValues(response.data.choices)
                        }
                        if (response.data?.question_type === 'grid_mcq') {



                            const rows = response.data?.grid_choices?.filter(i => i.axis === 'row')


                            const columns = response.data?.grid_choices?.filter(i => i.axis === 'col')

                            setRowValues(rows)
                            setColumnValues(columns)
                            setReverse(!response.data?.reverse_coded_flag ? false : true)
                        }
                        if (response.data?.question_type === 'grid_likert_scale') {

                            setMinValue(response.data?.grid_likert_scale?.likert_scale.min_value)
                            setMaxValue(response.data?.grid_likert_scale?.likert_scale.max_value)
                            setStartLabel(response.data?.grid_likert_scale?.likert_scale.min_label)
                            setEndLabel(response.data?.grid_likert_scale?.likert_scale.max_label)

                            const rows = response.data?.grid_likert_scale?.row_choices?.filter(i => i.axis === 'row')

                            setRowValues(rows)
                            setReverse(!response.data?.reverse_coded_flag ? false : true)
                        }


                    }
                }

                setisLoading(false)

            } catch (err) {
                console.log(err)

            }

        }



        loadQuestionDetails();

    }, [history, selectedQuestion]);



    return (
        <Modal
            show={showEditModal}
            backdrop="static"
            size='lg'
            onHide={() => {
                setEditModal(false);

            }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Question</Modal.Title>
            </Modal.Header>

            <form onSubmit={submit}>
                <Modal.Body >
                    {isLoading ? (
                        <div className='spinner'>
                            <SpinnerCircularFixed
                                size={60}
                                thickness={140}
                                speed={120}
                                color='var(--spinner-color)'
                                secondaryColor='rgb(240, 240, 240)
                                            '
                            />
                            <div className='spinner-text'>
                                {isDuplicate ? <span>Creating Question...</span> : <span>Updating Question...</span>}
                            </div>
                        </div>
                    ) : (

                        <>
                            <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>

                                        {locked ? <Card style={{ background: 'var(--color-f)', marginBottom: '1em' }}>
                                            <Card.Body>This question cannot be edited, because it belongs to a live survey(-s).</Card.Body>
                                        </Card>
                                            : null}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='question-text-container'>
                                            <span className='question'>Question Text:</span>
                                            {question_type === 'short_answer' ? (<span className='short-answer'>Short Answer is limited to 250 charachters</span>) : (null)}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea
                                            required
                                            value={question_text}
                                            className='form-control section-inputs'
                                            maxLength='350'
                                            placeholder='max 250 characters'
                                            onChange={(e) => {

                                                // if (e.target.value.trim() !== '') {
                                                setcurrentQuestionDetails({
                                                    ...currentQuestionDetails, question_text: e.target.value.replace(
                                                        /[^\x20-\x7E]+/g,
                                                        ''
                                                    )
                                                })
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3 tabs-menu">

                                <Tab eventKey="type" title="Type">
                                    <Container
                                        fluid
                                    // className='box'
                                    >
                                        <Row>
                                            <Col>
                                                <span className='question'>Type:</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <select

                                                    type='text'
                                                    className='form-control question-modal-any-dropdown'
                                                    onChange={(e) => {
                                                        if (e.target.value === 'Likert scale')
                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails, question_type: 'likert_scale'
                                                            })

                                                        if (e.target.value === 'Multiple Choice')
                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails, question_type: 'mcq'
                                                            })

                                                        if (e.target.value === 'Checkbox')
                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails, question_type: 'checkbox'
                                                            })
                                                        if (e.target.value === 'Dropdown')
                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails, question_type: 'dropdown'
                                                            })
                                                        if (e.target.value === 'Paragraph')
                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails, question_type: 'paragraph'
                                                            })
                                                        if (e.target.value === 'Short Answer')
                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails, question_type: 'short_answer'
                                                            })
                                                        if (e.target.value === 'Grid Multiple Choice') {

                                                            setColumnValues([{ column_value: "" }])
                                                            setRowValues([{ row_value: "" }])
                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails, question_type: 'grid_mcq'
                                                            })
                                                        }
                                                        if (e.target.value === 'Grid Likert scale') {
                                                            setColumnValues([{ column_value: "" }])
                                                            setRowValues([{ row_value: "" }])
                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails, question_type: 'grid_likert_scale'
                                                            })
                                                        }

                                                        if (e.target.value === '')
                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails, question_type: ''
                                                            })
                                                    }}

                                                    style={{
                                                        marginBottom: '10px',
                                                        marginTop: '10px',
                                                        width: '30%',
                                                    }}>
                                                    <option>{currentOption}</option>
                                                    {filteredOptions.map((option) => {
                                                        return (
                                                            <option key={option.value} value={option.value}>
                                                                {option.value}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Container>




                                    {question_type === 'likert_scale' ?
                                        <EditLikertScaleQuestion
                                            setMinValue={setMinValue}
                                            setMaxValue={setMaxValue}
                                            minValue={minValue}
                                            maxValue={maxValue}
                                            setReverse={setReverse}
                                            reverse={reverse}
                                            setNotApplicable={setNotApplicable}
                                            notApplicable={notApplicable}
                                            setStartLabel={setStartLabel}
                                            setEndLabel={setEndLabel}
                                            startLabel={startLabel}
                                            endLabel={endLabel}
                                        /> : null
                                    }

                                    {question_type === 'mcq' || question_type === 'checkbox' || question_type === 'dropdown' ?
                                        <EditChoiceQuestion

                                            setFormValues={setFormValues}
                                            formValues={formValues} /> : null}


                                    {question_type === 'grid_mcq' ?
                                        (<EditGridChoiceQuestion
                                            setColumnValues={setColumnValues}
                                            setRowValues={setRowValues}
                                            rowValues={rowValues}
                                            columnValues={columnValues}
                                            setReverse={setReverse}
                                            reverse={reverse}
                                        />) : (null)}

                                    {question_type === 'grid_likert_scale' ? (

                                        <EditGridLikertQuestion

                                            setReverse={setReverse}
                                            reverse={reverse}
                                            // GRID ROW VALUES

                                            setRowValues={setRowValues}
                                            rowValues={rowValues}

                                            // LIKERT VALUES
                                            setMinValue={setMinValue}
                                            setMaxValue={setMaxValue}
                                            setStartLabel={setStartLabel}
                                            setEndLabel={setEndLabel}
                                            startLabel={startLabel}
                                            endLabel={endLabel}
                                            minValue={minValue}
                                            maxValue={maxValue}

                                        />) : (null)}


                                    <Container
                                        fluid
                                    // className='box'
                                    >
                                        <Row>
                                            <Col>
                                                <span className='question'>Perspective:</span>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>

                                                <input id="toggle-on" className="toggle toggle-left" name="siwtch" value="false" type="radio" defaultChecked={objective ? false : true} onChange={toggleImplicit} />
                                                <label htmlFor="toggle-on" className="btn">Subjective</label>
                                                <input id="toggle-off" className="toggle toggle-right" name="siwtch" value="true" type="radio" defaultChecked={objective} onChange={toggleImplicit} />
                                                <label htmlFor="toggle-off" className="btn">Objective</label>
                                            </Col>
                                        </Row>
                                    </Container>

                                </Tab>
                                <Tab eventKey="theme" title="Theme">


                                    <Container
                                        fluid

                                    >
                                        <Row>
                                            <Col>
                                                <span className='question'>Themes:</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <select
                                                    type='text'
                                                    className='form-control question-modal-any-dropdown'
                                                    onChange={(e) => {


                                                        let formattedTheme = JSON.parse(e.target.value)

                                                        setcurrentQuestionDetails({

                                                            ...currentQuestionDetails,
                                                            parent_theme: formattedTheme.theme_name === 'Unassigned' ? 'unassigned' : formattedTheme.theme_name,
                                                            parent_theme_id: formattedTheme.id,
                                                            theme: 'unassigned',
                                                            theme_id: -3
                                                        })
                                                    }

                                                    }
                                                    // }
                                                    selected=''
                                                    style={{
                                                        marginBottom: '10px',
                                                        marginTop: '10px',
                                                        width: '30%',
                                                    }}>
                                                    {/* WILL BE ACTUAL THEME NAME */}

                                                    {parent_theme === 'unassigned' ? <option value=''>None Selected</option> : <option>{parent_theme}</option>}
                                                    {filteredThemes.map((option) => {
                                                        return (

                                                            <option key={option?.id} value={JSON.stringify(
                                                                {
                                                                    theme_name: option?.theme_name,
                                                                    id: option?.id
                                                                })}>
                                                                {option?.theme_name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Container>
                                    {subthemes?.length > 0 ?

                                        <Container
                                            fluid
                                        // className='box'
                                        >
                                            <Row>
                                                <Col>
                                                    <span className='question'>Subthemes:</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <select
                                                        type='text'
                                                        className='form-control question-modal-any-dropdown'
                                                        onChange={(e) => {


                                                            let formattedTheme = JSON.parse(e.target.value)

                                                            setcurrentQuestionDetails({
                                                                ...currentQuestionDetails,
                                                                theme: formattedTheme.theme_name,
                                                                theme_id: formattedTheme.id,
                                                            })

                                                        }
                                                        }
                                                        // }
                                                        selected=''
                                                        style={{
                                                            marginBottom: '10px',
                                                            marginTop: '10px',
                                                            width: '30%',
                                                        }}>

                                                        {/* <option>{theme === 'unassigned' ? '' : theme}</option> */}
                                                        {theme === 'unassigned' ? <option value=''>None Selected</option> : <option>{theme}</option>}
                                                        {/* <option>{theme}</option> */}
                                                        {filteredSubthemes.map((option) => {
                                                            return (
                                                                <option key={option?.id} value={JSON.stringify(
                                                                    {
                                                                        theme_name: option?.theme_name,
                                                                        id: option?.id
                                                                    })}>
                                                                    {option?.theme_name}
                                                                </option>
                                                            );
                                                        })}
                                                        {/* {theme ? <option>{''}</option> : null} */}

                                                    </select>

                                                </Col>
                                            </Row>
                                        </Container> :
                                        <div className='empty-subthemes-message'>This Theme does not have Subthemes</div>}
                                </Tab>



                            </Tabs>

                        </>)}
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                    <Button
                        className="clear-button clear-button--secondary"
                        variant='no-variant'
                        onClick={() => {
                            duplicate();
                        }}
                    >
                        <span>Duplicate Question</span>
                    </Button>
                    <div style={{ display: 'flex', gap: '0.5em' }}>
                        <Button
                            variant='no-variant'
                            className='clear-button clear-button--secondary'
                            onClick={() => {
                                setEditModal(false);
                                setStartLabel('Strongly Disagree')
                                setEndLabel('Strongly Agree')
                                setReverse(false)
                                setNotApplicable(false)
                                setFormValues([{ value: "" }])

                            }}>
                            <span>Leave without saving</span>

                        </Button>
                        <Button
                            onSubmit={() => {
                                setEditModal(false);
                            }}
                            disabled={!isEnabled}
                            type="submit"
                            className='card-button'
                            variant='no-variant-p'>
                            <span>Save Changes</span>
                        </Button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditQuestionModal;
