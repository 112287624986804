import React, { useState } from 'react';


import { Card } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import dndIcon from '../../../../../../assets/dndicon.svg'
import editIcon from '../../../../../../assets/editicon.svg'
import trashIcon from '../../../../../../assets/trashicon.svg'
import dropdownIcon from '../../../../../../assets/dropdownicon.svg'
import copyIcon from '../../../../../../assets/copybutton.svg'
import dropdownIconUp from '../../../../../../assets/dropdowniconUP.svg'
import moveSectionIcon from '../../../../../../assets/movesection.svg'
import drilldownIcon from '../../../../../../assets/drilldownicon.svg'
import branchingIcon from '../../../../../../assets/branchingicon.svg'
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
import Collapse from 'react-bootstrap/Collapse';

import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



const SectionQuestion = ({ questionDetails, masterData, setMasterData, index, currentSection, sectionId }) => {


    const [questionOpen, setQuestionOpen] = useState(false);

    const [branchedQuestions, setBranchedQuestions] = useState([]);

    const { question_text, question_type, implicit_flag, parent_theme, theme, choices } = questionDetails

    const currentSections = masterData.sections.filter(x => x.sectionId !== 1)

    const moveableSections = masterData.sections


    // console.log('currentSections', currentSections)

    // console.log('branchedQuestions', branchedQuestions)



    let displayQuestionType

    if (question_type === 'likert_scale') {
        displayQuestionType = 'Likert scale'
    }

    if (question_type === 'mcq') {
        displayQuestionType = 'Multiple Choice'
    }

    if (question_type === 'checkbox') {
        displayQuestionType = 'Checkbox'
    }

    if (question_type === 'dropdown') {
        displayQuestionType = 'Dropdown'
    }
    if (question_type === 'paragraph') {
        displayQuestionType = 'Paragraph'
    }
    if (question_type === 'short_answer') {
        displayQuestionType = 'Short Answer'
    }

    if (question_type === 'grid_mcq') {
        displayQuestionType = 'Grid Multiple Choice'
    }
    if (question_type === 'grid_likert_scale') {
        displayQuestionType = 'Grid Likert scale'
    }



    //MOVE SECTION DROPDOWN LOGIC


    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <img style={{ height: '1.7em' }} src={moveSectionIcon} alt='move_section_button' />

        </a>
    ));

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    {/* <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    /> */}
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );



    const handleMoveSection = (e) => {

        // console.log('masterData IN HANDLER', masterData)

        let questionId = questionDetails.id
        let newSectionId = e

        let destinationSection = moveableSections.filter(x => x.sectionId === newSectionId)[0]

        let reducedQuestion = currentSection.chosenQuestions.filter(x => x.id !== questionId)



        for (let i = 0; i < destinationSection.chosenQuestions.length; i++) {

            if (destinationSection.chosenQuestions.find(x => x.id === questionDetails.id)) {
                // console.log("STRUCK THE NON EXISTANT")
                // console.log('masterData', masterData)


                let updatedSections = moveableSections.map(x => {


                    if (x.sectionId === currentSection.sectionId) {
                        return { ...x, chosenQuestions: reducedQuestion }
                    }

                    // if (x.sectionId === destinationSection.sectionId) {

                    //     return { ...destinationSection, chosenQuestions: [...destinationSection.chosenQuestions, questionDetails] }

                    // }

                    return x
                })
                setMasterData({ ...masterData, sections: updatedSections })
                return
            }


            destinationSection.chosenQuestions.push(questionDetails)

        }

        // console.log("CONTINUED RUNNING")


        let updatedSections = moveableSections.map(x => {


            if (x.sectionId === currentSection.sectionId) {
                return { ...x, chosenQuestions: reducedQuestion }
            }

            if (x.sectionId === destinationSection.sectionId) {

                return { ...destinationSection, chosenQuestions: [...destinationSection.chosenQuestions, questionDetails] }

            }

            return x
        })

        // console.log('sections in handle', moveableSections)
        // console.log('questionId', questionId)
        // console.log('questionDetails', questionDetails)
        // console.log('MOVE TO', newSectionId)
        // console.log('Current currentSection', currentSection)
        // console.log('reducedQuestion', reducedQuestion)
        // console.log('destinationSection', destinationSection)


        setMasterData({ ...masterData, sections: updatedSections })
        // setMasterData()

    }


    const handleRemoveQuestion = (e) => {

        // console.log('question to remove', e)

        let reducedChosenQuestions = currentSection?.chosenQuestions.filter(x => x.id !== e.id)



        let reducedSection = { ...currentSection, chosenQuestions: reducedChosenQuestions }


        let updatedRemovedQSections = moveableSections.map(x => {


            if (x.sectionId === currentSection.sectionId) {
                return reducedSection
            }


            return x
        })


        // console.log('updatedRemovedQSections', updatedRemovedQSections)
        setMasterData({ ...masterData, sections: updatedRemovedQSections })

    }


    const handleRequired = (e) => {

        //if questions already had value from BE, no need local state change?




        let requiredQuestion = e

        // setRequired(prevRequired => !prevRequired)

        let sectionRequiredQuestion = currentSection?.chosenQuestions.map(x => {
            if (x.id === requiredQuestion.id) {


                return { ...x, required: !x.required }
            }

            return x
        })

        let adjustedCurrentSection = { ...currentSection, chosenQuestions: sectionRequiredQuestion }


        let requiredUpdatedSections = moveableSections.map(x => {


            if (x.sectionId === currentSection.sectionId) {
                return adjustedCurrentSection
            }


            return x
        })

        setMasterData({ ...masterData, sections: requiredUpdatedSections })


    }

    // const [modalShow, setModalShow] = React.useState(false);

    // function MyVerticallyCenteredModal(props) {

    //     const [newSectionId, setNewSectionId] = useState(null)

    //     console.log('masterData IN HANDLER', masterData)

    //     let questionId = questionDetails.id

    //     let destinationSection = moveableSections.filter(x => x.sectionId === newSectionId)[0]

    //     let reducedQuestion = currentSection.chosenQuestions.filter(x => x.id !== questionId)

    //     console.log('sections in handle', moveableSections)
    //     console.log('questionId', questionId)
    //     console.log('questionDetails', questionDetails)
    //     console.log('MOVE TO', newSectionId)
    //     console.log('Current currentSection', currentSection)
    //     console.log('reducedQuestion', reducedQuestion)
    //     console.log('destinationSection', destinationSection)


    //     if (newSectionId) {


    //         for (let i = 0; i < destinationSection?.chosenQuestions.length; i++) {

    //             if (destinationSection?.chosenQuestions.find(x => x.id === questionDetails.id)) {
    //                 console.log("STRUCK THE NON EXISTANT")
    //                 return
    //             }

    //             destinationSection?.chosenQuestions.push(questionDetails)

    //         }

    //         let updatedSections = moveableSections.map(x => {

    //             if (x.sectionId === currentSection.sectionId) {
    //                 return { ...x, chosenQuestions: reducedQuestion }
    //             }

    //             if (x.sectionId === destinationSection?.sectionId) {

    //                 return { ...destinationSection, chosenQuestions: [...destinationSection?.chosenQuestions, questionDetails] }

    //             }

    //             return x
    //         })



    //         console.log('updatedSections', updatedSections)

    //         setMasterData({ ...masterData, sections: updatedSections })

    //     }



    //     // setMasterData()


    //     return (
    //         <Modal
    //             {...props}
    //             size="lg"
    //             aria-labelledby="contained-modal-title-vcenter"
    //             centered
    //         >
    //             <Modal.Header closeButton>
    //                 <Modal.Title id="contained-modal-title-vcenter">
    //                     Move to Section
    //                 </Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body>

    //                 <select className='branching-dropdown'
    //                     onChange={(e) => {
    //                         setNewSectionId(parseInt(e.target.value))
    //                     }}
    //                 >
    //                     {/* <option value="">Continue to Next Section</option> */}
    //                     <option></option>
    //                     {

    //                         moveableSections.map((option) => {
    //                             return (
    //                                 <option key={option.sectionId} value={option.sectionId}>
    //                                     {`Skip to Section ${option.sectionId}`}
    //                                 </option>
    //                             );
    //                         })
    //                     }
    //                 </select>

    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button onClick={props.onHide}>Close</Button>
    //             </Modal.Footer>
    //         </Modal>
    //     );
    // }

    //_____________________________

    return (
        <Container fluid
            style={{ paddingLeft: '0px' }}
        >
            <Row>
                <Col>
                    <Card className='section-question-container'>

                        <Card.Body style={{ display: 'flex' }}>
                            {/* <div className='question-drag-handle-container' >
                                <img className="girp-question-button" src={dndIcon} alt='dnd_button' />
                            </div> */}
                            <div className='section-question-contents'>
                                <div className='section-question-header-container '>
                                    <div className='question-header-contents'>
                                        <span>{`${index + 1}. ${question_text}`}</span>
                                        {/* <FontAwesomeIcon icon={faEdit} className="edit-section-question-button" /> */}
                                        <img src={editIcon} alt='edit_button' />
                                    </div>
                                </div>
                                {/* <div className='sq-theme-container '>
                                    <span className='sq-theme'>Theme</span>
                                    <span className='sq-subtheme'>Subtheme</span>
                                </div> */}
                                <div>
                                    <span className='sq-theme-container ' style={{ color: '#4A86FA' }}>

                                        <strong >{parent_theme ? parent_theme : theme ? theme : null}</strong>
                                        {parent_theme && theme ? <span style={{ color: '#282A43' }}>
                                            {' > '}
                                        </span> : null}

                                        {parent_theme && theme ? theme : null}

                                    </span>
                                </div>
                                <div className='sq-parameters-container'>
                                    <span><strong>Question Type: </strong>{displayQuestionType}</span>
                                    <span><strong>Perspective: </strong>{implicit_flag ? 'Subjective' : 'Objective'}</span>
                                </div>

                                {question_type === 'mcq' || question_type === 'dropdown' ? <Collapse in={questionOpen} >
                                    <div className='answers-branching-main-container'>
                                        <div className='answers-container '>
                                            <strong> <span>Answers</span></strong>
                                            {choices?.map(x => {
                                                return (
                                                    <span>{x.value}</span>

                                                )
                                            })}
                                        </div>
                                        <div className='branching-container'>
                                            <strong> <span>Branching</span></strong>
                                            {choices?.map(x => {
                                                return (



                                                    <select className='branching-dropdown'
                                                        onChange={(e) => {

                                                            if (e.target.value === 'Continue to Next Section' && e.target.value) {
                                                                return
                                                            }
                                                            setBranchedQuestions([...branchedQuestions, { choiceId: x.id, nextSection: e.target.value }])
                                                        }}
                                                    >
                                                        {/* <option value="">Continue to Next Section</option> */}
                                                        <option>Continue to Next Section</option>
                                                        {

                                                            currentSections.map((option) => {
                                                                return (
                                                                    <option key={option.sectionId} value={option.sectionId}>
                                                                        {`Skip to Section ${option.sectionId}`}
                                                                    </option>
                                                                );
                                                            })
                                                        }
                                                    </select>




                                                )
                                            })}

                                        </div>
                                    </div>
                                </Collapse> : null}

                                <div className='section-question-header-container '>
                                    <div className='question-controls-contents'>
                                        <div className='checkbox-containers'>

                                            <input
                                                id='required-checkbox'
                                                type='checkbox'
                                                defaultChecked={questionDetails?.required === true ? true : false}
                                                className='required-checkbox'
                                                onChange={(e) => handleRequired(questionDetails)}
                                            />

                                            <label htmlFor='required-checkbox'>Required</label>


                                            <input
                                                id='shuffle-answers-checkbox'
                                                type='checkbox'
                                                className='branching-checkbox'

                                            />
                                            <label htmlFor='shuffle-answers-checkbox'>Shuffle Answers</label>

                                            <div>
                                                <img className="branching-button" src={branchingIcon} alt='branching_button' />
                                                <span>Branching</span>
                                            </div>
                                            <div>
                                                <img className="drilldown-button" src={drilldownIcon} alt='drilldown_button' />
                                                <span>Drill-down</span>
                                            </div>

                                        </div>
                                        <div style={{ display: 'flex' }}>

                                            <div>
                                                {/* <img style={{ height: '1.7em' }} src={moveSectionIcon} alt='move_section_button' /> */}
                                                <Dropdown >
                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu as={CustomMenu} >
                                                        {moveableSections.map(x => {

                                                            //need to filter if question's sectionId is not equal to sectionId in the list, then render item,
                                                            //in order to not render the section to which the question already belongs to
                                                            return (
                                                                sectionId !== x.sectionId ? <Dropdown.Item eventKey={moveableSections.indexOf(x)} onClick={(e) => handleMoveSection(x.sectionId)}> {`Move to Section ${moveableSections.indexOf(x) + 1}`}</Dropdown.Item> : <Dropdown.Item eventKey={moveableSections.indexOf(x)} disabled> {`Move to Section ${moveableSections.indexOf(x) + 1}`}</Dropdown.Item>
                                                            )

                                                        })}

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {/* <Button variant="primary" onClick={() => setModalShow(true)}>

                                                </Button> */}
                                            </div>

                                            <div>
                                                <img style={{ height: '1.4em', marginLeft: '0.25em', marginRight: '0.25em' }} src={copyIcon} alt='copy_button' />
                                            </div>
                                            <div style={{ cursor: 'pointer' }}>
                                                <img style={{ height: '1.7em' }} src={trashIcon} alt='trash_button' onClick={(e) => handleRemoveQuestion(questionDetails)} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {question_type === 'mcq' || question_type === 'dropdown' ? <div
                                onClick={() => setQuestionOpen(!questionOpen)}
                                aria-controls="answers-branching-main-container"
                                aria-expanded={questionOpen}>
                                {questionOpen ? <img className="question-accordion-handle " src={dropdownIconUp} alt='question-accordion-handle ' /> : <img className="question-accordion-handle " src={dropdownIcon} alt='question-accordion-handle ' />}
                                {/* <FontAwesomeIcon icon={faChevronDown} className="question-accordion-handle" /> */}

                            </div> : <img className="question-accordion-handle " style={{ opacity: '0%', cursor: 'default' }} src={dropdownIcon} alt='question-accordion-handle ' />}

                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </Container>

    )

}

export default SectionQuestion

