import React, { useState } from 'react';
import CohortItem from '../CohortItem/cohortitem.component';
import CreateCohortModal from '../CreateCohortModal/create-cohort-modal.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';


import { useSelector } from 'react-redux'


import './cohort-selection.component.scss';


function CohortSelection({ setCohortId }) {

    const [showModal, setShowModal] = useState(false);

    //useSelector from cohortReducer to get all cohort names, timestamps, ids

    const cohorts = useSelector((state) => state.cohort.cohorts);

    // const cohorts = []


    return (
        <div className='cohort-selection'>
            <h1 className='cohort-selection-header'>
                Cohorts

            </h1>
            <div className="create-cohort-button-container" >
                <FontAwesomeIcon icon={faPlusCircle} className="add-cohort-button" onClick={() => {
                    setShowModal(true);
                }} />
                <span className="add-cohort-text">Create New Cohort</span>
            </div>

            {/* on click of the cohort item ... */}


            {cohorts.length > 0 ? <div className='cohort-items'>
                {cohorts.map(({ id, cohort_name, created_at, cohort_desc }) => <CohortItem id={id} key={id} cohort_name={cohort_name} created_at={created_at} cohort_desc={cohort_desc} />)}
            </div> : <div className='cohort-items-empty'>No Cohorts Created</div>}

            <CreateCohortModal
                setShowModal={setShowModal}
                showModal={showModal}
            />


        </div>
    );

}

export default CohortSelection;