import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../components/Question-Components/CreateQuestionModal/create-question.styles.scss'

const ConfirmPopUp = ({ confirmCreated, setConfirmCreated }) => {


    return (
        <Modal
            show={confirmCreated}
            backdrop="static"
            centered
        >
            <Modal.Body className='confirm-pop-up-body'>

                <span>Question Created Succesfully</span>

            </Modal.Body>
            <Modal.Footer className='confirm-pop-up-footer'>
                <Button
                    variant='no-variant'
                    className='card-button'

                    onClick={() => {
                        setConfirmCreated(false)
                    }}>
                    <span>Close</span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmPopUp;
