import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useParams, Redirect } from "react-router-dom";
// import Axios from 'axios';
import axios from "../../misc/modifiedAxios";
import { useUA } from "use-ua-parser-js";
import { v4 as uuidv4 } from "uuid";

import "./Form.scss";

import FormDisclaimer from "../../misc/FormDisclaimer";

import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { SpinnerCircularFixed } from "spinners-react"; //https://adexin.github.io/spinners/

import ErrorPopUp from "../../misc/ErrorPopUp";
import ErrorPageNotFound from "../../misc/ErrorPageNotFound";
import StepWizard from "react-step-wizard";
import FormSection from "./FormSection/Form-Section.component";

const Form = () => {
  const [activeStep, setActiveStep] = useState(0);

  const customTransition = {
    enterRight: "none",
    enterLeft: "none",
    exitRight: "none",
    exitLeft: "none",
    intro: "none",
  };

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  //_________________________ VALIDATION

  const [userLocationData, setUserLocationData] = useState();
  const userBrowserData = useUA();

  const [answers, setAnswers] = useState({});
  const [sessionID, setSessionID] = useState("Not set");

  const [error, setError] = useState({
    message: "",
    status: "",
    sessionID: "",
    timeStamp: "",
  });
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [noQuestions, setNoQuestions] = useState(false);
  const [unavailableMessage, setUnavailableMessage] = useState("");
  const [adminUnavailableMsg, setAdminUnavailableMsg] = useState("");

  const [likertQs, setLikerQs] = useState([]);
  const [openQs, setOpenQs] = useState([]);
  const [dropdownQs, setDropdownQs] = useState([]);
  const [mcqGridQs, setMcqGridQs] = useState([]);
  const [gridLikertQs, setGridLikertQs] = useState([]);
  const [mcqQs, setMcqQs] = useState([]);
  const [checkboxQs, setCheckboxQs] = useState([]);

  const [survey, setSurvey] = useState({});

  const [surveyStart, setSurveyStart] = useState(0);

  const paramsId = useParams();

  const responses = [];

  Object.keys(answers).map(function (key, index) {
    responses.push(answers[key]);
  });

  const [isLoading, setIsLoading] = useState(false);
  const [questionLoad, setQuestionLoad] = useState(false);
  const [completedMessage, setCompletedMessage] = useState(false);
  const [keepAliveFlag, setKeepAliveFlag] = useState(true);

  // Should wrap in useMemo
  const keepAliveData = () => {
    // FORMATTING BROWSER DATA - changing undefined value for device type - desktop to 'desktop'
    let formatedUserBrowserData;

    if (userBrowserData.device.type === undefined) {
      formatedUserBrowserData = {
        ...userBrowserData,
        device: {
          type: "desktop",
          model:
            userBrowserData.device.model === undefined
              ? null
              : userBrowserData.device.model,
          vendor:
            userBrowserData.device.vendor === undefined
              ? null
              : userBrowserData.device.vendor,
        },
      };
    } else {
      formatedUserBrowserData = userBrowserData;
    }

    // OBJECT FOR KEEP ALIVE METADA

    console.log("HIT it right");
    return {
      metadata: {
        location: userLocationData,
        browserData: formatedUserBrowserData,
        sessionID: sessionID,
        surveyPosition: activeStep + 1,
      },
      surveyID: survey.id,
    };
  };

  // ~~~~~~~~~~~~~~~~~~ SUBMIT RESPONSES FUNCTION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  const submit = async () => {
    // e.preventDefault();

    const responses = [];

    Object.keys(answers).map(function (key, index) {
      responses.push(answers[key]);
    });

    setIsLoading(true);

    // FORMATING META DATA
    // duration
    let surveyDuration = Date.now() - surveyStart;

    // changing undefined value for device type - desktop to 'desktop'
    let formatedUserBrowserData;

    if (userBrowserData.device.type === undefined) {
      formatedUserBrowserData = {
        ...userBrowserData,
        device: {
          type: "desktop",
          model:
            userBrowserData.device.model === undefined
              ? null
              : userBrowserData.device.model,
          vendor:
            userBrowserData.device.vendor === undefined
              ? null
              : userBrowserData.device.vendor,
        },
      };
    } else {
      formatedUserBrowserData = userBrowserData;
    }

    // -------------------------

    try {
      const newSession = {
        metadata: {
          location: userLocationData,
          browserData: formatedUserBrowserData,
          sessionID: sessionID,
        },
        duration: Math.floor(surveyDuration / 1000),
        surveyID: survey.id,
        answers: responses,
      };

      const response = await axios.post(`/survey-responses/`, newSession);

      if (response.status === 200) {
        setIsLoading(false);
        setKeepAliveFlag(false);
        setCompletedMessage(true);
      }

      setIsLoading(false);

      // ______________________________KEEP ALIVE ON SUBMISSION WITHOUT METADATA _______________________
      try {
        await axios.put(`survey-responses/keep-alive/?sessionID=${sessionID}`, {
          submitted: true,
          surveyID: survey.id,
        });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      err && setShowErrorModal(true);
      // setError('Something went wrong on our side, we were unable to save your responses. Please try again or contact the admin.')

      // ALTERNATIVE
      setError({
        message: `Something went wrong on our side, we were unable to save your responses. Please try again or contact the admin. `,
        sessionID: sessionID,
        timeStamp: Date(Date.now()).toString(),
        status: err?.response?.status ? err.response?.status : err,
      });
      setKeepAliveFlag(false);
      setIsLoading(false);
    }
  };

  // ~~~~~~~~~~~~~~~~~~ MAIN USEFFECT TRIGERRED ON LANDING OF THE PAGE, RETRIEVE SURVEY AND METADATA ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  useEffect(() => {
    const getGeolocationData = async () => {
      try {
        // _____________________RETRIEVE USER'S IP AND LOCATION WITH OTHER META DATA____________________

        let geoLocationData;

        const geolocationResponse = await fetch(
          // `https://api.ipify.org/?format=json`
          `https://ipapi.co/json/`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );

        if (geolocationResponse) {
          geoLocationData = await geolocationResponse.json();
        }
        if (geoLocationData) {
          const { city, country_name, latitude, longitude, region, ip } =
            geoLocationData;
          setUserLocationData({
            // browser,
            city,
            country_name,
            latitude,
            longitude,
            region,
            ip,
          });
        }
      } catch (err) {
        console.log("err", err);
      }
    };

    const getSurvey = async () => {
      try {
        setQuestionLoad(true);

        // _____________________RETRIEVE SURVEY____________________

        const response = await axios.get(
          `/surveys/form/${paramsId.id}` //df5a61e4-a256-4f5a-a00d-c8bf649809d5
        );

        const token = localStorage.getItem("jwt");

        //for NOT Logged in Users CLOSED AND DRAFT
        if (
          (token === null && response?.data?.status === "closed") ||
          (token === null && response?.data?.status === "draft")
        ) {
          setUnavailableMessage(response?.data?.message);

          setQuestionLoad(false);
          return;
        }

        //for Logged in Users CLOSED SURVEY
        // if ((token && response?.data?.status === 'closed') || (token && response?.data?.status === 'draft')) {
        let status = "";
        if (
          response?.data?.status === "closed" ||
          response?.data?.status === "draft"
        ) {
          status = response?.data?.status;
          setAdminUnavailableMsg(
            status.charAt(0).toUpperCase() + status.slice(1)
          );
        }

        // setUnavailableMessage('User is logged in as Admin, the current survey status is set to = CLOSED'
        let likertQsArray = [];
        let openQsArray = [];
        let dropdownArray = [];
        let mcqGridArray = [];
        let mcqArray = [];
        let checkboxArray = [];
        let likertGridArray = [];

        if (response.data?.sections?.length === 0) {
          setNoQuestions(true);
        }

        for (let i = 0; i < response.data?.sections?.length; i++) {
          if (response.data?.sections[i]?.section_questions?.length > 0) {
            response.data?.sections[i]?.section_questions?.map((q) => {
              if (q.question_type === "likert_scale") {
                likertQsArray.push({
                  id: q.id,
                  question: q.question_text,
                  question_type: q.question_type,
                  required_flag: q.required_flag,
                  likert_scale: q.likert_scale,
                });
              } else if (
                q.question_type === "short_answer" ||
                q.question_type === "paragraph"
              ) {
                openQsArray.push({
                  id: q.id,
                  question: q.question_text,
                  question_type: q.question_type,
                  required_flag: q.required_flag,
                });
              } else if (q.question_type === "dropdown") {
                dropdownArray.push({
                  id: q.id,
                  question: q.question_text,
                  choices: q.choices,
                  question_type: q.question_type,
                  required_flag: q.required_flag,
                });
              } else if (q.question_type === "grid_mcq") {
                mcqGridArray.push({
                  id: q.id,
                  question: q.question_text,
                  grid_choices: q.grid_choices,
                  question_type: q.question_type,
                  required_flag: q.required_flag,
                });
              } else if (q.question_type === "mcq") {
                mcqArray.push({
                  id: q.id,
                  question: q.question_text,
                  choices: q.choices,
                  question_type: q.question_type,
                  required_flag: q.required_flag,
                });
              } else if (q.question_type === "checkbox") {
                checkboxArray.push({
                  id: q.id,
                  question: q.question_text,
                  choices: q.choices,
                  question_type: q.question_type,
                  required_flag: q.required_flag,
                });
              } else if (q.question_type === "grid_likert_scale") {
                likertGridArray.push({
                  id: q?.id,
                  question: q?.question_text,
                  grid_likert_scale: q?.grid_likert_scale,
                  question_type: q?.question_type,
                  required_flag: q?.required_flag,
                });
              }
            });

            setLikerQs(likertQsArray);
            setGridLikertQs(likertGridArray);
            setOpenQs(openQsArray);
            setDropdownQs(dropdownArray);
            setMcqGridQs(mcqGridArray);
            setMcqQs(mcqArray);
            setCheckboxQs(checkboxArray);
            setSurvey(response.data);
            setSurveyStart(Date.now());
          }
        }

        let newSessionID = uuidv4();

        setSessionID(newSessionID);

        setQuestionLoad(false);
      } catch (err) {
        if (err.response?.status === 404) {
          // setError('')
          setError({
            message:
              "This survey URL does not exist. Please try again or contact the admin.",
            sessionID: sessionID,
            timeStamp: Date(Date.now()).toString(),
            status: 404,
          });
          setKeepAliveFlag(false);
        }

        if (err.response?.status === 401) {
          setShowErrorModal(true);
          setError({
            message: "Your Session has timed out, please login again.",
            sessionID: sessionID,
            timeStamp: Date(Date.now()).toString(),
            status: 401,
          });
          setKeepAliveFlag(false);
        } else {
          setShowErrorModal(true);
          setError({
            message:
              "Something went wrong on our side, we were unable to load the survey. Please try again or contact the admin.",
            sessionID: sessionID,
            timeStamp: Date(Date.now()).toString(),
            status: err?.response?.status ? err.response?.status : err,
          });
          setKeepAliveFlag(false);
        }
        setQuestionLoad(false);
      }
    };

    getGeolocationData();

    getSurvey();
  }, []);

  // ~~~~~~~~~~~~~~~~~~ KEEP ALIVE ON USE EFFECT ON LANDING OF THE PAGE ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  useEffect(() => {
    const keepAlive = async () => {
      try {
        if (sessionID !== "Not set" && userLocationData) {
          const keepAliveWithMetaData = keepAliveData();

          await axios.put(
            `survey-responses/keep-alive/?sessionID=${sessionID}`,
            keepAliveWithMetaData //{ surveyPosition: activeStep + 1, surveyID: survey.id }
          );
        }
      } catch (err) {
        console.log(err);
      }
    };

    keepAlive();

    // if (keepAliveFlag) {

    // let timer1 = setTimeout(() => keepAlive(), 5000);

    // return () => {
    // 	clearTimeout(timer1);
    // };

    // }
  }, [sessionID, userLocationData]);

  // ~~~~~~~~~~~~~~~~~~ KEEP ALIVE USE EFFECTS ON PERIODIC TIME PASS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  useEffect(() => {
    try {
      if (keepAliveFlag) {
        // FORMATTING BROWSER DATA - changing undefined value for device type - desktop to 'desktop'
        // let formatedUserBrowserData

        // if (userBrowserData.device.type === undefined) {
        // 	formatedUserBrowserData = {
        // 		...userBrowserData,
        // 		device:
        // 		{
        // 			type: 'desktop',
        // 			model: userBrowserData.device.model === undefined ? null : userBrowserData.device.model,
        // 			vendor: userBrowserData.device.vendor === undefined ? null : userBrowserData.device.vendor
        // 		},

        // 	}
        // } else {
        // 	formatedUserBrowserData = userBrowserData
        // }

        // // OBJECT FOR KEEP ALIVE METADA
        // const keepAliveData = {
        // 	metadata: {
        // 		location: userLocationData,
        // 		browserData: formatedUserBrowserData,
        // 		sessionID: sessionID,
        // 		surveyPosition: activeStep + 1,
        // 	},
        // 	surveyID: survey.id,
        // }

        // REQUEST EVERY INTERVAL WITH KEEPALIVE METADATA OBJECT

        const keepAliveWithMetaData = keepAliveData();

        const interval = setInterval(() => {
          const keepAlive = async () => {
            await axios.put(
              `survey-responses/keep-alive/?sessionID=${sessionID}`,
              keepAliveWithMetaData
            );
          };
          keepAlive();
        }, 60000); // 600000

        return () => clearInterval(interval);
      }
    } catch (err) {
      console.log(err);
    }
  }, [keepAliveFlag, sessionID, userLocationData, activeStep]);

  // ~~~~~~~~~~~~~~~~~~ KEEP ALIVE USE EFFECT ON SECTION SWITCH ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  useEffect(() => {
    const keepAlive = async () => {
      try {
        if (sessionID !== "Not set") {
          let keepAliveWithMetaData = keepAliveData();

          keepAliveWithMetaData = {
            ...keepAliveWithMetaData,
            sectionUpdate: true,
          };

          await axios.put(
            `survey-responses/keep-alive/?sessionID=${sessionID}`,
            keepAliveWithMetaData //{ surveyPosition: activeStep + 1, surveyID: survey.id }
          );
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (keepAliveFlag) {
      let timer1 = setTimeout(() => keepAlive(), 5000);

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [keepAliveFlag, activeStep]);

  return (
    <div className="form-page-container">
      {isLoading ? (
        <div className="spinner">
          <SpinnerCircularFixed
            size={60}
            thickness={140}
            speed={120}
            color="var(--spinner-color)"
            secondaryColor="rgb(240, 240, 240)
												"
          />
          <div className="spinner-text">
            <h1>Submitting your responses...</h1>
          </div>
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              <div className="survey-title">
                <h1>{survey.survey_name}</h1>

                {!completedMessage && activeStep === 0 ? (
                  <span className="survey-description">
                    {survey.survey_desc}
                  </span>
                ) : null}

                {/* ERROR MODAL WILL BE FALSE SO WONT RENDER POP UP IF 404 */}
                {error.status === 404 && (
                  <ErrorPageNotFound message={error.message} />
                )}
                {showErrorModal && (
                  <ErrorPopUp
                    error={error}
                    setError={setError}
                    showErrorModal={showErrorModal}
                    setShowErrorModal={setShowErrorModal}
                  />
                )}
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>

          {adminUnavailableMsg ? (
            adminUnavailableMsg === "Closed" ? (
              <div className="admin-closed-message">
                <span>{`Admin Preview - Survey status is currently set to ${adminUnavailableMsg}.`}</span>
              </div>
            ) : (
              <div className="admin-draft-message">
                <span>{`Admin Preview - Survey status is currently set to ${adminUnavailableMsg}.`}</span>
              </div>
            )
          ) : null}

          <Row className="justify-content-md-center">
            <Col md={3}></Col>
            <Col md={6}>
              <div className="page">
                <div onSubmit={submit}>
                  {questionLoad ? (
                    <div className="spinner">
                      <SpinnerCircularFixed
                        size={60}
                        thickness={140}
                        speed={120}
                        color="var(--spinner-color)"
                        secondaryColor="rgb(240, 240, 240)
												"
                      />
                      {/* <ReactBootStrap.Spinner animation='border' /> */}
                      <div className="spinner-text">
                        <h1>Loading Form...</h1>
                      </div>
                    </div>
                  ) : unavailableMessage ? (
                    <h1 className="unavailable-message">
                      {unavailableMessage}
                    </h1>
                  ) : noQuestions ? (
                    <div className="no-questions-message">
                      <span>This survey has no questions</span>
                    </div>
                  ) : !completedMessage ? (
                    <div className="question-container">
                      <StepWizard
                        onStepChange={handleStepChange}
                        transitions={customTransition}
                      >
                        {survey?.sections?.map((x) => {
                          return (
                            <FormSection
                              key={x.id}
                              setAnswers={setAnswers}
                              answers={answers}
                              likertQs={likertQs}
                              gridLikertQs={gridLikertQs}
                              openQs={openQs}
                              dropdownQs={dropdownQs}
                              mcqGridQs={mcqGridQs}
                              mcqQs={mcqQs}
                              checkboxQs={checkboxQs}
                              section={x}
                              activeStep={activeStep}
                              setLikerQs={setLikerQs}
                              setOpenQs={setOpenQs}
                              setDropdownQs={setDropdownQs}
                              setMcqGridQs={setMcqGridQs}
                              setGridLikertQs={setGridLikertQs}
                              setMcqQs={setMcqQs}
                              setCheckboxQs={setCheckboxQs}
                              setSurvey={setSurvey}
                              submit={submit}
                            />
                          );
                        })}
                      </StepWizard>

                      <Container fluid>
                        <Row style={{ display: "flex", alignItems: "center" }}>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          ></Col>
                          <Col></Col>
                        </Row>
                      </Container>
                      <FormDisclaimer />
                    </div>
                  ) : (
                    // style={{ alignText: 'center' }} FOR EMPIRISYS SURVEY
                    <div className="thank-you-message">
                      <h1 className="thank-you-message-header">
                        Thank you for submitting your response.
                      </h1>

                      {/* HASH CODE IS the ID of DETECT SURVEY for BP */}

                      {survey?.submit_confirm_message?.map((x) => {
                        return <span>{x}</span>;
                      })}

                      {survey?.id === "pP2z8wA7D5yl64qm" ? (
                        <span>
                          Your answers will be used to gain valuable insights
                          into various aspects of process safety and human
                          factors management.
                        </span>
                      ) : null}
                      {survey?.id === "pP2z8wA7D5yl64qm" ? (
                        <span>
                          Please rest assured that all your responses are kept
                          confidential and will solely be utilised for internal
                          research purposes.
                        </span>
                      ) : null}
                      <span>
                        If you have any questions, comments, or feedback don’t
                        hesitate to contact us at{" "}
                        <a
                          href="mailto:info-sense@empirisys.io"
                          style={{ color: "#0a58ca" }}
                        >
                          info-sense@empirisys.io
                        </a>
                      </span>
                      <a
                        href="https://www.empirisys.io/sense"
                        style={{ color: "#0a58ca" }}
                      >
                        www.empirisys.io/sense
                      </a>
                      <FormDisclaimer />
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Form;
