import { parseZone } from 'moment';
import React, { useState } from 'react';

import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

const OpenQuestion = ({
	id,
	text,
	question_type,
	setError,
	setAnswers,
	answers,
	questionNumber,
	required_flag,
	onInputChanged,

}) => {
	const styles = {
		marginBottom: '10px',
		marginTop: '10px',
	};


	const handleTextChange = (e) => {

		setError('');

		if (required_flag) {
			onInputChanged(e)
		}


		if (e.target.value === '') {
			setAnswers((answers) => ({


				...answers,
				[`Question_${parseInt(id)}`]: {
					questionID: parseInt(id),
					questionType: question_type,
					response:
						e.target.value.replace(
							/[^\x20-\x7E\n]+/g,
							''
						),
				},
			}));


		} else {
			if (e.target.value.trim() !== '') {
				setAnswers((answers) => ({


					...answers,
					[`Question_${parseInt(id)}`]: {
						questionID: parseInt(id),
						questionType: question_type,
						response:
							e.target.value.replace(
								/[^\x20-\x7E\n]+/g,
								''
							),
					},
				}));
			}

		}

	}


	return (
		<Container
			fluid
			className='forms-box'
		>
			{required_flag ? <Row>
				<Col>
					<div className='question-title'>
						<span className='question'>{questionNumber}. {text} </span>
						<span style={{ color: 'red', fontSize: '1.5em' }}>*</span>
					</div>
					{question_type === 'short_answer' ? <span>{'(max 250 characters)'}</span> : <span>{'(max 2500 characters)'}</span>}
				</Col>
			</Row> :
				<Row>
					<Col>
						<div className='question-title'>
							<span className='question'>{questionNumber}. {text}</span>
						</div>
						{question_type === 'short_answer' ? <span>{'(max 250 characters)'}</span> : <span>{'(max 2500 characters)'}</span>}
					</Col>
				</Row>
			}
			<Row>
				{required_flag ? <Col>

					{question_type === 'short_answer' ?
						<textarea
							required
							className='form-control survey-form-open-question'

							name={parseInt(id)}
							type='text'
							maxLength='250'
							onChange={handleTextChange}
							style={styles}
						/>
						:
						<textarea
							required
							className='form-control survey-form-open-question'
							name={id}
							type='text'
							maxLength='2500'
							onChange={handleTextChange}
							style={styles}
						/>}
				</Col>

					:

					<Col>

						{question_type === 'short_answer' ?
							<textarea
								className='form-control survey-form-open-question '
								id='register-email'
								type='text'
								name={id}
								maxLength='250'
								onChange={handleTextChange}
								style={styles}
							/>
							:
							<textarea
								className='form-control survey-form-open-question '
								id='register-email'
								type='text'
								maxLength='2500'
								onChange={handleTextChange}
								style={styles}
							/>}
					</Col>}


			</Row>
		</Container>
	);
};

export default OpenQuestion;
