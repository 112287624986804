import React, { useRef, useState, useLayoutEffect, forceUpdate, useEffect } from 'react';
import './cohortitemname.styles.scss';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch } from 'react-redux';

function CohortItemName({ cohort_name }) {

    const [name_short, set_name_short] = useState(cohort_name)
    const cohort_name_ref = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [windowResize, set_window_resize] = useState(false);
    useLayoutEffect(() => {
        if (cohort_name_ref.current.offsetWidth != dimensions.width) {
            if (cohort_name_ref.current.offsetWidth > dimensions.width) {
                var temp_name_short = name_short
            } else {
                var temp_name_short = name_short
            }
            setDimensions({
                width: cohort_name_ref.current.offsetWidth,
                height: cohort_name_ref.current.offsetHeight
            });

            if (getTextWidth(temp_name_short) > cohort_name_ref.current.offsetWidth) {
                temp_name_short = temp_name_short + "..."
                while (getTextWidth(temp_name_short) > cohort_name_ref.current.offsetWidth) {
                    temp_name_short = temp_name_short.substring(0, temp_name_short.length - 4) + "..."
                }
                temp_name_short = temp_name_short.substring(0, temp_name_short.length - 10) + "..."
            }
            set_name_short(temp_name_short)
        }
        set_window_resize(false)
    }, [windowResize]);
    let timeout;
    const handleResize = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            set_window_resize(true);
        }, 200);
    }
    window.addEventListener("resize", handleResize);


    return (
        <div ref={cohort_name_ref} className="name opensans-semi-bold-black-16px">{name_short}</div>
    );
}

function getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
}


export default CohortItemName;

