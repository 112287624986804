import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import addIcon from '../../../assets/addcirclebutton.svg'

import axios from "../../../misc/modifiedAxios";
import ErrorCatch from '../../Shared/Error/error-catch';

import CreateSurveyModal from '../CreateSurveyModal/create-survey-modal-component'
import EditSurveyModal from '../EditSurveyModal/edit-survey-modal-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import './survey-display.styles.scss';
import SurveyTable from '../SurveyTable/survey-table.component'
import SurveyURLModal from '../SurveyURLModal/survey-url-modal-component'
import { SpinnerCircularFixed } from 'spinners-react';



const SurveyDisplay = () => {

    const [isLoading, setisLoading] = useState(false);

    const surveys = useSelector((state) => state.survey.surveys);

    const history = useHistory()

    const dispatch = useDispatch()

    const organisationId = useSelector((state) => state.user.organisationId);




    useEffect(() => {


        let controller = new AbortController();
        let controllerQuestions = new AbortController();
        (async () => {
            try {
                setisLoading(true)

                // GET AND SET QUESTIONS

                const response = await axios.get(
                    // `http://localhost:1337/surveys?_sort=id:DESC`,
                    "/surveys",
                    {
                        signal: controller.signal
                    }
                ).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })

                controller = null;


                dispatch({
                    type: 'SET_SURVEYS',
                    payload: response.data
                })


                //GET AND SET QUESTION GROUPS

                const responseQuestion = await axios.get(
                    `/questions?ogs=${organisationId}&theme=`,
                    {
                        signal: controllerQuestions.signal
                    }//change to themes
                ).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })

                console.log('questionResponse', responseQuestion)



                controllerQuestions = null;

                dispatch({
                    type: 'SET_QUESTIONS',
                    payload: responseQuestion.data
                })

                setisLoading(false)

            } catch (err) {
                console.log(err)
            }
        })();

        return () => controller?.abort();
    }, [dispatch, history, organisationId]);



    const [showCreateModal, setCreateShowModal] = useState(false);
    const [showEditModal, setEditModal] = useState(false);
    const [showUrlModal, setShowUrlModal] = useState(false);


    return (

        <div className='survey-display'>


            <h1 className='survey-display-header'>
                Survey Details
            </h1>


            <div className="add-survey-button-container " >
                <img style={{ height: '2em' }} src={addIcon} alt='add_button' onClick={() => {
                    setCreateShowModal(true);
                }} />
                <span className="add-button-text-new-survey">Create New Survey</span>
            </div>




            {isLoading ? (
                <div className='spinner'>
                    <SpinnerCircularFixed
                        size={60}
                        thickness={140}
                        speed={120}
                        color='var(--spinner-color)'
                        secondaryColor='rgb(240, 240, 240)
												'
                    />
                    <div className='spinner-text'>
                        <span>Loading Surveys...</span>
                    </div>
                </div>
            ) : (
                <SurveyTable surveys={surveys}
                    setEditModal={setEditModal}
                    setShowUrlModal={setShowUrlModal}
                />)}
            <CreateSurveyModal
                setShowModal={setCreateShowModal}
                showModal={showCreateModal}
            />
            {showEditModal ? <EditSurveyModal
                setEditModal={setEditModal}
                showEditModal={showEditModal} /> : null}
            {showUrlModal ? <SurveyURLModal
                setShowUrlModal={setShowUrlModal}
                showUrlModal={showUrlModal} /> : null}
        </div >
    );

};

export default SurveyDisplay;




