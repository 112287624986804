import React from 'react'

export const DropdownFilter = ({
    column
}) => {
    // Calculate the options for filtering
    // using the preFilteredRows

    const { filterValue, setFilter, preFilteredRows, id } = column


    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    const displayOptions = options.map(option => {

        if (option === 'live') {
            return {
                value: 'live',
                label: 'Live'
            }
        }

        if (option === 'draft') {
            return {
                value: 'draft',
                label: 'Draft'
            }
        }


        if (option === 'closed') {
            return {
                value: 'closed',
                label: 'Closed'
            }
        }
        
        return null
    })

    // Render a multi-select box
    return (
        <select
            className='formControl'
            value={filterValue}
            onChange={e => {


                setFilter(e.target.value || undefined)

            }}
        >
            <option value="">All</option>
            {displayOptions.map((option, i) => (

                <option key={i} value={option?.value}>
                    {option?.label}
                </option>
            ))}
        </select>
    )
}

