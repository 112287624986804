import React from 'react';
import { Modal, Button } from 'react-bootstrap';



const ClearSurveyWarningPopUp = ({
    showWarningModal,
    setShowWarningModal,

    setLikerQs,
    setOpenQs,
    setDropdownQs,
    setMcqGridQs,
    setMcqQs,
    setGridLikertQs,
    setCheckboxQs,
    setSurvey,
}) => {


    return (
        <Modal
            show={showWarningModal}
            backdrop="static"
            onHide={() => {
                setShowWarningModal(false);
            }}>
            <Modal.Body >
                <div
                >
                    <span>This will clear all of your responses from the survey, are you sure?</span>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    variant='no-variant'
                    className='clear-button clear-button--secondary'

                    onClick={() => {
                        setShowWarningModal(false);
                    }}>
                    <span>Cancel</span>
                </Button>
                <Button
                    variant='no-variant'
                    className='card-button'

                    onClick={() => {
                        // setLikerQs([])
                        // setOpenQs([])
                        // setDropdownQs([])
                        // setMcqGridQs([])
                        // setMcqQs([])
                        // setGridLikertQs([])
                        // setCheckboxQs([])
                        // setSurvey({})
                        // setShowWarningModal(false);
                        window.location.reload(false);
                    }}>
                    <span>Yes</span>
                </Button>

            </Modal.Footer>
        </Modal>
    );
};

export default ClearSurveyWarningPopUp;
