const INITIAL_STATE = {
	clientId: 1,
};

const clientReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_CLIENT':
			return {
				...state,
				cohorts: action.payload,
			};
		default:
			return state;
	}
};

export default clientReducer;
