import { updateThemeDetails, updateUnassignedThemeDetails, removeTheme, removeSubTheme } from './theme.utils';

const INITIAL_STATE = {
	themes: [{
		id: -1,
		theme_desc: "",
		theme_name: 'Unassigned',
		type: 'allUnassigned'
	}, {
		id: -2,
		theme_desc: "",
		theme_name: "All Questions",
		type: 'all'
	}],

	selectedTheme: null,
	selectedChildTheme: null
};

const themeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_THEMES':
			return {
				...state,
				themes: action.payload.map(x => {
						return { ...x, type: 'regular' }
					}),

				// When user lands or refreshes Question Manager, first theme will be set as All Questions
				selectedTheme: {
					id: -2,
					theme_desc: "",
					theme_name: "All Questions",
					type: 'all'
				},
				selectedChildTheme: null
			};
		case 'SELECT_THEME':
			return {
				...state,
				selectedTheme: action.payload,
			};
		case 'ADD_NEW_THEME':
			return {
				...state,
				themes: [action.payload, ...state.themes ],
			};
		case 'UPDATE_THEME_DETAILS':
			return {
				...state,
				themes: updateThemeDetails(state.themes, action.payload),
				selectedTheme: action.payload //[REVIEW] was uncommented
			};

		case 'DELETE_THEME':
			return {
				...state,
				themes: removeTheme(state.themes, action.payload),
				selectedTheme: null
			};

		// V4 same as UPDATE_THEME_DETAILS, except selectedTheme is not null, but parent theme to be rendered in question display view
		case 'DELETE_SUBTHEME':
			return {
				...state,
				themes: updateThemeDetails(state.themes, action.payload),
				selectedTheme: action.payload,
				selectedChildTheme: null
			};
		case 'SELECT_CHILD_THEME':
			return {
				...state,
				selectedChildTheme: action.payload,
			};

		default:
			return state;
	}
};

export default themeReducer;


// DORMANT OLD V3 version
		// case 'DELETE_SUBTHEME':
		// 	return {
		// 		...state,
		// 		themes: removeSubTheme(state.selectedTheme, action.payload, state.themes),
		// 		//Return the most recent Theme, which had its subtheme removed. 
		// 		selectedTheme: removeSubTheme(state.selectedTheme, action.payload, state.themes).filter(x => x.id === state.selectedTheme.id)[0]
		// 	};


		// NOT USED

		// case 'UPDATE_UNASSIGNED_THEME_DETAILS':
		// 	return {
		// 		...state,
		// 		themes: updateUnassignedThemeDetails(state.themes, action.payload),
		// 		selectedTheme: state.selectedTheme//state.themes.filter(x => x.type === 'allUnassigned')
		// 	};