import React from 'react'

export const DropdownFilter = ({
    column
}) => {
    // Calculate the options for filtering
    // using the preFilteredRows

    const { filterValue, setFilter, preFilteredRows, id } = column


    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    const displayOptions = options.map(option => {

        if (option === 'likert_scale') {
            return {
                value: 'likert_scale',
                label: 'Likert scale'
            }
        }

        if (option === 'mcq') {
            return {
                value: 'mcq',
                label: 'Multiple Choice'
            }
        }


        if (option === 'checkbox') {
            return {
                value: 'checkbox',
                label: 'Checkbox'
            }
        }

        if (option === 'dropdown') {
            return {
                value: 'dropdown',
                label: 'Dropdown'
            }
        }
        if (option === 'paragraph') {
            return {
                value: 'paragraph',
                label: 'Paragraph'
            }
        }
        if (option === 'short_answer') {
            return {
                value: 'short_answer',
                label: 'Short Answer'
            }
        }

        if (option === 'grid_mcq') {
            return {
                value: 'grid_mcq',
                label: 'Grid Multiple Choice'
            }
        }
        if (option === 'grid_likert_scale') {
            return {
                value: 'grid_likert_scale',
                label: 'Grid Likert Scale'
            }
        }
        return null
    })

    // Render a multi-select box
    return (
        <select
            className='formControl'
            value={filterValue}
            onChange={e => {


                setFilter(e.target.value || undefined)

            }}
        >
            <option value="">All</option>
            {displayOptions.map((option, i) => (

                <option key={i} value={option?.value}>
                    {option?.label}
                </option>
            ))}
        </select>
    )
}

