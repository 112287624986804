import React, { useState, useEffect } from 'react';
import { some, isEmpty } from 'lodash'
import { Button, Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';

import LinkertQuestion from '../../../components/Form/Questions/LinkertQuestion/LinkertQuestion';
import GridLikertQuestion from '../../../components/Form/Questions/GridLikertQuestion/GridLikertQuestion';
import OpenQuestion from '../../../components/Form/Questions/OpenQuestion/OpenQuestion';
import DropdownQuestion from '../../../components/Form/Questions/DropdownQuestion/DropdownQuestion';
import MCQGridQuestion from '../../../components/Form/Questions/MCQGridQuestion/MCQGridQuestion';
import MCQQuestion from '../../../components/Form/Questions/MCQQuestion/MCQQuestion';
import CheckboxQuestion from '../../../components/Form/Questions/CheckboxQuestion/CheckboxQuestion';

import ClearSurveyWarningPopUp from '../../../misc/ClearSurveyWarningPopUp';

const ActionButtons = (props) => {

	const { setLikerQs,
		setOpenQs,
		setDropdownQs,
		setMcqGridQs,
		setMcqQs,
		setGridLikertQs,
		setCheckboxQs,
		setSurvey,
		answers
	} = props

	const handleBack = () => {
		props.previousStep();
	};

	const handleNext = () => {

		props.nextStep();
		// window.scrollTo(0, 0)
		// window.scrollTo({
		// 	top: 0,
		// 	left: 0,
		// 	behavior: 'smooth',
		// })
	};

	const handleFinish = () => {
		props.lastStep();

	};


	const [showWarningModal, setShowWarningModal] = useState(false);


	return (
		<div style={{ marginTop: '3em' }}>
			<Row style={{ marginBottom: '2em' }}>
				<Col style={{ display: 'flex' }} xs={6}>
					<div href="#" className="clear-button clear-button--secondary"
						onClick={() => {

							setShowWarningModal(true)
							// setLikerQs([])
							// setOpenQs([])
							// setDropdownQs([])
							// setMcqGridQs([])
							// setMcqQs([])
							// setGridLikertQs([])
							// setCheckboxQs([])
							// setSurvey({})
						}}>
						<span>Clear Survey</span>
					</div>
				</Col>
				<Col style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
					{props.currentStep > 1 && (
						<div className='back-button-container-form'>

							<div className="clear-button clear-button--secondary"
								onClick={handleBack}
							>
								<span>Back</span>
							</div>
						</div>
					)}
					<div>
						{props.currentStep < props.totalSteps && (
							<input
								type='button'
								onClick={handleNext}
								value='Continue'
								className='card-button'
							/>
						)}

						{props.currentStep === props.totalSteps && (


							// <input
							// 	type='button'
							// 	onClick={handleFinish}
							// 	value='Submit Answers'
							// 	className='card-button'
							// />
							<input
								type='button'

								disabled={isEmpty(answers) ? true : false}
								onClick={handleFinish}
								value='Submit Answers'
								className={isEmpty(answers) ? 'card-button-disabled' : 'card-button'}
							/>

						)}
					</div>
				</Col>
			</Row>
			{showWarningModal ? <ClearSurveyWarningPopUp
				showWarningModal={showWarningModal}
				setShowWarningModal={setShowWarningModal}

				setLikerQs={setLikerQs}
				setOpenQs={setOpenQs}
				setDropdownQs={setDropdownQs}
				setMcqGridQs={setMcqGridQs}
				setMcqQs={setMcqQs}
				setGridLikertQs={setGridLikertQs}
				setCheckboxQs={setCheckboxQs}
				setSurvey={setSurvey}
			/> : null}
		</div>
	);
};


const FormSection = (props) => {

	let {
		answers,
		setAnswers,
		likertQs,
		openQs,
		dropdownQs,
		mcqGridQs,
		mcqQs,
		gridLikertQs,
		checkboxQs,
		section,
		activeStep
	} = props


	let questionNumber = 0;


	const requiredQs = section?.section_questions?.filter(x => x.required_flag === true)

	const formatedRequiredQs = requiredQs.map(x => { return { [x.id]: '' } })

	const reducedFormatedQs = Object.assign({}, ...formatedRequiredQs);
	//______________ VALIDATION _______________________

	const [validatedQs, setValidatedQs] = useState(reducedFormatedQs);


	const [error, setError] = useState("");

	const onInputChanged = (event, id) => {

		const targetName = event.target.name;
		const targetValue = event.target.value;

		if (id) {

			return setValidatedQs((validatedQs) => ({
				...validatedQs,
				[id]: targetValue
			}));

		}


		setValidatedQs((validatedQs) => ({
			...validatedQs,
			[targetName]: targetValue
		}));


	};

	const onMultipleInputChanged = (id, chosenValues, question_type) => {




		setValidatedQs((validatedQs) => ({
			...validatedQs,
			[id]: chosenValues.length > 0 ? chosenValues : ''
		}));

	};



	const validate = () => {

		let exists = Object.values(validatedQs).includes("");


		if (exists) {
			setError("Please complete all required fields marked '*'");
			window.scrollTo(0, 0)
		}

		else {
			setError("");
			props.nextStep();
			// props.userCallback(validatedQs);
		}
	};

	const validateSubmission = () => {
		let exists = Object.values(validatedQs).includes("");

		if (exists) {
			setError("Please complete all required fields marked '*'");
			window.scrollTo(0, 0)
		}
		else {
			setError("");
			props.submit();
			// props.userCallback(validatedQs);
		}
	}




	// __________________ VALIDATION END _________________________

	useEffect(() => {

		window.scrollTo(0, 0)

	}, [activeStep])

	return (

		<>

			<Row>

				<Col >
					<div className='section-title'>
						<h1>{section.section_name}</h1>

						<span className='survey-description'> {section.section_desc}</span>


						{error && <p className='error'>{error}</p>}
					</div>
				</Col>
			</Row>



			<FormGroup>



				{section?.section_questions?.map((q) => {
					if (
						q.question_type ===
						'dropdown'
					) {
						return (
							<div
								key={q.id}
								className='section'>
								{dropdownQs.map(
									(
										dropdownLine
									) => {
										if (
											q.id ===
											dropdownLine.id
										) {
											questionNumber++

											const {
												question,
												choices,
												id,
												question_type,
												required_flag
											} = dropdownLine;

											return (
												<DropdownQuestion
													text={
														question
													}
													setError={
														setError
													}
													setAnswers={
														setAnswers
													}
													answers={
														answers
													}
													key={
														questionNumber
													}
													questionNumber={
														questionNumber
													}
													choices={
														choices
													}
													id={
														id
													}
													question_type={
														question_type
													}
													required_flag={required_flag}
													onInputChanged={onInputChanged}
												/>
											);
										}
									}
								)}
							</div>
						);
					}

					if (
						q.question_type ===
						'likert_scale'
					) {
						return (
							<div
								key={q.id}
								className='section'>
								{likertQs.map(
									(line) => {
										if (
											q.id ===
											line.id
										) {
											questionNumber++;

											const {
												question,
												id,
												question_type,
												required_flag,
												likert_scale
											} = line;

											return (
												<LinkertQuestion
													text={
														question
													}
													setError={
														setError
													}
													setAnswers={
														setAnswers
													}
													answers={
														answers
													}
													key={
														questionNumber
													}
													questionNumber={
														questionNumber
													}
													question_type={
														question_type
													}
													id={
														id
													}
													required_flag={required_flag}
													likert_scale={likert_scale}
													onInputChanged={onInputChanged}

												/>
											);
										}
									}
								)}
							</div>
						);
					}

					if (
						q.question_type ===
						'short_answer' ||
						q.question_type ===
						'paragraph'
					) {
						return (
							<div
								key={q.id}
								className='section'>
								{openQs.map(
									(openLine) => {
										if (
											q.id ===
											openLine.id
										) {
											questionNumber++;

											const {
												question,
												id,
												question_type,
												required_flag
											} = openLine;

											return (
												<OpenQuestion
													text={
														question
													}
													setError={
														setError
													}
													setAnswers={
														setAnswers
													}
													answers={
														answers
													}
													key={
														questionNumber
													}
													questionNumber={
														questionNumber
													}
													question_type={
														question_type
													}
													id={
														id
													}
													required_flag={required_flag}
													onInputChanged={onInputChanged}

												/>
											);
										}
									}
								)}
							</div>
						);
					}

					if (
						q.question_type ===
						'grid_mcq'
					) {
						return (
							<div
								key={q.id}
								className='section'>
								{mcqGridQs.map(
									(
										dropdownLine
									) => {
										if (
											q.id ===
											dropdownLine.id
										) {
											questionNumber++;

											const {
												question,
												grid_choices,
												id,
												question_type,
												required_flag
											} = dropdownLine;

											return (
												<MCQGridQuestion
													id={
														id
													}
													text={
														question
													}
													setError={
														setError
													}
													setAnswers={
														setAnswers
													}
													answers={
														answers
													}
													key={
														questionNumber
													}
													questionNumber={
														questionNumber
													}
													grid_choices={
														grid_choices
													}
													question_type={
														question_type
													}
													required_flag={required_flag}
													onMultipleInputChanged={onMultipleInputChanged} />
											);
										}
									}
								)}
							</div>
						);
					}
					if (q.question_type === 'mcq') {
						return (
							<div
								key={q.id}
								className='section'>
								{mcqQs.map(
									(line) => {
										if (
											q.id ===
											line.id
										) {
											questionNumber++;

											const {
												question,
												choices,
												id,
												question_type,
												required_flag
											} = line;

											return (
												<MCQQuestion
													text={
														question
													}
													setError={
														setError
													}
													setAnswers={
														setAnswers
													}
													answers={
														answers
													}
													key={
														questionNumber
													}
													questionNumber={
														questionNumber
													}
													choices={
														choices
													}
													rating={
														line
													}
													id={
														id
													}
													question_type={
														question_type
													}
													required_flag={required_flag}
													onInputChanged={onInputChanged}
												/>
											);
										}
									}
								)}
							</div>
						);
					}

					if (
						q.question_type ===
						'checkbox'
					) {
						return (
							<div
								key={q.id}
								className='section'>
								{checkboxQs.map(
									(line) => {
										if (
											q.id ===
											line.id
										) {
											questionNumber++;

											const {
												question,
												choices,
												id,
												question_type,
												required_flag
											} = line;

											return (
												<CheckboxQuestion
													text={
														question
													}
													setError={
														setError
													}
													setAnswers={
														setAnswers
													}
													answers={
														answers
													}
													key={
														questionNumber
													}
													questionNumber={
														questionNumber
													}
													choices={
														choices
													}
													rating={
														line
													}
													question_type={
														question_type
													}
													id={
														id
													}
													required_flag={required_flag}
													onMultipleInputChanged={onMultipleInputChanged}
												/>
											);
										}
									}
								)}
							</div>
						);
					}
					if (
						q.question_type ===
						'grid_likert_scale'
					) {
						return (
							<div
								key={q.id}
								className='section'>

								{gridLikertQs.map(
									(
										dropdownLine
									) => {
										if (
											q.id ===
											dropdownLine.id
										) {
											questionNumber++;

											const {
												question,
												grid_likert_scale,
												id,
												question_type,
												required_flag
											} = dropdownLine;

											return (
												<GridLikertQuestion
													id={
														id
													}
													text={
														question
													}
													setError={
														setError
													}
													setAnswers={
														setAnswers
													}
													answers={
														answers
													}
													key={
														questionNumber
													}
													questionNumber={
														questionNumber
													}
													grid_likert_scale={
														grid_likert_scale
													}
													question_type={
														question_type
													}
													required_flag={required_flag}
													onMultipleInputChanged={onMultipleInputChanged} />
											);
										}
									}
								)
								}
							</div>
						);
					}
				})}



				<ActionButtons {...props} nextStep={validate} lastStep={validateSubmission} />

				{/* Next Phase Clear Section:
				
				1. FormGroup change to form
				2. <input type="reset" value="Reset Form" onClick={() => { setAnswers({}) }} />
				 Work on this, reset the form which is a section essentially of its inputs, 
				 but also need to filter out answers from setAnswers/answers by section id, if questions include that.
				*/}




			</FormGroup>
		</>
	);
};

export default FormSection;
