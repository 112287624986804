import React, { useEffect } from 'react';
import './Organisations.scss';
import SideNavBar from '../../components/Shared/SideNavBar/sidenavbar.component';
import TabOrganisationSwitch from './tab-switch-organisations.component'
import { useHistory } from "react-router-dom";

const Organisations = ({ match }) => {

    const history = useHistory();

    useEffect(() => {

        const jwt = localStorage.getItem('jwt')
        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (!jwt) {
            history.push("/")
        }


    }, []);


    return (
        <div className='themes'>


            <SideNavBar />
            <div style={{ width: '100%' }}>

                <TabOrganisationSwitch />
            </div>

        </div>
    );
};

export default Organisations;