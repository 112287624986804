export const updateThemeDetails = (themes, editedTheme) => {
	const existingItem = themes.find(
		(theme) => theme.id === editedTheme.id
	);

	if (existingItem) {
		return themes.map((theme) =>
			theme.id === editedTheme.id
				? {
					...theme,
					theme_name: editedTheme.theme_name,
					theme_desc: editedTheme.theme_desc,
					theme_summary: editedTheme.theme_summary,
					child_themes: editedTheme.child_themes,
					parent_theme: editedTheme.parent_theme
				}
				: theme
		);
	}

	return [...themes];
};


export const updateUnassignedThemeDetails = (themes, editedTheme) => {
	const existingItem = themes.find(
		(theme) => theme.id === editedTheme.id
	);

	if (existingItem) {
		return themes.map((theme) =>
			theme.id === editedTheme.id
				? {
					...theme,
					theme_name: editedTheme.theme_name,
					theme_desc: editedTheme.theme_desc,
					theme_summary: editedTheme.theme_summary,
					child_themes: editedTheme.child_themes,
					parent_theme: editedTheme.parent_theme
				}
				: theme
		);
	}

	return [...themes];
};

export const removeTheme = (themes, removedTheme) => {
	const existingTheme = themes.find(
		(theme) => theme.id === removedTheme.id
	);

	return themes.filter((theme) =>
		theme.id !== existingTheme.id
	);
};

export const removeSubTheme = (selectedTheme, removedSubtheme, themes) => {

	const subthemes = selectedTheme.child_themes

	const existingSubtheme = subthemes.find(
		(subtheme) => subtheme.id === removedSubtheme.id
	);


	const filteredSubthemes = subthemes.filter((theme) =>
		theme.id !== existingSubtheme.id

	);

	// let updatedTheme = { ...selectedTheme, child_themes: filteredSubthemes };

	return themes.map((theme) =>

		theme.id === selectedTheme.id ? {
			...selectedTheme,
			child_themes: filteredSubthemes
		} : theme
	)

};