import React from 'react';
import { useState, useEffect } from 'react';
import Select, { components } from "react-select";
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import dropdownIcon from '../../../../assets/dropdownicon.svg'

const DropdownQuestion = ({
	id,
	text,
	question_type,
	choices,
	setError,
	setAnswers,
	answers,
	questionNumber,
	required_flag,
	onInputChanged,
}) => {


	// const [selectedOption, setSelectedOption] = useState({ value: '', label: 'None selected' });
	const defaultValue = { value: '', label: 'None selected' }

	let formattedOptions = choices.map(option => {
		return { ...option, label: option.value }

	})

	let options = [defaultValue, ...formattedOptions]


	const DropdownIndicator = props => {
		return (
			<components.DropdownIndicator {...props}>
				<img src={dropdownIcon} alt='dropdown_icon' style={{ height: '0.6em' }} />
			</components.DropdownIndicator>
		);
	};


	const handleSelect = (e) => {


		setAnswers({
			...answers,
			[`Question_${parseInt(id)}`]: {
				questionID: parseInt(id),
				questionType: `${question_type}`,
				response: e.value
			},
		});

		if (required_flag) {
			onInputChanged(
				{
					target:
					{
						value: e.value,
						name: id,
					}
				}, id)
		}


	}

	return (
		<Container
			fluid
			className='forms-box-dropdown'
		>
			{required_flag ? <Row>
				<Col>
					<div className='question-title'>
						<span className='question'>{questionNumber}. {text} </span>
						<span style={{ color: 'red', fontSize: '1.5em' }}>*</span>
					</div>
				</Col>
			</Row> :
				<Row>
					<Col>
						<div className='question-title'>
							<span className='question'>{questionNumber}. {text}</span>
						</div>
					</Col>
				</Row>
			}
			<Row>

				{required_flag ? <Col>

					<Select
						defaultValue={defaultValue}
						components={{ DropdownIndicator }}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								backgroundColor: 'var(--background-color)',
								width: '100%',
								border: '3px solid var(--form-text-color)',
								borderRadius: '8.61px',
								height: '50px',
								boxShadow: 'none',
								"&:hover": {
									borderColor: "revar(--form-text-color)"
								},
								"@media only screen and (min-width: 490px)": {
									width: "50%",
								},

							}),
							menu: (baseStyles, state) => ({
								...baseStyles,
								backgroundColor: 'var(--background-color)',
								width: '100%',
								border: '2px solid black',
								borderTop: 'none',
								"@media only screen and (min-width: 490px)": {
									width: "50%",
								},
							}),

							option: (baseStyles, state) => ({
								...baseStyles,
								background: state.isFocused
									? 'var(--color-r)'
									: state.isSelected
										? 'var(--color-p)'
										: null,

								color: state.isFocused
									? 'var(--color-u)'
									: state.isSelected
										? 'white'
										: 'var(--color-u)',
							}),
							indicatorSeparator: (baseStyles, state) => ({
								...baseStyles,
								display: 'none',
							})

						}}
						onChange={handleSelect}
						options={options}
					/>


				</Col>


					:

					<Col>
						<Select
							defaultValue={defaultValue}
							components={{ DropdownIndicator }}
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									backgroundColor: 'var(--background-color)',
									width: '100%',
									border: '3px solid var(--form-text-color)',
									borderRadius: '8.61px',
									height: '50px',
									boxShadow: 'none',
									"&:hover": {
										borderColor: "revar(--form-text-color)"
									},
									"@media only screen and (min-width: 490px)": {
										width: "50%",
									},

								}),
								menu: (baseStyles, state) => ({
									...baseStyles,
									backgroundColor: 'var(--background-color)',
									width: '100%',
									border: '2px solid black',
									borderTop: 'none',
									"@media only screen and (min-width: 490px)": {
										width: "50%",
									},
								}),

								option: (baseStyles, state) => ({
									...baseStyles,
									background: state.isFocused
										? 'var(--color-r)'
										: state.isSelected
											? 'var(--color-p)'
											: null,

									color: state.isFocused
										? 'var(--color-u)'
										: state.isSelected
											? 'white'
											: 'var(--color-u)',
								}),
								indicatorSeparator: (baseStyles, state) => ({
									...baseStyles,
									display: 'none',
								})

							}}

							onChange={handleSelect}
							options={options}
						/>

					</Col>}

			</Row>
		</Container>
	);
};

export default DropdownQuestion;
