import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Toggle from 'react-toggle'
import './edit-grid-likert-questions.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash, faGripLines } from '@fortawesome/free-solid-svg-icons';
import trashIcon from '../../../../assets/trashicon.svg'
import addIcon from '../../../../assets/addcirclebutton.svg'

import "react-datepicker/dist/react-datepicker.css";

const EditGridLikertQuestion = ({ setMinValue, setMaxValue, setStartLabel, setEndLabel, startLabel, endLabel, minValue, maxValue, rowValues, setRowValues, setReverse, reverse }) => {

    //source: https://bapunawarsaddam.medium.com/add-and-remove-form-fields-dynamically-using-react-and-react-hooks-3b033c3c0bf5

    const minOptions = [0, 1]
    const maxOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10]


    const toggleSwitch = (e) => {
        setReverse(prevReverse => !prevReverse)

    }
    //_____________________ROWS____________________

    let handleRowChange = (i, e) => {
        let newFormValues = [...rowValues];
        newFormValues[i][e.target.name] = e.target.value;
        setRowValues(newFormValues);
    }

    let addRowFormFields = () => {
        setRowValues([...rowValues, { value: "" }])
    }

    let removeRowFormFields = (i) => {
        let newFormValues = [...rowValues];
        newFormValues.splice(i, 1);
        setRowValues(newFormValues)
    }



    //___________ DnD Handle Rows_______________
    function handleOnDragEndRows(result) {
        if (!result.destination) return;

        const items = Array.from(rowValues);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // updateChoices(items)
        setRowValues(items)
    }




    return (
        <div>
            <Container
                fluid
            // className='box'
            >
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <span className='question'>Min:</span>
                            </Col>
                        </Row>
                        <select
                            value={minValue}
                            type='text'
                            className='form-control'
                            onChange={(e) => {
                                setMinValue(parseInt(e.target.value)) // split on comma, into numbers array and also change them into integers as it makes strings initially 
                            }}

                            style={{
                                marginBottom: '10px',
                                marginTop: '10px',
                                width: '30%',
                            }}>
                            {/* <option></option> */}
                            {minOptions.map((option) => {
                                return (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                );
                            })}
                        </select>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <span className='question'>Max:</span>
                            </Col>
                        </Row>
                        <select
                            type='text'
                            className='form-control'
                            onChange={(e) => {
                                setMaxValue(e.target.value.split(',').map(x => parseInt(x))) // split on comma, into numbers array and also change them into integers as it makes strings initially 
                            }}
                            value={maxValue}
                            style={{
                                marginBottom: '10px',
                                marginTop: '10px',
                                width: '30%',
                            }}>
                            {/* <option></option> */}
                            {maxOptions.map((option) => {
                                return (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                );
                            })}
                        </select>
                    </Col>
                </Row>
            </Container>
            <Container
                fluid
                // className='box'
                style={{
                    paddingTop: '20px',
                }}>
                <Row>
                </Row>
                <Row>
                    <Row> <span className='question' style={{ paddingBottom: '1em' }}>Min label:</span></Row>

                    <Col>
                        <input
                            required
                            className='form-control'
                            value={startLabel}
                            maxLength='35'
                            placeholder='max 35 characters'
                            onChange={(e) => {
                                setStartLabel(e.target.value.replace(
                                    /[^\x20-\x7E]+/g,
                                    ''
                                ))
                                // }
                            }}

                        />
                    </Col>
                </Row>
            </Container>
            <Container
                fluid
                style={{
                    paddingTop: '20px',
                    paddingBottom: '15px',
                    marginBottom: '15px',
                }}>
                <Row>
                </Row>
                <Row>
                    <Row> <span className='question' style={{ paddingBottom: '1em' }}>Max label:</span></Row>

                    <Col>
                        <input
                            required
                            className='form-control'
                            value={endLabel}
                            maxLength='35'
                            placeholder='max 35 characters'
                            onChange={(e) => {

                                setEndLabel(e.target.value.replace(
                                    /[^\x20-\x7E]+/g,
                                    ''
                                ))

                            }}

                        />
                    </Col>
                </Row>
            </Container>
            <span className='grid-col-row-labels'><strong>Rows:</strong></span>
            <DragDropContext onDragEnd={handleOnDragEndRows}>
                <Droppable droppableId="rows">
                    {(provided) => (
                        <div className="choices" style={{ marginLeft: '1em', marginTop: '1em' }} {...provided.droppableProps} ref={provided.innerRef}>
                            {rowValues.map((element, index) => (
                                <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                                    {(provided) => (
                                        <div className="form-inline-choice-rows" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <div className="create-theme-button-container" >
                                                <FontAwesomeIcon icon={faGripLines} className="girp-theme-button" />
                                            </div>
                                            <label className='row-number-label'>{index + 1}</label>
                                            <input type="text" name="value" value={element.value || ""} onChange={e => handleRowChange(index, e)} maxLength='50'
                                                placeholder='max 50 characters' className='grid-col-row-inputs' />
                                            {
                                                index ?

                                                    <div className="create-theme-button-container" >

                                                        <img src={trashIcon} alt='trash_button' className="remove-theme-button" style={{ height: "26px", width: "26px" }} onClick={() => {
                                                            removeRowFormFields(index);
                                                        }}
                                                        />
                                                    </div>
                                                    : null
                                            }
                                        </div>)}
                                </Draggable>
                            ))}

                            <div className="add-choice-button-container" >
                                <img style={{ height: '1.8em' }} src={addIcon} alt='add_button' onClick={() => {
                                    addRowFormFields();
                                }}
                                />
                                <span className="add-choice-button-text">Add Row</span>
                            </div>

                            {provided.placeholder}
                        </div>)}
                </Droppable>
            </DragDropContext>
            <Container
                fluid
            >
                <Row>
                    <Col>
                        <span className='question'>Reverse Coded:</span>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <input id="toggle-on-reverse-coded" className="toggle toggle-left" name="siwtch-reverse-coded" value="false" type="radio" defaultChecked={reverse ? false : true} onChange={toggleSwitch} />
                        <label htmlFor="toggle-on-reverse-coded" className="btn">No</label>
                        <input id="toggle-off-reverse-coded" className="toggle toggle-right" name="siwtch-reverse-coded" value="true" type="radio" defaultChecked={reverse} onChange={toggleSwitch} />
                        <label htmlFor="toggle-off-reverse-coded" className="btn">Yes</label>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}



export default EditGridLikertQuestion;
