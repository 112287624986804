import React, { useState, useEffect } from 'react';
import axios from "../../../misc/modifiedAxios";
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/

import "../../../_vars.scss"
import { faCloudShowersHeavy } from '@fortawesome/free-solid-svg-icons';

const CreateOrganisationModal = ({ showModal, setShowModal }) => {

    const history = useHistory();
    //---useSelector get client Id
    //POST object

    const dispatch = useDispatch()
    const [newOrganisation, setNewOrganisation] = useState(null)
    const [isLoading, setisLoading] = useState(false);
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const user = useSelector((state) => state.user.user);


    let company
    let userid

    if (user) {
        userid = user.id
        company = user.company
    }

    const [isEnabled, setEnabled] = useState(true)


    const submit = async (e) => {
        e.preventDefault();
        setEnabled(false)

        try {

            setisLoading(true)
            const newOrganisation = {
                org_name: name,
                org_desc: description,
                userID: userid,
                userCompany: company
            };


            const response = await axios.post(
                `/organisations`,
                newOrganisation
            ).catch(
                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                })

            setEnabled(true)


            const { org_name, org_desc, id } = response.data

            setNewOrganisation({
                id,
                org_name,
                org_desc,
            })

            setName('')
            setDescription('')
            setisLoading(false)
            setShowModal(false);

        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }
    };

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change


        if (newOrganisation !== null) {

            const setOrganisation = () => {
                dispatch({
                    type: 'ADD_NEW_ORGANISATION',
                    payload: newOrganisation
                })
            }

            setOrganisation();
        }

    }, [dispatch, newOrganisation]);



    return (
        <Modal
            show={showModal}
            backdrop="static"
            onHide={() => {
                setShowModal(false);
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Create Organisation</Modal.Title>
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body >


                    {isLoading ? (
                        <div className='spinner'>
                            <SpinnerCircularFixed
                                size={60}
                                thickness={140}
                                speed={120}
                                color='var(--spinner-color)'
                                secondaryColor='rgb(240, 240, 240)
                                            '
                            />
                            <div className='spinner-text'>
                                <span>Creating Organisation...</span>
                            </div>
                        </div>
                    ) : (

                        <>
                            <Container
                                fluid

                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span className='question'>Name:</span>
                                            <span style={{ color: 'red', fontSize: '15px' }}>*Please ensure the name is correct, as it cannot be changed later. </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <input
                                            required
                                            className='form-control'
                                            onChange={(e) => {
                                                // setError('');

                                                //prevent only 'space' answers and clear out emojis (.replace())
                                                // if (e.target.value.trim() !== '') {

                                                setName(e.target.value.replace(
                                                    /[^\x20-\x7E]+/g,
                                                    ''
                                                ))
                                                // }
                                            }}

                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Container
                                fluid

                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <span className='question'>Description:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea
                                            // required
                                            className='form-control'
                                            onChange={(e) => {
                                                // setError('');

                                                //prevent only 'space' answers and clear out emojis (.replace())
                                                // if (e.target.value.trim() !== '') {

                                                setDescription(e.target.value.replace(
                                                    /[^\x20-\x7E]+/g,
                                                    ''
                                                ))
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>

                        </>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='no-variant'
                        className='modal-cancel-button'
                        onClick={() => {
                            setName('')
                            setDescription('')
                            setisLoading(false)
                            setShowModal(false);
                        }}>
                        Cancel
                    </Button>
                    {name ? <Button
                        onClick={() => {
                            setShowModal(false);
                        }}
                        disabled={false}
                        type='submit'
                        className='modal-create-button'
                        variant='no-variant-p'>
                        Create
                    </Button> :
                        <Button
                            onClick={() => {
                                setShowModal(false);
                            }}
                            disabled={true}
                            type='submit'
                            className='modal-create-button'
                            variant='no-variant-p'>
                            Create
                        </Button>}
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateOrganisationModal;
