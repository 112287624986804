import React, { useState, useEffect } from 'react';

import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

const CheckboxQuestion = ({
	id,
	text,
	question_type,
	setError,
	setAnswers,
	answers,
	rating,
	choices,
	questionNumber,
	required_flag,
	onMultipleInputChanged
}) => {

	const [chosenValues, setChosenValues] = useState([]);

	const [count, setCount] = useState(0);

	useEffect(() => {

		if (count > 0) {
			const UpdateParentAnswer = () => {
				let response;

				response = {
					...answers,

					[`Question_${parseInt(id)}`]: {
						questionID: parseInt(id),
						questionType: `${question_type}`,
						response: chosenValues,
					},
				};

				setAnswers(response);

				if (required_flag) {
					onMultipleInputChanged(id, chosenValues, question_type)
				}


			};
			UpdateParentAnswer();
		}

		setCount(1);
	}, [chosenValues]);

	return (
		<Container
			fluid
			className='forms-box'
		>
			{required_flag ? <Row>
				<Col>
					<div className='question-title'>
						<span className='question'>{questionNumber}. {text} </span>
						<span style={{ color: 'red', fontSize: '1.5em' }}>*</span>
					</div>
				</Col>
			</Row> :
				<Row>
					<Col>
						<div className='question-title'>
							<span className='question'>{questionNumber}. {text}</span>
						</div>
					</Col>
				</Row>
			}
			<Row>
				{required_flag ? (chosenValues.length > 0 ? <Col>
					<div
					// className='linkert-container'
					>
						{choices.map((x) => {
							return (
								<Row key={x.id}>
									<div className='mcq-radio-label-container '>
										<input
											onChange={(e) => {


												if (
													chosenValues.includes(
														e.target.value
													)


												) {

													let updatedValues =
														chosenValues.filter(
															(x) =>
																x !==
																e.target.value
														);

													setChosenValues(
														updatedValues
													);

												} else {
													// let updatedValues = chosenValues.push(e.target.value)

													setChosenValues([
														...chosenValues,
														e.target.value,
													]);
												}
											}}
											type='checkbox'
											value={x.value}
											name={id}
											className='linkert-radios-mcq'
										/>
										{x.value}
									</div>
								</Row>
							);
						})}
					</div>
				</Col> : <Col>
					<div
					// className='linkert-container'
					>
						{choices.map((x) => {
							return (
								<Row key={x.id}>
									<div className='mcq-radio-label-container '>
										<input
											onChange={(e) => {

												if (
													chosenValues.includes(
														e.target.value
													)
												) {
													let updatedValues =
														chosenValues.filter(
															(x) =>
																x !==
																e.target.value
														);

													setChosenValues(
														updatedValues
													);
												} else {
													// let updatedValues = chosenValues.push(e.target.value)

													setChosenValues([
														...chosenValues,
														e.target.value,
													]);
												}
											}}
											type='checkbox'
											required
											value={x.value}
											name={id}
											className='linkert-radios-mcq'
										/>
										{x.value}
									</div>
								</Row>
							);
						})}
					</div>
				</Col>) :

					<Col>
						<div
						// className='linkert-container'
						>
							{choices.map((x) => {
								return (
									<Row key={x.id}>
										<div className='mcq-radio-label-container '>
											<input
												onChange={(e) => {
													if (
														chosenValues.includes(
															e.target.value
														)
													) {
														let updatedValues =
															chosenValues.filter(
																(x) =>
																	x !==
																	e.target.value
															);

														setChosenValues(
															updatedValues
														);
													} else {
														// let updatedValues = chosenValues.push(e.target.value)

														setChosenValues([
															...chosenValues,
															e.target.value,
														]);
													}
												}}
												type='checkbox'
												value={x.value}
												name={id}
												className='linkert-radios-mcq'
											/>
											{x.value}
										</div>
									</Row>
								);
							})}
						</div>
					</Col>}
			</Row>
		</Container>
	);
};

export default CheckboxQuestion;
