import React from 'react';
import './subtheme-item.styles.scss';
import axios from "../../../misc/modifiedAxios";
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import ErrorCatch from '../../Shared/Error/error-catch';
import ThemeRowItem from '../Themerowitem/theme-row-item.component';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

function SubthemeItem({ id, sys_default, theme_name, theme_desc, theme_summary, setEditShowModal, setIsDeleting }) {
    //setSubthemeOpen
    // onClick dispatch the passed in id as currently selected cohort


    const history = useHistory();

    const dispatch = useDispatch()

    const selectSubtheme = () => {
        dispatch({
            type: 'SELECT_SUBTHEME',
            payload: { id, theme_name, theme_desc, theme_summary }
        })
    }
    const toggleEditModal = () => {

        setEditShowModal(true)

    }
    const deleteTheme = async () => {

        try {
            let response
            setIsDeleting(true)
            response = await axios.delete(
                `/themes/${id}`).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })

            setIsDeleting(false)
        }
        catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }

        dispatch({
            type: 'DELETE_SUBTHEME',
            payload: { id, theme_name, theme_desc, theme_summary }
        })

    }


    return (

        //  onClick={selectTheme}
        <tr className='theme-row' onClick={selectSubtheme}>
            {/* <td>1</td> */}
            <ThemeRowItem textFull={theme_name} width='2.5em' />
            <ThemeRowItem textFull={theme_desc} width='2.5em' />
            <ThemeRowItem textFull={theme_summary} width='2.5em' />
            {sys_default ? null : <td style={{ width: '5%' }}>
                <div className="create-theme-button-container" >
                    <FontAwesomeIcon icon={faEdit} className="edit-theme-button" onClick={() => {
                        toggleEditModal()
                    }}
                    />
                </div>
            </td>}
            {sys_default ? null : <td td style={{ width: '5%' }}>
                <div className="create-theme-button-container" >
                    <FontAwesomeIcon icon={faTrash} className="remove-theme-button" onClick={() => {
                        window.confirm("Are you sure you wish to delete this item?") &&
                            deleteTheme()
                    }} />
                </div>
            </td>}
        </tr>

    );
}

export default SubthemeItem;

