import { updateQuestionDetails, removeQuestion } from './question.utils'

const INITIAL_STATE = {
	questions: [],
	selectedQuestion: null,
};

const questionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_QUESTIONS':
			return {
				...state,
				questions: action.payload,
			};
		case 'SELECT_QUESTION':
			return {
				...state,
				selectedQuestion: action.payload,
			};
		case 'ADD_NEW_QUESTION':
			return {
				...state,
				questions: [action.payload, ...state.questions],
			};
		case 'DELETE_QUESTION':
			return {
				...state,
				questions: removeQuestion(state.questions, action.payload),
				selectedQuestion: action.payload
			};
		case 'UPDATE_QUESTION_DETAILS':
			return {
				...state,
				questions: updateQuestionDetails(state.questions, action.payload),
				selectedQuestion: action.payload
			};
		default:
			return state;
	}
};

export default questionReducer;
