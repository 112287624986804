import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
// import { Container } from 'react-bootstrap';
// import { Row } from 'react-bootstrap';
// import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faGripLines } from '@fortawesome/free-solid-svg-icons';
import './choice-questions.styles.scss'
import addIcon from '../../../../assets/addcirclebutton.svg'
import trashIcon from '../../../../assets/trashicon.svg'
import "react-datepicker/dist/react-datepicker.css";

const ChoiceQuestion = ({ formValues, setFormValues }) => {

    //source: https://bapunawarsaddam.medium.com/add-and-remove-form-fields-dynamically-using-react-and-react-hooks-3b033c3c0bf5


    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);

    }

    let addFormFields = () => {
        setFormValues([...formValues, { value: "" }])
        // updateChoices([...formValues, { value: "" }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    // const dndformValues = formValues.map((item, index) => {
    //     return {
    //         value: item.value,
    //         id: index.toString()
    //     }
    // })

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(formValues);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // updateChoices(items)
        setFormValues(items)
    }





    return (

        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
                {(provided) => (
                    <div className="characters" style={{ marginLeft: '1em', marginTop: '1em' }} {...provided.droppableProps} ref={provided.innerRef}>
                        {formValues.map((element, index) => {
                            return (
                                <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                                    {(provided) => (
                                        <div className="form-inline-choice-rows" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <div className="create-theme-button-container" >
                                                <FontAwesomeIcon icon={faGripLines} className="girp-theme-button" />
                                            </div>
                                            <label className='row-number-label'>{index + 1}</label>
                                            <input type="text" name="value" value={element.value || ""} onChange={e => handleChange(index, e)} maxLength='120'
                                                placeholder='max 120 characters' className='choice-inputs' />
                                            {
                                                index ?

                                                    <div className="create-theme-button-container" >

                                                        <img src={trashIcon} alt='trash_button' className="remove-theme-button" style={{ height: "26px", width: "26px" }} onClick={() => {
                                                            removeFormFields(index);
                                                        }}
                                                        />
                                                    </div>


                                                    : null
                                            }
                                        </div>
                                    )}


                                </Draggable>
                            );
                        })}


                        <div className="add-choice-button-container" >
                            <img style={{ height: '1.8em' }} src={addIcon} alt='add_button' onClick={() => {
                                addFormFields();
                            }}
                            />
                            <span className="add-choice-button-text">Add Choice</span>
                        </div>


                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}



export default ChoiceQuestion;
