import React, { useRef, useState } from 'react';
import axios from '../../../misc/modifiedAxios'
import './sidenavbar.component.scss';

import { withRouter } from 'react-router-dom';

import { ReactComponent as Logo } from '../../../assets/avatar.svg';

import { useSelector, useDispatch } from 'react-redux'


import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

import Button from "react-bootstrap/Button"

import Overlay from 'react-bootstrap/Overlay';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../_vars.scss"

// import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import analyticswhite from '../../../assets/analyticswhite.svg';
import analyticsblue from '../../../assets/analyticsblue.svg';
import surveymanagerblue from '../../../assets/surveymanagerblue.svg';
import surveymanagerwhite from '../../../assets/surveymanagerwhite.svg';
import taskwhite from '../../../assets/taskwhite.svg';
import taskblue from '../../../assets/taskblue.svg';
import settingsBlue from '../../../assets/settingsBlue.svg';
import settingsWhite from '../../../assets/settingsWhite.svg';
import BottomSVGImage from '../../../assets/BottomSVGImage.svg';


import SenseLogoWhite from '../../../assets/SenseLogoWhite.svg';


function SideNavBar({ history }) {



    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const user = useSelector((state) => state.user);


    let organisations
    let organisationId

    if (user) {
        organisations = user.organisations
        organisationId = user.organisationId
    }


    const formatedOrganisations = organisations?.map(x => {
        return {
            company_name: x.company_name,
            id: x.id
        }
    })


    // const [show, setShow] = useState(false);
    // const target = useRef(null);
    const dispatch = useDispatch()


    const logOut = (e) => {
        localStorage.removeItem('jwt')
        localStorage.removeItem('organisationId')
        dispatch({ type: "SET_USER", payload: null });
        history.push('/')
    }

    // const setLastActiveOrg = (e) => {

    // }

    const setLastActiveOrg = async (e) => {


        e.preventDefault()

        try {

            let lastActiveOrg = { active_og: e.target.value }

            const updateActiveOrg = await axios.put(`/users/${user.user.id}`, lastActiveOrg).catch(
                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                }
            );


        }

        catch (err) {
            console.log(err)

        }

    }


    let cohortstyle;
    let cohortimage;
    let cohorttext;
    if (history.location.pathname.includes('/cohorts')) {
        cohortstyle = { backgroundColor: 'var(--navbar-selected)' };
        cohortimage = analyticsblue;
        cohorttext = { color: "var(--navbar-background)" };
    } else {
        cohortstyle = { backgroundColor: 'var(--navbar-background)' };
        cohortimage = analyticswhite;
        cohorttext = {};
    }


    let questionstyle;
    let questionimage;
    let questiontext;
    if (history.location.pathname.includes('/question-manager')) {
        questionstyle = { backgroundColor: 'var(--navbar-selected)' };
        questionimage = taskblue;
        questiontext = { color: "var(--navbar-background)" };
    } else {
        questionstyle = { backgroundColor: 'var(--navbar-background)' };
        questionimage = taskwhite;
        questiontext = {};
    }



    let surveystyle;
    let surveyimage;
    let surveytext;
    if (history.location.pathname.includes('/survey-schedule-builder')) {
        surveystyle = { backgroundColor: 'var(--navbar-selected)' };
        surveyimage = surveymanagerblue;
        surveytext = { color: "var(--navbar-background)" };
    } else {
        surveystyle = { backgroundColor: 'var(--navbar-background)' };
        surveyimage = surveymanagerwhite;
        surveytext = {};
    }


    let settingsstyle;
    let settingsimage;
    let settingstext;
    if (history.location.pathname.includes('/settings') || history.location.pathname.includes('/organisations')) {
        settingsstyle = { backgroundColor: 'var(--navbar-selected)' };
        settingsimage = settingsBlue;
        settingstext = { color: "var(--navbar-background)" };
    } else {
        settingsstyle = { backgroundColor: 'var(--navbar-background)' };
        settingsimage = settingsWhite;
        settingstext = {};
    }





    return (
        <div className='side-nav'>

            <div className='controls-container'>

                <img src={SenseLogoWhite} className="sense-logo" alt='edit_button' />
                {/* </OverlayTrigger> */}
                <div className="group-button" style={{ cursor: 'default' }}>
                    <span className="user-rank">Organisation:</span>
                    <select value={organisationId}
                        type='text'
                        className='form-control organisation-dropdown'
                        onChange={(e) => {
                            localStorage.setItem('organisationId', e.target.value)
                            dispatch({ type: "SET_ORGANISATION", payload: parseInt(e.target.value) });
                            setLastActiveOrg(e)
                        }}

                        style={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            width: '8em',
                            fontSize: 'medium',
                            cursor: 'pointer',
                            backgroundColor: 'var(--navbar-select-component-background)',
                            color: 'var(--navbar-select-component-text)',
                            borderColor: 'var(--navbar-select-component-border-color)'
                        }}>
                        {/* <option>{minValue}</option> */}
                        {formatedOrganisations?.map((option, i) => {
                            return (
                                <option key={i} value={option.id}>
                                    {option.company_name}
                                </option>
                            );
                        })}

                    </select>
                </div>


                {/* OLD QM '/question-manager/questions' */}

                <div className="group-button" style={questionstyle} onClick={() => history.push('/question-manager')}>
                    {/*<FontAwesomeIcon icon={faQuestionCircle} className="group-button-icon" />*/}
                    <span className="group-button-text" style={questiontext}>Question Manager</span>
                    <img src={questionimage} className="edit-theme-button" alt='edit_button' />
                </div>
                <div className="group-button" style={surveystyle} onClick={() => history.push('/survey-schedule-builder/surveys')}>
                    {/*<FontAwesomeIcon icon={faCalendarAlt} className="group-button-icon" />*/}
                    <span className="group-button-text" style={surveytext}>Survey Builder</span>
                    <img src={surveyimage} className="edit-theme-button" alt='edit_button' />
                </div>


                <div ref={ref} className="group-button" style={settingsstyle} onClick={handleClick}>
                    <span className="group-button-text" style={settingstext}>Settings</span>
                    <img src={settingsimage} className="edit-theme-button" alt='edit_button' />
                    <Overlay
                        show={show}
                        target={target}
                        placement="right"
                        container={ref}
                        containerPadding={20}
                    >
                        <Popover id="popover-contained">
                            <Popover.Header as="h3">Settings</Popover.Header>
                            <Popover.Body>
                                <Button style={{ marginBottom: '5px' }} variant="none" className='secondary-button' onClick={logOut}>Log Out</Button>
                                <Button style={{ marginBottom: '5px' }} variant="none" className='secondary-button' onClick={() => history.push('/organisations/register')}>Organisations</Button>
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                </div>

                <img src={BottomSVGImage} className="bottom-image" alt='edit_button' />

            </div>

        </div>
    );

}

export default withRouter(SideNavBar);