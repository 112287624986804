import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import styled from 'styled-components'
import { useTable } from 'react-table'
import { Modal, Button } from 'react-bootstrap';
import './organisation-table.styles.scss'
import axios from "../../misc/modifiedAxios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAltSquare } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle, faTrash, faGripLines, faEdit } from '@fortawesome/free-solid-svg-icons';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';




const Styles = styled.div`
  padding: 1rem;

  
  table {
    width:98%;
    border-spacing: 0;
    // border: 1px solid black;
    }
   tr:nth-child(even){background-color: #f2f2f2;}
   tr:hover {background-color: #ddd;}

    th{
        border-bottom: 1px solid black;
        padding-left:5px
    },

    td {
      margin: 0;
      padding: 0.5rem;
    //   border-bottom: 1px solid black;
    //   border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`



function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <table {...getTableProps()}>

            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const deleteOrganisation = async (e, history, dispatch, defaultOrg, user, setisLoading) => {

    const orgId = e.id



    try {
        let response

        setisLoading(true)

        response = await axios.delete(
            `/organisations/${orgId}`).catch(

                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                })
            ;


        let lastActiveOrg = { active_org: defaultOrg.id }


        const updateActiveOrg = await axios.put(`/users/${user.id}`, lastActiveOrg).catch(
            (error) => {
                if (error.response.status === 403) {
                    localStorage.removeItem('jwt')
                    localStorage.removeItem('organisationId')
                    history.push('/')
                }

                if (error.response.status === 401) {

                    alert("Session has expired, please re-login.")
                    localStorage.removeItem('jwt')
                    localStorage.removeItem('organisationId')
                    history.push('/')

                }
                // <ErrorCatch error={error} />
            }
        );






        localStorage.setItem("organisationId", defaultOrg.id);

        dispatch({
            type: 'DELETE_ORGANISATION',
            payload: response.data
        })
        dispatch({
            type: 'SELECT_ORGANISATION',
            payload: null
        })
        dispatch({
            type: 'SET_ORGANISATION',
            payload: defaultOrg.id
        })

        setisLoading(false)

    }
    catch (err) {
        console.log(err)
        // err && setError('Internal error, please contact survey admin');
        window.alert('Internal error, please contact admin');
    }


}

function OrganisationTable({ organisations, setEditModal, setShowUrlModal }) {

    const dispatch = useDispatch()
    const history = useHistory()

    const [selectedOrg, setSelectedOrg] = useState('')
    const defaultOrg = useSelector((state) => state.user.defaultOrg);
    const user = useSelector((state) => state.user.user);
    const [isLoading, setisLoading] = useState(false);

    const filteredOrgs = organisations.filter(x => x.org_name !== defaultOrg.org_name)

    // const [showUrlModal, setUrlModal] = useState(false);

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (selectedOrg !== null) {
            const selectOrg = () => {
                dispatch({
                    type: 'SELECT_ORGANISATION',
                    payload: selectedOrg
                })
            }

            selectOrg();
        }

    }, [dispatch, selectedOrg]);



    const columnsReady = React.useMemo(

        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },

            {
                Header: 'Name',
                accessor: 'org_name',
            },
            {
                Header: 'Description',
                accessor: 'org_desc',
            },

            {
                Header: '  ',
                Cell: ({ cell }) => {



                    return (<div className="create-theme-button-container" >
                        <FontAwesomeIcon icon={faEdit} className="edit-theme-button" value={cell.row.original} onClick={() => {
                            setSelectedOrg(cell.row.original)
                            setEditModal(true)
                        }}
                        />
                    </div>)
                }
            },

            {
                Header: '   ',
                // defaultCanFilter: true,
                Filter: false,
                Cell: ({ cell }) => {


                    return (<div className="create-theme-button-container" >
                        <FontAwesomeIcon icon={faTrash} className="remove-theme-button" value={cell.row.original} onClick={() => {

                            window.confirm("Are you sure you wish to delete this item?") &&
                                deleteOrganisation(cell.row.original, history, dispatch, defaultOrg, user, setisLoading)
                        }} />
                    </div>)


                }
            }

        ],
        [setEditModal, setShowUrlModal]
    )


    // const data = React.useMemo(() => makeData(150), [])

    return (
        isLoading ? (
            <div className='spinner'>
                <SpinnerCircularFixed
                    size={60}
                    thickness={140}
                    speed={120}
                    color='var(--spinner-color)'
                    secondaryColor='rgb(240, 240, 240)
                                            '
                />
                <div className='spinner-text'>
                    <span>Deleting Organisation...</span>
                </div>
            </div>
        ) : (
            <Styles>
                <Table
                    columns={columnsReady}
                    data={filteredOrgs}
                />
            </Styles>
        )
    )
}

export default OrganisationTable
