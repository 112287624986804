import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

const MCQQuestion = ({
	id,
	text,
	question_type,
	setError,
	setAnswers,
	answers,
	rating,
	choices,
	questionNumber,
	required_flag,
	onInputChanged
}) => {

	const radioName = uuidv4()

	return (
		<Container
			fluid
			className='forms-box'
		>
			{required_flag ? <Row>
				<Col>
					<div className='question-title'>
						<span className='question'>{questionNumber}. {text} </span>
						<span style={{ color: 'red', fontSize: '1.5em' }}>*</span>
					</div>
				</Col>
			</Row> :
				<Row>
					<Col>
						<div className='question-title'>
							<span className='question'>{questionNumber}. {text}</span>
						</div>
					</Col>
				</Row>
			}
			<Row>
				{required_flag ? <Col>
					<div
						className='mcq-all-radios-container'
						onChange={(e) => {
							setError('');
							setAnswers({
								...answers,
								[`Question_${parseInt(id)}`]: {
									questionID: parseInt(id),
									questionType: question_type,
									response: e.target.value,
								},
							});
							onInputChanged(e, id)
						}}>
						{/* <span className='linkert-label-left'>
							Strongly Disagree
						</span> */}

						{choices.map((x) => {
							return (
								<Row key={x.id}>
									<div className='mcq-radio-label-container'>
										<input
											required
											type='radio'
											value={x.value}
											name={radioName}
											className='linkert-radios-mcq'
										/>
										{x.value}
									</div>
								</Row>
							);
						})}
					</div>
				</Col>

					:

					<Col>
						<div
							className='mcq-all-radios-container'
							onChange={(e) => {
								setError('');
								setAnswers({
									...answers,
									[`Question_${parseInt(id)}`]: {
										questionID: parseInt(id),
										questionType: question_type,
										response: e.target.value,
									},
								});
							}}>
							{/* <span className='linkert-label-left'>
							Strongly Disagree
						</span> */}

							{choices.map((x) => {
								return (
									<Row key={x.id}>
										<div className='mcq-radio-label-container'>
											<input
												type='radio'
												value={x.value}
												name={radioName}
												className='linkert-radios-mcq'
											/>
											{x.value}
										</div>
									</Row>
								);
							})}
						</div>
					</Col>}

			</Row>
		</Container>
	);
};

export default MCQQuestion;
