import React, { useState, useEffect } from 'react';
import axios from "../../../misc/modifiedAxios";
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
import ErrorCatch from '../../Shared/Error/error-catch';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';


const EditSubtheme = ({ showEditModal, setEditShowModal }) => {

    const selectedChildTheme = useSelector((state) => state.theme.selectedChildTheme);
    const selectedParentTheme = useSelector((state) => state.theme.selectedTheme);


    const [isLoading, setisLoading] = useState(false);
    const history = useHistory();
    const subthemeId = selectedChildTheme?.id

    const [currentThemeDetails, setcurrentThemeDetails] = useState({
        theme_name: '',
        theme_desc: '',
        theme_summary: ''
    })

    const { theme_name, theme_desc, theme_summary } = currentThemeDetails

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        const loadSubthemeDetails = () => {

            if (selectedChildTheme !== null) {

                setcurrentThemeDetails({
                    theme_name: selectedChildTheme.theme_name,
                    theme_desc: selectedChildTheme.theme_desc,
                    theme_summary: selectedChildTheme.theme_summary
                })
            }

        }

        loadSubthemeDetails();

    }, [selectedChildTheme]);


    const dispatch = useDispatch()
    const [updatedThemeSelection, setUpdatedThemeSelection] = useState(null)
    const [updatedChildTheme, setUpdatedChildTheme] = useState(null)


    const submit = async (e) => {
        e.preventDefault();



        try {
            setisLoading(true)
            const editedSubtheme = {
                // {
                //     theme_name: currentThemeDetails.theme_name,
                //     theme_desc: currentThemeDetails.theme_desc,
                //     theme_summary: currentThemeDetails.theme_summary,
                // }
                data: {
                    theme_name: currentThemeDetails.theme_name,
                    theme_desc: currentThemeDetails.theme_desc,
                    theme_summary: currentThemeDetails.theme_summary,
                    parentThemeID: selectedParentTheme?.id

                }
            }

            const response = await axios.put(
                `/themes/${subthemeId}`,
                editedSubtheme
            ).catch(
                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                })



            const { theme_name, theme_desc, theme_summary, id, parent_theme, child_themes } = response.data


            // setUpdatedThemeSelection({
            //     id,
            //     theme_name,
            //     theme_desc,
            //     theme_summary,
            //     parent_theme,
            //     child_themes
            // })



            let formattedChiledThemes = child_themes.filter(x => x.parent_theme_flag !== true)

            let updatedChildtheme

            let allSpecificThemesQuestions = response?.data?.child_themes.map(x => {

                if (x.id === selectedChildTheme?.id) {
                    setUpdatedChildTheme(x)
                }

                return x.questions
            }).flat(1)

            console.log('updatedChildtheme', updatedChildtheme)

            setUpdatedThemeSelection({
                ...response.data,
                child_themes: formattedChiledThemes,
                questions: [...response.data.questions, ...allSpecificThemesQuestions],
                type: 'regular'
            })

            setisLoading(false)
            setEditShowModal(false);


        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }
    };
    useEffect(() => {

        if (updatedThemeSelection !== null) {
            const setCohortsFromRequest = () => {
                dispatch({
                    type: 'UPDATE_THEME_DETAILS',
                    payload: updatedThemeSelection
                })

                dispatch({
                    type: 'SELECT_CHILD_THEME',
                    payload: updatedChildTheme
                })

            }

            setCohortsFromRequest();
        }

    }, [dispatch, updatedThemeSelection, updatedChildTheme]);



    const deleteSubtheme = async () => {

        try {
            let response
            setisLoading(true)

            response = await axios.delete(
                `/themes/${subthemeId}`).catch(

                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })
                ;

            console.log('response.data', response.data)


            dispatch({
                type: 'DELETE_SUBTHEME',
                payload: response.data
            })
            setisLoading(false)
            setEditShowModal(false);


        }
        catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }



    }




    return (

        <Modal
            size='lg'
            show={showEditModal}
            backdrop="static"
            onHide={() => {
                setEditShowModal(false);
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Question Subtheme</Modal.Title>
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body >
                    {isLoading ? (
                        <div className='spinner'>
                            <SpinnerCircularFixed
                                size={60}
                                thickness={140}
                                speed={120}
                                color='var(--spinner-color)'
                                secondaryColor='rgb(240, 240, 240)
                                            '
                            />
                            <div className='spinner-text'>
                                <span>Updating Subtheme...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <span className='modal-text '>Subtheme Name:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <input
                                            required
                                            value={theme_name}
                                            className='form-control section-inputs'
                                            maxLength='50'
                                            placeholder='max 50 characters'
                                            onChange={(e) => {
                                                //Maybe add to EDITED cohort local state
                                                // if (e.target.value.trim() !== '') {

                                                setcurrentThemeDetails({
                                                    ...currentThemeDetails, theme_name: e.target.value.replace(
                                                        /[^\x20-\x7E]+/g,
                                                        ''
                                                    )
                                                })
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <span className='modal-text '>Subtheme Description:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea
                                            // required
                                            value={theme_desc ? theme_desc : ''}
                                            className='form-control section-inputs'
                                            maxLength='300'
                                            placeholder='max 300 characters'
                                            onChange={(e) => {
                                                //Maybe add to EDITED cohort local state
                                                // if (e.target.value.trim() !== '') {

                                                setcurrentThemeDetails({
                                                    ...currentThemeDetails, theme_desc: e.target.value.replace(
                                                        /[^\x20-\x7E]+/g,
                                                        ''
                                                    )
                                                })
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <span className='modal-text '>Summary:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea
                                            // required
                                            value={theme_summary ? theme_summary : ''}
                                            className='form-control section-inputs'
                                            maxLength='300'
                                            placeholder='max 300 characters'
                                            onChange={(e) => {
                                                //Maybe add to EDITED cohort local state
                                                // if (e.target.value.trim() !== '') {

                                                setcurrentThemeDetails({
                                                    ...currentThemeDetails, theme_summary: e.target.value.replace(
                                                        /[^\x20-\x7E]+/g,
                                                        ''
                                                    )
                                                })
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </>)}


                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                    <div
                        className="clear-button clear-button--secondary"
                        onClick={() => {
                            window.confirm("Are you sure you wish to delete this item?") &&
                                deleteSubtheme()
                        }} >
                        <span>Delete Subtheme</span> </div>
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{ marginRight: '10px' }}
                            className="clear-button clear-button--secondary"
                            onClick={() => {
                                setEditShowModal(false);
                            }} >
                            <span>Cancel</span>
                        </div>
                        <input
                            type='submit'
                            disabled={false}
                            value='Save Changes'
                            className='card-button'
                        />
                        {/* <Button
                            variant='no-variant'
                            className='modal-cancel-button'
                            onClick={() => {
                                setEditShowModal(false);
                            }}>
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='modal-create-button'
                            variant='no-variant-p'>
                            Save Changes
                        </Button> */}
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditSubtheme;
