import React, { useEffect } from 'react';
import axios from "../../misc/modifiedAxios";
import './cohorts.styles.scss';
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import SideNavBar from '../../components/Shared/SideNavBar/sidenavbar.component';
import CohortSelection from '../../components/Cohort-Components/CohortSelection/cohort-selection.component';
import CohortDisplay from '../../components/Cohort-Components/CohortDisplay/cohort-display.component';
import { useHistory } from "react-router-dom";
import ErrorCatch from '../../components/Shared/Error/error-catch';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/

const Cohorts = () => {

    // Get all cohorts request useEffect on componenet did mount

    const [cohorts, setCohorts] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()

    const history = useHistory();

    const organisationId = useSelector((state) => state.user.organisationId);

    useEffect(() => {

        const jwt = localStorage.getItem('jwt')
        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (!jwt) {
            localStorage.removeItem('jwt')
            localStorage.removeItem('organisationId')
            history.push("/")
        }


    }, []);


    useEffect(() => {
        const getCohorts = async () => {
            try {

                setIsLoading(true)
                const response = await axios.get(
                    `/cohorts`,
                ).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                    }
                    // <ErrorCatch />
                )
                    ;
                if (response.data) {
                    setCohorts(response.data)
                    setIsLoading(false)
                }

            } catch (err) {
                console.log(err)
            }
        };


        getCohorts();
    }, [history, organisationId]);

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change
        // if (cohorts.length > 0) {



        const setCohortsFromRequest = () => {
            dispatch({
                type: 'SET_COHORTS',
                payload: cohorts
            })
        }

        setCohortsFromRequest();
        // }

        setIsLoading(false)

    }, [cohorts, dispatch]);




    return (
        <div className='cohorts'>
            <>
                <SideNavBar />
                {isLoading ? (
                    <div className='spinner'>
                        <SpinnerCircularFixed
                            size={60}
                            thickness={140}
                            speed={120}
                            color='var(--spinner-color)'
                            secondaryColor='rgb(240, 240, 240)
												'
                        />
                        <div className='spinner-text'>
                            <span>Loading Cohorts...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <CohortSelection />
                        <CohortDisplay />
                    </>)}
            </>

        </div>
    );
};

export default Cohorts;