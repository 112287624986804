import React, { useState, useEffect, useRef } from 'react';
import axios, { cancelToken } from "../../../misc/modifiedAxios";
// import Axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import './cohort-display.component.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/spinner';
import "../../../_vars.scss";

// import UserItem from '../UserItem/useritem.component';
import EditCohortModal from '../EditCohortModal/edit-cohort-modal.component'
import ApiTable from '../../Shared/ApiTable/api-table.component'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

// at first render of the component is undefined
//could add it into useRef, this way would not be Rerendered whenever useEffect shoots again

const CohortDisplay = () => {

    const CancelToken = useRef(cancelToken)
    const cancel = useRef(undefined)

    const history = useHistory()
    const dispatch = useDispatch()
    //EDIT cohort details modal state
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isUpdating, setisUpdating] = useState(false);

    const [csvText, setCsvText] = useState('')
    const [csvTitle, setCsvTitle] = useState('')

    const selectedCohort = useSelector((state) => state.cohort.selectedCohort);

    const organisationId = useSelector((state) => state.user.organisationId);

    let selectedCohortId

    if (selectedCohort) {
        selectedCohortId = selectedCohort.id
    }



    const [recipientDetails, setRecipientDetails] = useState({
        recipients: []
    })

    useEffect(() => {

        const getSeletedCohort = async () => {
            try {


                if (selectedCohortId) {


                    if (cancel.current !== undefined) {//uses .current property as this is how you manipulate the object in useRef
                        cancel.current(); // same as running cancel() because once it is defined it is assigned a function with new CancelToken (also has .current)

                    }
                    setisLoading(true)

                    const response = await axios.get(
                        `/cohorts/${selectedCohortId}`,
                        {
                            cancelToken: new CancelToken.current(c => {
                                cancel.current = c; // c is the identifier of the request, ON THE FIRST request it DEFINES the cancel variable, meaning
                            }),
                        }
                    ).catch(
                        // (error) => {
                        // if (error.response.status === 403 || error.response.status === 401) {
                        //     localStorage.removeItem('jwt')
                        //     localStorage.removeItem('organisationId')
                        //     history.push('/')
                        // }
                        (error) => {
                            if (error.response.status === 403) {
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')
                            }

                            if (error.response.status === 401) {

                                alert("Session has expired, please re-login.")
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')

                            }
                        }
                    );


                    // ******* maybe will need to replace with useDispatch


                    if (response) {

                        setRecipientDetails({
                            recipients: response.data.recipients
                        })
                        setisLoading(false)
                    }

                }


            } catch (err) {
                console.log(err)

            }
        };

        getSeletedCohort();
    }, [history, selectedCohortId]);

    const handleFile = (e) => {

        e.preventDefault()


        setCsvTitle(e.target.files[0].name)

        if (e.target.files[0]) {
            const reader = new FileReader()
            reader.onload = async (e) => {
                const text = e.target.result;
                setCsvText(text)
            };
            reader.readAsText(e.target.files[0])
        }

        //if the current method is not good can switch to local state that displays file name
        e.target.value = null
        // setInputTitle(csvText)

    }

    const updateRecipients = async (e) => {


        e.preventDefault()

        try {


            setCsvTitle('')
            if (selectedCohortId !== null && csvText.length > 0) {

                setisUpdating(true)


                let recipientData = { recipients: csvText }


                const updateResponse = await axios.put(`/cohorts/${selectedCohortId}`, recipientData).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    }
                );;


                if (updateResponse.status === 200) {

                    setisUpdating(false)
                    setisLoading(true)
                    const getUpdatedRecipients = await axios.get(
                        `/cohorts/${selectedCohortId}`).catch(
                            (error) => {
                                if (error.response.status === 403) {
                                    localStorage.removeItem('jwt')
                                    localStorage.removeItem('organisationId')
                                    history.push('/')
                                }

                                if (error.response.status === 401) {

                                    alert("Session has expired, please re-login.")
                                    localStorage.removeItem('jwt')
                                    localStorage.removeItem('organisationId')
                                    history.push('/')

                                }
                                // <ErrorCatch error={error} />
                            }
                        );


                    //maybe will need to replace with useDispatch


                    setRecipientDetails({
                        recipients: getUpdatedRecipients.data.recipients
                    })

                    setisLoading(false)
                }

            }
        }

        catch (err) {
            console.log(err)
            setisUpdating(false)
            setisLoading(false)

        }

    }




    const deleteCohort = async () => {
        try {


            if (selectedCohortId) {


                if (cancel.current !== undefined) {//uses .current property as this is how you manipulate the object in useRef
                    cancel.current(); // same as running cancel() because once it is defined it is assigned a function with new CancelToken (also has .current)

                }
                setisLoading(true)

                const response = await axios.delete(
                    `/cohorts/${selectedCohortId}`,
                    {
                        cancelToken: new CancelToken.current(c => {
                            cancel.current = c; // c is the identifier of the request, ON THE FIRST request it DEFINES the cancel variable, meaning
                        }),
                    }
                ).catch(

                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                    }
                );

                dispatch({
                    type: 'DELETE_COHORT',
                    payload: selectedCohort
                })

            }


        } catch (err) {
            console.log(err)

        }
    };










    const { recipients } = recipientDetails


    let cohort_name
    let cohort_desc
    let created_at
    if (selectedCohort) {

        cohort_name = selectedCohort.cohort_name
        cohort_desc = selectedCohort.cohort_desc
        created_at = selectedCohort.created_at
    }




    useEffect(() => {



        dispatch({
            type: 'SELECT_COHORT',
            payload: null
        })

    }, [dispatch, organisationId]);


    return (


        <div className='cohort-display-container'>
            <h1 className='cohort-display-header'>
                Cohorts
            </h1>
            {!selectedCohort?.id ? <div className='cohort-display-text'> No Cohort Selected </div>

                :

                <div className='cohort-display'>
                    <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                        <form onSubmit={(e) => updateRecipients(e)}>
                            <div className="upload-container">
                                <Button variant="secondary" className='secondary-button'><label htmlFor="upload-recipients">Select file</label></Button>
                                <input className="custom-file-input" accept='.csv' id="upload-recipients" type="file" name='file' onChange={(e) => {
                                    handleFile(e)

                                }} />{/* onClick={e => (e.target.value = null)} */}

                                <span className='file-text-container'>{csvTitle ? csvTitle : 'No file'}</span>


                                <Button variant="secondary" className='secondary-button' type="submit" >Upload</Button>
                            </div>

                        </form>
                    </div>
                    {/* ### RECIPIENT DETAILS TABLE  */}
                    <h2>{cohort_name}</h2>
                    <p>{cohort_desc}</p>
                    <p>{created_at}</p>

                    <div className='buttons-container-cohorts'>
                        <div className='single-button-container-cohorts'>
                            <FontAwesomeIcon icon={faEdit} className="edit-cohort-button" onClick={() => {
                                setShowModal(true);
                            }}
                            />
                            <FontAwesomeIcon icon={faTrash} className="remove-cohort-button"
                                onClick={() => {
                                    window.confirm("Are you sure you wish to delete this item?") &&
                                        deleteCohort()
                                }}
                            />
                            {/* </div>
                        <div className='single-button-container'> */}
                            {/* <FontAwesomeIcon icon={faTrash} className="remove-cohort-button" onClick={() => {
                            }} /> */}
                            {/* </div>
                        <div className='single-button-container'> */}
                            {/* <Button style={{backgroundColor: "#6c757d", borderColor:"#6c757d"}} >Add Members</Button> */}
                        </div>


                        {showModal ? <EditCohortModal
                            setShowModal={setShowModal}
                            showModal={showModal}
                        /> : null}

                    </div>


                    {/* TABLE AND CONDITIONAL MESSAGE/SPINNERS */}
                    {!isLoading ? (

                        !isUpdating ? (recipients.length === 0 ? <div className='no-recipients'>Cohort has no recipients</div> :

                            <ApiTable recipients={recipients} />) : <div className='spinner-container'><Spinner animation="border" />
                            <span>Uploading Recipients...</span></div>) :
                        (<div className='spinner-container'><Spinner animation="border" />
                            <span>Refreshing Recipients...</span></div>)
                    }



                    {/* <div className='buttons-container'>
                        <div className='single-button-container'>
                            <Button style={{backgroundColor: "#6c757d", borderColor:"#6c757d"}} onClick={() => {
                                setShowModal(true);
                            }} >Edit Cohort</Button>
                        </div>
                        <div className='single-button-container'>
                            <Button style={{backgroundColor: "#6c757d", borderColor:"#6c757d"}} >Add Members</Button>
                        </div>
                        <div className='single-button-container'>
                            <Button style={{backgroundColor: "#6c757d", borderColor:"#6c757d"}} >Delete Cohort</Button>
                        </div>

                        {showModal ? <EditCohortModal
                            setShowModal={setShowModal}
                            showModal={showModal}
                        /> : null}

                    </div> */}

                </div>
            }
        </div >
    );
};

export default CohortDisplay;




