import { updateCohortDetails, removeCohort } from './cohort.utils';

const INITIAL_STATE = {
	cohorts: [],
	selectedCohort: {
		id: null,
		cohort_name: '',
		cohort_desc: '',
		created_at:''
	},

};

const cohortReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_COHORTS':
			return {
				...state,
				cohorts: action.payload,
			};
		case 'SELECT_COHORT':
			return {
				...state,
				selectedCohort: action.payload,
			};
		case 'ADD_NEW_COHORT':
			return {
				...state,
				cohorts: [...state.cohorts, action.payload ],
			};
		case 'UPDATE_COHORT_DETAILS':
				return {
					...state,
					cohorts: updateCohortDetails(state.cohorts, action.payload),
					selectedCohort: action.payload
			};
			case 'DELETE_COHORT':
				return {
					...state,
					cohorts: removeCohort(state.cohorts, action.payload),
					selectedCohort: {
						id: null,
						cohort_name: '',
						cohort_desc: '',
						created_at:''
					}
				};
		default:
			return state;
	}
};

export default cohortReducer;
