import React from 'react';


import '../../ThemeSelectItem/theme-question-item.styles.scss';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import editIcon from '../../../../assets/editicon.svg'
import Accordion from 'react-bootstrap/Accordion';

import label from '../../../../assets/Frame.svg'

import ThemeItemName from '../../cohortItemName/themeitemname.component';


function dateFormat(date) {
    var setdate = new Date(date)

    return setdate.toLocaleDateString()
}


function ChildThemeSelectItem({ childTheme, setSubthemeModal, selected }) {

    const { theme_name, theme_desc, createdAt, sys_default } = childTheme



    const dispatch = useDispatch()


    const selectChildTheme = async () => {

        dispatch({
            type: 'SELECT_CHILD_THEME',
            payload: childTheme
        })
    }



    return (
        <div className="child-theme-selection-item child-theme-selection-item-small" onClick={selectChildTheme} style={{ backgroundColor: selected ? 'var(--accordion-background-selected)' : '' }}>

            <div className="child-theme-separation-container" >

                <div className="child-theme-separator-line" ></div>
            </div>
            <div className="overlap-group-regular-theme">
                <div className="theme-item-in-accordion-name-edit-button-container">
                    <span className="theme-item-in-accordion-name">
                        {theme_name}
                    </span>
                    {!sys_default ? <img src={editIcon} alt='edit_button' style={{ height: '1.3em', paddingLeft: '10px' }} onClick={(event) => {
                        setSubthemeModal(true);
                    }} /> : null}
                </div>
                <span className="theme-item-in-accordion-description">
                    {theme_desc}
                </span>

                {dateFormat(createdAt) === 'Invalid Date' ?
                    <div className='theme-created-date'></div>
                    :
                    <div className='theme-created-date'><span style={{ fontWeight: '500' }}>Created: </span>{dateFormat(createdAt)}</div>
                }

            </div>



        </div>)


}

export default ChildThemeSelectItem;

