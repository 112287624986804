import React from 'react';
import ReactDOM from 'react-dom';
import "@fontsource/source-sans-pro"
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import newStore from './redux/store';

import './globals.css';
import './styleguide.css';
import App from './App';

ReactDOM.render(
	<Provider store={newStore.store}>
		<BrowserRouter>
			<PersistGate persistor={newStore.persistor}>
				<App />
			</PersistGate>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
