import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTable } from 'react-table'
import { useHistory } from "react-router-dom";
import axios from "../../../misc/modifiedAxios";
import Button from 'react-bootstrap/Button';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import './question-group-table.styles.scss'
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const Styles = styled.div`
  padding: 1rem;

  
  table {
    width:98%;
    border-spacing: 0;
    // border: 1px solid black;
    }
   tr:nth-child(even){background-color: #f2f2f2;}
   tr:hover {background-color: #ddd;}

    th{
        border-bottom: 1px solid black;
    },

    td {
      margin: 0;
      padding: 0.5rem;
    //   border-bottom: 1px solid black;
    //   border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`


function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <table {...getTableProps()}>

            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                        <th></th>
                        <th></th>
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

function QuestionGroupTable({ questionGroups, setEditShowModal }) {

    const dispatch = useDispatch()

    const [question, setQuestion] = useState('')

    const [isLoading, setisLoading] = useState(false);
    const history = useHistory()


    const deleteQuestionGroup = async (e, history, dispatch, setisLoading) => {

        const questionGroupId = e.id

        try {
            let response

            setisLoading(true)

            response = await axios.delete(
                `/question-groups/${questionGroupId}`).catch(

                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })
                ;



            dispatch({
                type: 'DELETE_QUESTION_GROUP',
                payload: response.data
            })

            setisLoading(false)

        }
        catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }


    }


    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (question !== null) {
            const addNewQuestion = () => {
                dispatch({
                    type: 'SELECT_QUESTION_GROUP',
                    payload: question
                })
            }

            addNewQuestion();
        }

    }, [dispatch, question]);



    const columnsReady = React.useMemo(

        () => [

            {
                Header: 'Group Description',
                accessor: 'group_desc',
                Cell: ({ cell }) => {

                    return <div>{cell.row.original.group_desc}</div>
                }

            },

            {
                Header: 'Questions',
                accessor: 'questions',
                Cell: ({ cell }) => {

                    const questions = cell.row.original.questions

                    if (cell.row.original.questions) {
                        return (
                            <div className='questions-container'>
                                {
                                    questions.map((question) =>

                                        <div key={question.id}>{question.question_text}</div>) //{questions.indexOf(question) + 1}.

                                }
                            </div>
                        )
                    }
                    return null

                }
            },


            // {
            //     Header: 'Start Date',
            //     accessor: 'start_date',
            //     Cell: ({ cell }) => {

            //         const questions = cell.row.original.questions

            //         if (cell.row.original.questions) {
            //             return (
            //                 <div className='questions-container'>
            //                     {
            //                         questions.map((question) =>

            //                             <div key={question.id}>{question.start_date}</div>)

            //                     }
            //                 </div>
            //             )
            //         }
            //         return null

            //     },


            // },
            // {
            //     Header: 'End Date',
            //     accessor: 'end_date', //NEED to change to CELL so that switches likert_scale to Liker Scale returns jsx
            //     Cell: ({ cell }) => {

            //         const questions = cell.row.original.questions

            //         if (cell.row.original.questions) {
            //             return (
            //                 <div className='questions-container'>
            //                     {
            //                         questions.map((question) =>

            //                             <div key={question.id}>{question.end_date}</div>)

            //                     }
            //                 </div>
            //             )
            //         }

            //         return null

            //     },


            // },


            {
                Header: ' ',
                Cell: ({ cell }) => {



                    return <FontAwesomeIcon icon={faEdit} className="edit-cohort-button" onClick={() => {
                        setQuestion(cell.row.original)
                        setEditShowModal(true)
                    }}
                    />
                }
            },
            {
                Header: '  ',
                Cell: ({ cell }) => {

                    return <div className="create-theme-button-container" >
                        <FontAwesomeIcon icon={faTrash} className="remove-theme-button"
                            onClick={() => {
                                window.confirm("Are you sure you wish to delete this item?") &&
                                    deleteQuestionGroup(cell.row.original, history, dispatch, setisLoading)
                            }}
                        />
                    </div>
                }
            },
        ],
        [setEditShowModal]
    )

    return (


        isLoading ? (
            <div className='spinner'>
                <SpinnerCircularFixed
                    size={60}
                    thickness={140}
                    speed={120}
                    color='var(--spinner-color)'
                    secondaryColor='rgb(240, 240, 240)
                                            '
                />
                <div className='spinner-text'>
                    <span>Deleting Question Group...</span>
                </div>
            </div>
        ) : (
            <Styles>
                <Table
                    columns={columnsReady}
                    data={questionGroups}
                />
            </Styles>
        )
    )
}

export default QuestionGroupTable
