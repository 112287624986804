export const updateQuestionDetails = (questions, editedQuestion) => {




	const existingItem = questions.find(
		(question) => question.id === editedQuestion.id
	);


	if (existingItem) {
		return questions.map((question) =>
			question.id === editedQuestion.id
				?
				editedQuestion
				: question
		);
	}

	return [...questions]
};


export const removeQuestion = (questions, removedQuestion) => {
	const existingQuestion = questions.find(
		(question) => question.id === removedQuestion.id
	);

	return questions.filter((question) =>
		question.id !== existingQuestion.id
	);
};