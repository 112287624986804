import React from 'react'

export const ThemeDropDown = ({
    column
}) => {
    // Calculate the options for filtering
    // using the preFilteredRows

    const { filterValue, setFilter, preFilteredRows, id } = column


    //TRIED PASSING ID DIDNT WORK, need something else that filters

    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {


            if (row.original.parent_theme) {
                options.add(row.original.parent_theme)
            }
            if (!row.original.parent_theme && row.original.theme) {
                options.add(row.original.theme)
            }

        })
        return [...options.values()]
    }, [preFilteredRows])

    // const cleanOptions = options.map(item => {
    //     if (item !== null)
    //         return item
    // })


    // const unique = [...new Set(options.map(item => {
    //     if (item !== null) {
    //         return item.theme_name
    //     }
    // }))];




    // Render a multi-select box
    return (

        <select
            className='formControl'
            value={filterValue}
            onChange={e => {


                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (

                // value={JSON.stringify(option)}
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}


// export const ThemeDropDown = ({
//     column: { filterValue, setFilter, preFilteredRows, id },
// }) => {
//     // Calculate the options for filtering
//     // using the preFilteredRows
//     const options = React.useMemo(() => {
//         const options = new Set()
//         preFilteredRows.forEach(row => {
//             options.add(row.values[id])
//         })
//         return [...options.values()]
//     }, [id, preFilteredRows])

//     // Render a multi-select box

//     return (
//         <select
//             value={filterValue}
//             onChange={e => {
//                 setFilter(e.target.value || undefined)
//             }}
//         >
//             <option value="">All</option>
//             {options.map((option, i) => (
//                 <option key={i} value={option}>
//                     {option}
//                 </option>
//             ))}
//         </select>
//     )
// }

