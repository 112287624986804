import './App.css';
import { Switch, Route } from 'react-router-dom';
// import CohortItem from './components/CohortItem/cohortitem.component';
import Login from './pages/Login/Login'
import LoginV4 from './pages/V4 Login/LoginV4'
// import HomePage from './pages/homepage/hompage.component';
import Cohorts from './pages/Cohorts/cohorts.component';
import QuestionManager from './pages/QuestionManager/question-manager.component';
import QM from './pages/QM/qm.component';
import SurveyScheduleBuilder from './pages/SurveyScheduleBuilder/survey-schedule-builder.component';
import Form from './pages/Form/Form';
import Organisations from './pages/Organisations/Organisations.jsx'
import TestArea from './pages/testarea';

function App() {
	return (
		<div>
			{/* <input type='button'/> */}
			<Switch>
				{/* <InjectAxiosInterceptors /> */}

				<Route exact path='/' component={LoginV4} />

				<Route path='/cohorts' component={Cohorts} />
				<Route path='/question-manager' component={QM} />
				{/* <Route path='/question-manager' component={QuestionManager} /> */}
				<Route
					path='/survey-schedule-builder'
					component={SurveyScheduleBuilder}
				/>
				<Route path='/surveys/form/:id' component={Form} />
				<Route path='/organisations' component={Organisations} />
				<Route path='/password-reset' component={Organisations} />
				<Route path='/test-area' component={TestArea} />

			</Switch>
		</div>
	);
}

export default App;
