export const updateQuestionGroupDetails = (questionGroups, editedQuestionGroup) => {




	const existingItem = questionGroups.find(
		(question) => question.id === editedQuestionGroup.id
	);



	if (existingItem) {
		return questionGroups.map((questionGroup) =>
			questionGroup.id === editedQuestionGroup.id
				?
				editedQuestionGroup
				: questionGroup
		);
	}

	return [...questionGroups]
};


export const removeQuestionGroup = (questionGroups, removedQuestionGroup) => {
	const existingQuestionGroup = questionGroups.find(
		(questionGroup) => questionGroup.id === removedQuestionGroup.id
	);

	return questionGroups.filter((questionGroup) =>
		questionGroup.id !== existingQuestionGroup.id
	);
};

