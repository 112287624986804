import React, { useState, useEffect } from 'react';
// import './add-questions-modal.styles.scss'
import { Modal, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import { useSelector, useDispatch } from 'react-redux'
import { MultiSelect } from "react-multi-select-component";

import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import CreateSurveyQuestionModal from '../../CreateSurveyQuestions/create-survey-question-modal.component';
import SurveyQuestionTable from './survey-question-table.component'

const AddQuestionModal = ({ sectionId, masterData, setMasterData, showQuestionsModal, setShowQuestionsModal, setFirstInitiation }) => {

    const [showCreateModal, setCreateShowModal] = useState(false);

    return (

        <Modal

            show={showQuestionsModal}
            key={sectionId}

            fullscreen={true}

        >
            <Modal.Header >

                <Modal.Title>Add Questions</Modal.Title>
                <Button
                    variant='no-variant'
                    className='modal-cancel-button'
                    onClick={() => {
                        setShowQuestionsModal(false);
                    }}>
                    Close
                </Button>
            </Modal.Header>

            <Modal.Body >

                {/* COMMENTED OUT BUTTON FOR PROD UNFINISHED CREATE QUESTION LOGIC (need to add new question at the top of the questions list instead of last) */}
                {/* <Button
                    variant='no-variant'
                    className='modal-cancel-button'
                    onClick={() => {
                        setCreateShowModal(true);
                    }}>
                    Create New Question
                </Button> */}
                <CreateSurveyQuestionModal
                    setShowModal={setCreateShowModal}
                    showModal={showCreateModal}
                    setMasterData={setMasterData}
                    masterData={masterData}
                    setFirstInitiation={setFirstInitiation}
                />
                <SurveyQuestionTable
                    key={sectionId}
                    masterData={masterData}
                    setMasterData={setMasterData}
                    sectionId={sectionId}
                />


            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>

        </Modal>
    );
};

export default AddQuestionModal;
