import React, { useState } from "react";
import { useSelector } from "react-redux";

// import './section-component.styles.scss'

import AddInEditQuestionModal from "../AddInEditQuestionsModal/add-in-questions-modal-component";
import { Card } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import trashIcon from "../../../../assets/trashicon.svg";
import dndIcon from "../../../../assets/dndicon.svg";
import addIcon from "../../../../assets/addcirclebutton.svg";
import ViewSectionQuestion from "./ViewSectionQuestions/ViewSectionQuestion/view-section-question-component";

const ViewSection = ({ sectionId, masterData, setMasterData }) => {
  const organisationId = useSelector((state) => state.user.organisationId);

  const [showQuestionsModal, setShowQuestionsModal] = useState(false);

  //try this
  const currentSection = masterData?.sections?.find(
    (item) => item.sectionId === sectionId
  );

  //SECTIONS DND ____________________

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(currentSection.chosenQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // let updatedSectionsQuestions = { ...currentSection, chosenQuestions: items }
    let updatedSections = masterData?.sections?.map((x) => {
      if (x.sectionId === currentSection.sectionId) {
        return { ...x, chosenQuestions: items };
      }
      return x;
    });

    let newMasterData = { ...masterData, sections: updatedSections };

    setMasterData(newMasterData);
  }

  const handleRemoveSection = (e) => {
    let reducedSections = masterData.sections.filter((x) => x.sectionId !== e);

    setMasterData({ ...masterData, sections: reducedSections });
  };

  const handleSetName = (e) => {
    let updatedNameSections = masterData?.sections.map((x) => {
      if (x.sectionId === sectionId) {
        return {
          ...x,
          section_name: e.target.value.replace(/[^\x20-\x7E]+/g, ""),
        };
      }

      return x;
    });

    setMasterData({ ...masterData, sections: updatedNameSections });
  };

  const handleSetDescription = (e) => {
    let updatedDescriptionSections = masterData?.sections.map((x) => {
      if (x.sectionId === sectionId) {
        return {
          ...x,
          section_description: e.target.value.replace(/[^\x20-\x7E]+/g, ""),
        };
      }

      return x;
    });

    setMasterData({ ...masterData, sections: updatedDescriptionSections });
  };

  //SECTIONS DND END ___________________

  return (
    <div key={sectionId} className="section-component">
      <Card className="section-card">
        <Card.Body>
          <Container
            fluid
            style={{
              paddingBottom: "15px",
              marginBottom: "15px",
            }}
          >
            <Row>
              <Col>
                <span className="question">Section Name:</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  // required
                  defaultValue={
                    currentSection?.section_name
                      ? currentSection?.section_name
                      : ""
                  }
                  className="form-control section-inputs"
                  maxLength={organisationId === 5 ? "1000" : "250"}
                  placeholder={
                    organisationId === 5
                      ? "max 1000 characters"
                      : "max 250 characters"
                  }
                  // setName(e.target.value.replace(
                  //     /[^\x20-\x7E]+/g,
                  //     ''
                  // ))

                  onBlur={(e) => handleSetName(e)}
                />
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col>
                <span className="question">Section Description:</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <textarea
                  className="form-control section-inputs"
                  maxLength="1000"
                  placeholder="max 1000 characters"
                  // value={currentSection?.section_description ? currentSection?.section_description : ''}
                  defaultValue={
                    currentSection?.section_description
                      ? currentSection?.section_description
                      : ""
                  }
                  onBlur={(e) => handleSetDescription(e)}
                />
              </Col>
            </Row>
          </Container>

          {showQuestionsModal ? (
            <AddInEditQuestionModal
              key={sectionId}
              showQuestionsModal={showQuestionsModal}
              setShowQuestionsModal={setShowQuestionsModal}
              masterData={masterData}
              setMasterData={setMasterData}
              sectionId={sectionId}
            />
          ) : null}
          <Container fluid style={{ padding: "0.1em" }}>
            <Row>
              <Col>
                <div className="add-question-button-container">
                  <img
                    style={{ height: "1.5em" }}
                    src={addIcon}
                    alt="add_button"
                    onClick={(e) => {
                      setShowQuestionsModal(true);
                    }}
                  />
                  <span className="add-button-text">Add Questions</span>
                </div>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <div
                        className="characters"
                        style={{ marginLeft: "1em" }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {currentSection?.chosenQuestions?.length > 0 ? (
                          currentSection.chosenQuestions.map(
                            (element, index) => {
                              return (
                                <Draggable
                                  key={index.toString()}
                                  draggableId={index.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    // <div className="form-inline" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}  >

                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <div className="question-with-handle-container">
                                        <div className="question-drag-handle-container">
                                          <img
                                            className="grip-question-button"
                                            src={dndIcon}
                                            alt="dnd_button"
                                            {...provided.dragHandleProps}
                                          />
                                        </div>

                                        <ViewSectionQuestion
                                          key={element?.id}
                                          questionDetails={element}
                                          index={index}
                                          masterData={masterData}
                                          sectionId={sectionId}
                                          setMasterData={setMasterData}
                                          currentSection={currentSection}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          )
                        ) : (
                          <span className="no-questions-selected-message">
                            {null}
                          </span>
                        )}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <Container fluid style={{ padding: "0em" }}>
                  <div className="delete-question-button-container">
                    <img
                      style={{ height: "1.7em" }}
                      src={trashIcon}
                      alt="trash_button"
                      onClick={(e) => handleRemoveSection(sectionId)}
                    />
                    <span className="delete-button-text">Delete Section</span>
                  </div>
                </Container>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ViewSection;
