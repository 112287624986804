import React from 'react';

import './theme-question-item.styles.scss';

import label from '../../../assets/Frame.svg'

import axios from "../../../misc/modifiedAxios";
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'react-bootstrap';

function AllQuestionsThemes({ created_at }) {

    const history = useHistory();

    const selectedTheme = useSelector((state) => state.theme.selectedTheme);


    const dispatch = useDispatch()

    const selectTheme = (e) => {


        let type = e

        if (selectedTheme?.type === type) {
            return
        }



        console.log('type', type)

        dispatch({
            type: 'SELECT_THEME',
            payload: {
                id: type === 'all' ? -2 : -1,
                theme_desc: "",
                theme_name: type === 'all' ? "All Questions" : 'Unassigned',
                type: type,
                questions: []

            }
        })
        dispatch({
            type: 'SELECT_CHILD_THEME',
            payload: null
        })

    }

    return (
        <>
            <Accordion.Item eventKey='all' onClick={(e) => {
                selectTheme('all');
            }}>
                <Accordion.Header>
                    <div className="theme-item-in-accordion" >

                        <div className="theme-icon" style={{ paddingRight: '15px' }}>
                            <img style={{ height: '2.5em', width: '2.5em' }} src={label} />
                        </div>
                        <div className="overlap-group-regular-theme">
                            {/* <ThemeItemName theme_name={theme_name} /> */}
                            <span className="opensans-semi-bold-black-16px">All Questions
                            </span>


                        </div>

                    </div>
                </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey='allUndefined' onClick={(e) => {
                selectTheme('allUnassigned');
            }}>
                <Accordion.Header>
                    <div className="theme-item-in-accordion" >


                        <div className="theme-icon" style={{ paddingRight: '15px' }}>
                            <img style={{ height: '2.5em' }} src={label} />
                        </div>
                        <div className="overlap-group-regular-theme">
                            {/* <ThemeItemName theme_name={theme_name} /> */}
                            <span className="opensans-semi-bold-black-16px">
                                Unassigned
                            </span>


                        </div>

                    </div>
                </Accordion.Header>
            </Accordion.Item>
        </>
    );
}

export default AllQuestionsThemes;

