import { useState } from 'react';
// import axios from '../misc/modifiedAxios';
import axios from 'axios';

const TestArea = () => {


    const [files, setFiles] = useState()

    const uploadImage = async (e) => {
        e.preventDefault();

        const formData = new FormData()

        formData.append('files', files[0])
        formData.append('path', "1")

        axios.post("http://localhost:1337/api/upload", formData)
            .then((response) => {
                //after success
            }).catch((error) => {
                //handle error
            })
    }

    return (
        <form onSubmit={uploadImage}>
            <input
                type="file"
                onChange={(e) => setFiles(e.target.files)}
            />
            <input type="submit" value="Submit" />
        </form>
    );
};

export default TestArea;
