import React, { useState, useEffect } from 'react'
import axios from "../../../misc/modifiedAxios";
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import styled from 'styled-components'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useRowSelect } from 'react-table'
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './question-table.styles.scss';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/

import { ColumnFilter } from './column-filter.component';
import { DropdownFilter } from './q-type-dropdown-filter.component';
import { ThemeDropDown } from './theme.dropdown.component';
import { SubthemeDropdown } from './subtheme.dropdown.component';
import { ReverseDropdown } from './reverse-coded.dropdown.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faGripVertical, faEdit } from '@fortawesome/free-solid-svg-icons';
import FormCheck from 'react-bootstrap/FormCheck'

const Styles = styled.div`
padding: 1rem;

overflow-x: auto;
table {
  width:95%;
  border-spacing: 0;
  // border: 1px solid black;
  }
 tr:nth-child(even){background-color: #f2f2f2;}
 tr:hover {background-color: #ddd;}

  th{
      border-bottom: 1px solid black;
      width: 5em
  },

  td {
    margin: 0;
    padding: 0.5rem;
  //   border-bottom: 1px solid black;
  //   border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
`
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                {/* <input type="checkbox" ref={resolvedRef} {...rest} /> */}
                <label className="switch">
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                    <span className="slider round"></span>
                </label>
                {/* <FormCheck
                    type="switch"
                    id="custom-switch"
                    ref={resolvedRef} {...rest}
                // onChange={toggleSwitch}
                /> */}
                {/* <FormCheck
                    type="switch"
                    id="custom-switch"
                    label="Check this switch"
                    ref={resolvedRef} {...rest}
                /> */}
            </>
        )
    }
)

//FILTERS

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}




function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        selectedFlatRows,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,


        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 },
        defaultColumn, // Be sure to pass the defaultColumn option


    }, useFilters, useGlobalFilter, // useGlobalFilter!
        usePagination, useRowSelect,

        // hooks => {
        //     hooks.visibleColumns.push(columns => [
        //         // Let's make a column for selection
        //         {
        //             id: 'selection',
        //             // The header can use the table's getToggleAllRowsSelectedProps method
        //             // to render a checkbox
        //             Header: ({ getToggleAllRowsSelectedProps }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        //                 </div>
        //             ),
        //             // The cell can use the individual row's getToggleRowSelectedProps method
        //             // to the render a checkbox
        //             Cell: ({ row }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        //                 </div>
        //             ),
        //         },
        //         ...columns,
        //     ])
        // }


    )


    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()}>

                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {/* <br />

            <div className="pagination">
                <div className='pagination-buttons'>
                    <div className='button-container'>
                        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </Button>{' '}
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </Button>{' '}
                        <Button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </Button>{' '}
                        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </Button>{' '}
                    </div>
                    <div className='go-to-container'>
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <span>
                            <strong>| Go to page:{' '} </strong>
                        </span>
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                    </div>
                    <div className='dropdown-container'>
                        <select
                            value={pageSize}
                            className="form-select"
                            // style={{ width: '100px' }}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>

                </div>
            </div> */}
        </>
    )

}

const deleteQuestion = async (e, history, dispatch, setisLoading) => {

    const questionId = e.id

    try {
        let response

        setisLoading(true)

        response = await axios.delete(
            `/questions/${questionId}`).catch(

                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                })
            ;




        dispatch({
            type: 'DELETE_QUESTION',
            payload: response.data
        })

        setisLoading(false)

    }
    catch (err) {
        console.log(err)
        // err && setError('Internal error, please contact survey admin');
        window.alert('Internal error, please contact admin');
    }


}

function QuestionTable({ questions, setEditModal, setChosenQuestions }) {

    const dispatch = useDispatch()
    const history = useHistory()

    const [isLoading, setisLoading] = useState(false);
    const [question, setQuestion] = useState(null)

    let reformatedQuestion

    if (question)

        reformatedQuestion = questions.filter(x => {
            return x.id === question.id
        })



    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (question !== null) {
            const addNewQuestion = () => {
                dispatch({
                    type: 'SELECT_QUESTION',
                    payload: reformatedQuestion[0]
                })
            }

            addNewQuestion();
        }

    }, [dispatch, question, reformatedQuestion]);


    // useEffect(() => {

    //     // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

    //     if (selectedFlatRows.length > 0) {
    //         const addNewQuestion = () => {
    //             setChosenQuestions(selectedFlatRows)
    //         }

    //         addNewQuestion();
    //     }

    // }, [dispatch, setChosenQuestions, selectedFlatRows]);





    const columnsReady = React.useMemo(

        () => [


            {


                Header: 'Question',
                accessor: 'question_text',
                Filter: ColumnFilter,
                Cell: ({ cell }) => {
                    /* Add data-tip */
                    // return <span data-tip={row.value}>{row.value}</span>;

                    if (cell.row.original.question_type === 'likert_scale') {


                        return <>
                            <OverlayTrigger
                                key={cell.row.original.id}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={cell.row.original.id} >
                                        <div className="params">
                                            <span><strong >Min label:</strong>  {cell.row.original.likert_scale.min_label}</span>
                                            <span><strong >Min value:</strong>  {cell.row.original.likert_scale.min_value}</span>
                                            <span><strong >Max label:</strong> {cell.row.original.likert_scale.max_label}</span>
                                            <span><strong >Max value:</strong>  {cell.row.original.likert_scale.max_value}</span>
                                            <span><strong >Reverse Coded:</strong>  {cell.row.original.reverse_coding_flag ? 'Yes' : 'No'}</span>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span>{cell.row.original.question_text}</span>
                            </OverlayTrigger>
                        </>;
                    }

                    if (cell.row.original.question_type === 'mcq' || cell.row.original.question_type === 'checkbox' || cell.row.original.question_type === 'dropdown') {

                        const choices = cell.row.original.choices.map(choice => choice.value)


                        return <>
                            <OverlayTrigger
                                key={cell.row.original.id}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={cell.row.original.id} >
                                        <div className="params">
                                            <span><strong >Choices:</strong></span>
                                            {choices.join(", ")}
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span>{cell.row.original.question_text}</span>
                            </OverlayTrigger>
                        </>;
                    }

                    if (cell.row.original.question_type === 'short_answer' || cell.row.original.question_type === 'paragraph') {

                        return <span>{cell.row.original.question_text}</span>
                    }


                    if (cell.row.original.question_type === 'grid_mcq') {



                        const rows = cell.row.original.grid_choices.filter(i => i.axis === 'row').map(value => {
                            if (value.axis === 'row') {
                                return value.value
                            }

                            return null

                        })


                        const columns = cell.row.original.grid_choices.filter(i => i.axis === 'col').map(value => {
                            if (value.axis === 'col') {
                                return value.value
                            }

                            return null

                        })


                        return <>
                            <OverlayTrigger
                                key={cell.row.original.id}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={cell.row.original.id} >
                                        <div className="params">
                                            <span><strong >Rows: </strong>{rows.join(", ")}</span>
                                            <span><strong >Columns: </strong>  {columns.join(", ")}</span>
                                            <span><strong >Reverse Coded:</strong>  {cell.row.original.reverse_coding_flag ? 'Yes' : 'No'}</span>

                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span>{cell.row.original.question_text}</span>
                            </OverlayTrigger>
                        </>;
                    }


                    return <span>{cell.row.original.question_text}</span>

                }

            },
            {
                Header: 'Theme',
                // defaultCanFilter: true,
                // disableFilters: true,
                Filter: ThemeDropDown,
                accessor: 'parent_theme',
                filter: 'equals',
                Cell: ({ cell }) => {

                    //ORIGINAL
                    // if (cell.row.original.theme && cell.row.original.parent_theme) {
                    //     return cell.row.original.parent_theme.theme_name
                    // }
                    // if (cell.row.original.theme && !cell.row.original.parent_theme) {
                    //     return cell.row.original.theme.theme_name
                    // }

                    //JuST FLATTENED
                    // if (cell.row.original.theme && cell.row.original.parent_theme) {
                    //     return cell.row.original.parent_theme
                    // }
                    // if (cell.row.original.theme && !cell.row.original.parent_theme) {
                    //     return cell.row.original.theme
                    // }

                    //FLATTENED & ALWAYS parent

                    if (cell.row.original.parent_theme) {
                        return cell.row.original.parent_theme
                    }
                    // if (cell.row.original.theme && !cell.row.original.parent_theme) {
                    //     return cell.row.original.theme
                    // }
                    return ''


                }
            },
            {
                Header: 'Subtheme',
                Filter: SubthemeDropdown,
                // disableFilters: true,

                accessor: 'theme',
                Cell: ({ cell }) => {



                    // if (cell.row.original.theme && cell.row.original.parent_theme) {
                    //     return cell.row.original.theme.theme_name
                    // }
                    if (cell.row.original.theme && cell.row.original.parent_theme) {
                        return cell.row.original.theme
                    }
                    return ''


                }
            },
            {
                Header: 'Perspective',
                accessor: 'implicit_flag',
                Filter: ReverseDropdown,
                filter: 'equals',
                // disableFilters: true,
                Cell: ({ cell }) => {


                    if (cell.row.original.implicit_flag) {
                        return 'Subjective'
                    }
                    return 'Objective'

                }
            },


            {
                Header: 'Question Type',
                accessor: 'question_type', //NEED to change to CELL so that switches likert_scale to Liker Scale returns jsx
                // defaultCanFilter: true,
                Filter: DropdownFilter,
                filter: 'equals',
                Cell: ({ cell }) => {

                    if (cell.row.original.question_type === 'likert_scale') {
                        return 'Likert scale'
                    }

                    if (cell.row.original.question_type === 'mcq') {
                        return 'Multiple Choice'
                    }

                    if (cell.row.original.question_type === 'checkbox') {
                        return 'Checkbox'
                    }

                    if (cell.row.original.question_type === 'dropdown') {
                        return 'Dropdown'
                    }
                    if (cell.row.original.question_type === 'paragraph') {
                        return 'Paragraph'
                    }
                    if (cell.row.original.question_type === 'short_answer') {
                        return 'Short Answer'
                    }

                    if (cell.row.original.question_type === 'grid_mcq') {
                        return 'Grid Multiple Choice'
                    }

                    return null //to be replaced by other question type conditionals and null wont happen

                }
            },


            {
                Header: ' ',
                // defaultCanFilter: true,
                Filter: false,
                Cell: ({ cell }) => {



                    // return <Button value={cell.row.original} onClick={() => {
                    //     setQuestion(cell.row.original)
                    //     setEditModal(true)
                    // }}>
                    //     Edit Question
                    // </Button>

                    return (<div className="create-theme-button-container" >
                        <FontAwesomeIcon icon={faEdit} className="edit-theme-button" value={cell.row.original} onClick={() => {
                            setQuestion(cell.row.original)
                            setEditModal(true)
                        }}
                        />
                    </div>)
                }
            },
            {
                Header: '   ',
                // defaultCanFilter: true,
                Filter: false,
                Cell: ({ cell }) => {


                    return (<div className="create-theme-button-container" >
                        <FontAwesomeIcon icon={faTrash} className="remove-theme-button" value={cell.row.original} onClick={() => {
                            window.confirm("Are you sure you wish to delete this item?") &&
                                deleteQuestion(cell.row.original, history, dispatch, setisLoading)
                        }} />
                    </div>)


                }
            }

        ],
        [setEditModal]
    )
        ;

    // const data = React.useMemo(() => makeData(150), [])


    const formatedQuestions = questions.map(x => {

        if (x.theme && x.parent_theme) {
            return { ...x, parent_theme: x.parent_theme.theme_name, theme: x.theme.theme_name }
        }
        if (x.theme && !x.parent_theme) {
            return { ...x, parent_theme: x.theme.theme_name, theme: null }
        }
        return x

    })


    return (

        isLoading ? (
            <div className='spinner'>
                <SpinnerCircularFixed
                    size={60}
                    thickness={140}
                    speed={120}
                    color='var(--spinner-color)'
                    secondaryColor='rgb(240, 240, 240)
                                            '
                />
                <div className='spinner-text'>
                    <span>Deleting Question...</span>
                </div>
            </div>
        ) : (
            <Styles>
                <Table
                    columns={columnsReady}
                    data={formatedQuestions}

                />
            </Styles>
        )
    )
}

export default QuestionTable
