import React, { useState, useEffect, useRef } from 'react';
import axios, { cancelToken } from "../../../misc/modifiedAxios";
// import axios from 'axios'
import { Modal, Button, FormControl } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './edit-survey-modal.styles.scss'
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import dndIcon from '../../../assets/dndicon.svg'
import addIcon from '../../../assets/addcirclebutton.svg'

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
import { CSVLink } from "react-csv";
import "../../../_vars.scss"
import ViewSection from './ViewSection/view-section-component';
import Form from 'react-bootstrap/Form';






const EditSurveyModal = ({ showEditModal, setEditModal }) => {





    const CancelToken = useRef(cancelToken)
    const cancel = useRef(undefined)


    const [key, setKey] = useState('questions'); //Tab initial view
    const history = useHistory()
    const [isLoading, setisLoading] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(false);

    const selectedSurveyLight = useSelector((state) => state.survey.selectedSurvey);


    const organisationId = useSelector((state) => state.user.organisationId);
    const [chosenQuestions, setChosenQuestions] = useState([])
    const [requiredQuestions, setRequiredQuestions] = useState([])


    //MASTER DATA FOR SECTIONS______________________________

    const questions = useSelector((state) => state.question.questions);

    const [masterData, setMasterData] = useState({

        allAvailableQuestions: [],
        sections: []
    })
    const [uploadedFooterImageID, setUploadedFooterImageID] = useState(null)


    console.log('CURRENT MASTERDATA', masterData)

    function handleOnDragEnd(result) {

        if (!result.destination) return;

        const items = Array.from(masterData?.sections);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let newMasterData = { ...masterData, sections: items }

        // updateChoices(items)
        setMasterData(newMasterData)
    }


    const [selectedSurvey, setSelectedSurvey] = useState({

        survey_desc: '',
        survey_name: '',
        submit_confirm_message: '',
        start_date: '',
        end_date: '',
        status: '',
        sections: []

    });

    let survey_descRef = useRef()
    let survey_nameRef = useRef()
    let submit_confirm_messageRef = useRef()



    let selectedSurveyID = selectedSurveyLight?.id;

    useEffect(() => {

        const getSelectedSurvey = async () => {
            try {


                if (selectedSurveyID) {


                    if (cancel.current !== undefined) {//uses .current property as this is how you manipulate the object in useRef
                        cancel.current(); // same as running cancel() because once it is defined it is assigned a function with new CancelToken (also has .current)
                    }
                    setisLoading(true)

                    const response = await axios.get(
                        `/surveys/${selectedSurveyID}`,
                        {
                            cancelToken: new CancelToken.current(c => {
                                cancel.current = c; // c is the identifier of the request, ON THE FIRST request it DEFINES the cancel variable, meaning
                            }),
                        }
                    ).catch(
                        // (error) => {
                        // if (error.response.status === 403 || error.response.status === 401) {
                        //     localStorage.removeItem('jwt')
                        //     localStorage.removeItem('organisationId')
                        //     history.push('/')
                        // }
                        (error) => {
                            if (error.response.status === 403) {
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')
                            }

                            if (error.response.status === 401) {

                                alert("Session has expired, please re-login.")
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')

                            }
                        }
                    );

                    if (response) {

                        setSelectedSurvey(response.data)

                        setisLoading(false)
                    }

                }


            } catch (err) {
                console.log(err)

            }
        };

        getSelectedSurvey();
    }, [history, selectedSurveyID]);


    console.log('response: ', selectedSurvey)

    //________________________________________________


    const surveyStatusOptions = [
        {
            display: 'Live',
            value: 'live'
        },
        {
            display: 'Closed',
            value: 'closed'
        },
        {
            display: 'Draft',
            value: 'draft'
        }
    ]



    const surveyId = selectedSurvey?.id

    const [currentSurveyDetails, setCurrentSurveyDetails] = useState({

        survey_desc: '',
        survey_name: '',
        submit_confirm_message: '',
        start_date: '',
        end_date: '',
        status: ''

    })

    const { survey_desc, survey_name, start_date, end_date, status, submit_confirm_message } = currentSurveyDetails


    /// DATE FORMATTING
    let formatStartDate
    let formatEndDate


    if (start_date) {

        let startDate
        startDate = start_date
        formatStartDate = new Date(startDate)

    }

    if (end_date) {



        let endDate
        endDate = end_date

        formatEndDate = new Date(endDate)

    }




    //SET SURVEY DETAILS ON INITIAL RENDER OF THE SELECTED SURVEY
    useEffect(() => {

        const loadSurvey = () => {

            if (selectedSurvey !== null) {

                setCurrentSurveyDetails({

                    survey_desc: selectedSurvey.survey_desc, //possibly can remove these two
                    survey_name: selectedSurvey.survey_name,
                    submit_confirm_message: selectedSurvey.submit_confirm_message,
                    start_date: selectedSurvey.start_date,
                    end_date: selectedSurvey.end_date,
                    status: selectedSurvey.status
                    // questions: selectedSurvey.questions,

                })
                survey_descRef.current = selectedSurvey.survey_desc
                survey_nameRef.current = selectedSurvey.survey_name
                submit_confirm_messageRef.current = selectedSurvey.submit_confirm_message

            }

        }

        loadSurvey();

    }, [selectedSurvey]);


    const dispatch = useDispatch()
    const [updatedSurvey, setUpdatedSurvey] = useState(null)
    const [createdDuplicateSurvey, setCreatedDuplicateSurvey] = useState(null)


    const duplicate = async () => {


        let convertedStartDate
        if (typeof currentSurveyDetails.start_date === 'object') {
            convertedStartDate = currentSurveyDetails.start_date.toISOString()
        }

        let convertedEndDate
        if (typeof currentSurveyDetails.end_date === 'object') {
            convertedEndDate = currentSurveyDetails.end_date.toISOString()
        }

        let duplicateName
        if (currentSurveyDetails.survey_name === selectedSurvey.survey_name) {
            // duplicateName = '[DUPLICATE] ' + selectedSurvey.survey_name
            duplicateName = '[DUPLICATE] ' + survey_nameRef.current.value.replace(/[^\x20-\x7E\n]+/g, '')
        }
        if (currentSurveyDetails.survey_name !== selectedSurvey.survey_name) {
            // duplicateName = currentSurveyDetails.survey_name
            duplicateName = survey_nameRef.current.value.replace(/[^\x20-\x7E\n]+/g, '')
        }


        try {
            setIsDuplicate(true)
            setisLoading(true)




            const duplicateSurvey = {

                // IF Survey status is Live, the chosen question's should be locked from edditing and the IDs are attached in this list:
                questionsList: currentSurveyDetails.status === 'live' ?

                    masterData.sections.map(section => {

                        return section.chosenQuestions.map(question => {
                            return question.id
                        })
                    }).flat()

                    : [],


                data: {
                    og: organisationId,
                    survey_desc: survey_descRef.current.value.replace(/[^\x20-\x7E\n]+/g, ''),
                    survey_name: duplicateName,
                    submit_confirm_message: submit_confirm_messageRef.current.value.replace(/[^\x20-\x7E\n]+/g, ''),
                    footer_image: uploadedFooterImageID,
                    sections: masterData.sections.map(section => {

                        return {
                            order: masterData.sections.indexOf(section),
                            section_name: section?.section_name,
                            section_desc: section?.section_description.replace(
                                /[^\x20-\x7E\n]+/g,///[^\x20-\x7E]+/g,
                                ''
                            ),
                            section_questions: section.chosenQuestions.map(question => {
                                return {

                                    order: section.chosenQuestions.indexOf(question) + 1,
                                    question: question.id,
                                    required_flag: question?.required === true ? true : false
                                }
                            })
                        }

                    }),
                    start_date: convertedStartDate ? convertedStartDate : currentSurveyDetails.start_date,
                    end_date: convertedEndDate ? convertedEndDate : currentSurveyDetails.end_date,
                    status: currentSurveyDetails.status,
                }
            };

            const response = await axios.post(
                `/surveys/`,
                duplicateSurvey
            ).catch(
                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                })


            const { id, survey_desc, survey_name, questions, start_date, end_date, url, status, section, footer_image, submit_confirm_message } = response.data

            setCreatedDuplicateSurvey({
                id,
                survey_desc,
                survey_name,
                submit_confirm_message,
                start_date,
                end_date,
                status,
                url,
                questions,
                section,
                footer_image,
            })

            setisLoading(false)
            setIsDuplicate(false)
            setChosenQuestions([])
            setRequiredQuestions([])
            setMasterData({

                allAvailableQuestions: [],
                sections: []
            })
            setEditModal(false);

        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }

    }


    const submit = async (e) => {
        e.preventDefault();


        // STACKOVERFLOW: https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset Dustin Silk, 2016

        // const endDate = new Date(currentSurveyDetails.end_date.getTime() - (currentSurveyDetails.end_date.getTimezoneOffset() * 60000)).toISOString()
        // const startDate = new Date(currentSurveyDetails.start_date.getTime() - (currentSurveyDetails.start_date.getTimezoneOffset() * 60000)).toISOString()
        let convertedStartDate
        if (typeof currentSurveyDetails.start_date === 'object') {
            convertedStartDate = currentSurveyDetails.start_date.toISOString()
        }

        let convertedEndDate
        if (typeof currentSurveyDetails.end_date === 'object') {
            convertedEndDate = currentSurveyDetails.end_date.toISOString()
        }


        try {

            setIsDuplicate(false)
            setisLoading(true)

            // ----------------------------------- CHANGING THE LOCKED QUESTION LOGIC ALL SCENARIOS BASED ON SURVEY STATUS CHANGES -----------------------
            let questionsList
            // if past status is live
            if (selectedSurvey.status === 'live') {
                // if new status is live
                if (currentSurveyDetails.status === 'live') {
                    questionsList = []
                    // if new status is NOT live  
                } else {
                    questionsList = masterData.sections.map(section => {

                        return section.chosenQuestions.map(question => {
                            return question.id
                        })
                    }).flat()
                }
            }

            // if past status is live
            if (selectedSurvey.status !== 'live') {
                // if new status is live
                if (currentSurveyDetails.status === 'live') {
                    questionsList = masterData.sections.map(section => {

                        return section.chosenQuestions.map(question => {
                            return question.id
                        })
                    }).flat()
                    // if new status is NOT live  
                } else {
                    questionsList = []
                }
            }

            // ------------------------------------------------------------------------------------------

            const editedSurvey = {

                questionsList: questionsList,

                data: {
                    // survey_desc: currentSurveyDetails.survey_desc.replace(
                    //     /[^\x20-\x7E\n]+/g,///[^\x20-\x7E]+/g,
                    //     ''
                    // ),
                    survey_desc: survey_descRef.current.value.replace(/[^\x20-\x7E\n]+/g, ''),
                    survey_name: survey_nameRef.current.value.replace(/[^\x20-\x7E\n]+/g, ''),//currentSurveyDetails.survey_name,
                    submit_confirm_message: submit_confirm_messageRef.current.value.replace(/[^\x20-\x7E\n]+/g, ''),
                    footer_image: uploadedFooterImageID,
                    status: currentSurveyDetails.status,
                    start_date: convertedStartDate,
                    end_date: convertedEndDate,
                    sections: masterData.sections.map(section => {

                        return {
                            order: masterData.sections.indexOf(section),
                            section_name: section?.section_name,
                            section_desc: section?.section_description.replace(
                                /[^\x20-\x7E\n]+/g,///[^\x20-\x7E]+/g,
                                ''
                            ),
                            section_questions: section.chosenQuestions.map(question => {
                                return {
                                    component_id: question.component_id,

                                    order: section.chosenQuestions.indexOf(question) + 1,
                                    question: question.id,
                                    required_flag: question?.required === true ? true : false
                                }
                            })



                        }

                    })

                }

            }



            const response = await axios.put(
                `/surveys/${surveyId}`,
                editedSurvey
            ).catch(
                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }

                })


            const { id, survey_desc, survey_name, questions, start_date, end_date, url, organisation, status, section, footer_image, submit_confirm_message } = response.data

            setUpdatedSurvey({
                id,
                survey_desc,
                survey_name,
                submit_confirm_message,
                start_date,
                end_date,
                status,
                questions,
                url,
                organisation,
                section,
                footer_image,
            })


            setisLoading(false)
            setChosenQuestions([])
            setRequiredQuestions([])
            setMasterData({

                allAvailableQuestions: [],
                sections: []
            })
            setEditModal(false);


        } catch (err) {
            console.log(err)

        }
    };
    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (updatedSurvey !== null) {
            const setUpdatedSurvey = () => {
                dispatch({
                    type: 'UPDATE_SURVEY_DETAILS',
                    payload: updatedSurvey
                })
            }

            setUpdatedSurvey();
        }

    }, [dispatch, updatedSurvey]);


    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (createdDuplicateSurvey !== null) {
            const setCreatedSurvey = () => {
                dispatch({
                    type: 'ADD_NEW_SURVEY',
                    payload: createdDuplicateSurvey
                })
            }

            setCreatedSurvey();
        }

    }, [dispatch, createdDuplicateSurvey]);


    useEffect(() => {


        if (selectedSurvey?.questions) {


            // })


            const filtered = selectedSurvey?.questions.filter(x => {
                if (x.required_flag === true) {
                    return x.question.id
                }
            })

            const initialRequiredQuestions = filtered.map(x => x.question.id)

            setChosenQuestions(selectedSurvey.questions)
            setRequiredQuestions(initialRequiredQuestions)
        }


    }, [selectedSurvey]);


    //_____________________________________ CSV RESPONSE _____________________________________//


    const [csv, setCSV] = useState([]);

    const getCSVRespones = () => {

        try {
            // let response


            // setIsDeleting(true)

            return (axios.get(
                `/surveys/responses/${surveyId}`)
                .catch(

                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }


                    }))
                ;
        }
        catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }

    }



    const csvLinkEl = React.createRef();


    const downloadReport = async () => {
        const data = await getCSVRespones();

        setCSV(data.data.responses)


    }

    useEffect(() => {

        if (csv.length > 0) {
            csvLinkEl.current.link.click();
        }

    }, [csv])




    // ________________________________CSV RESPONSES END _____________________________//




    //INITIALISE AVAILABLE QUESTIONS FOR MASTEDATA SECTIONS


    useEffect(() => {



        setMasterData({
            allAvailableQuestions: questions,//formatedQuestions,
            // Below is Formatting from BE to fit Components
            sections: selectedSurvey?.sections?.map(x => {


                return ({
                    sectionId: selectedSurvey?.sections.indexOf(x) + 1,
                    chosenQuestions: x.section_questions.map(y => {

                        return ({
                            ...y,
                            required: y.required_flag,
                            theme: y.theme,
                            parent_theme: y.parent_theme,
                        })
                    }),
                    section_description: x.section_desc,
                    section_name: x.section_name

                })

            })
        })



    }, [questions, showEditModal, selectedSurvey]);
    //_____________________________________________________


    const uploadImage = async (e) => {
        e.preventDefault();


        try {
            const formData = new FormData()

            formData.append('files', e.target.files[0])
            formData.append('path', "1")

            const response = await axios({
                method: 'post',
                url: '/upload',
                data: formData
            })

            setUploadedFooterImageID(response.data[0]?.id)

        } catch (err) {
            console.log(err)
        }
    }


    // const handleSetDescription = (e) => {

    //     setCurrentSurveyDetails({
    //         ...currentSurveyDetails, survey_desc: e.target.value
    //     })

    // }

    return (

        <Modal
            show={showEditModal}
            dialogClassName="main-modal"
            // size="xl"
            backdrop="static"
            onHide={() => {
                setChosenQuestions([])
                setRequiredQuestions([])
                setEditModal(false);

            }}>
            <Modal.Header style={{ backgroundColor: '#F4F4F4' }} closeButton>
                <Modal.Title>View</Modal.Title>
                {/* <Button
                    variant='no-variant'
                    className='modal-cancel-button'
                    onClick={() => {
                        setEditModal(false);
                    }}>
                    Close
                </Button> */}
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body style={{ backgroundColor: '#F4F4F4' }}>

                    {isLoading ? (
                        <div className='spinner'>
                            <SpinnerCircularFixed
                                size={60}
                                thickness={140}
                                speed={120}
                                color='var(--spinner-color)'
                                secondaryColor='rgb(240, 240, 240)
                                            '
                            />
                            <div className='spinner-text'>
                                {isDuplicate ? <span>Creating Survey...</span> : <span>Updating Survey...</span>}
                            </div>
                        </div>
                    ) : (
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3">
                            <Tab eventKey="questions" title="Questions">
                                <Container
                                    fluid

                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}>
                                    <Row>
                                        <Col>
                                            <span className='question'>Name *</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <input
                                                required
                                                // value={survey_name ? survey_name : ''}
                                                defaultValue={survey_name}
                                                className='form-control section-inputs'
                                                maxLength='250'
                                                placeholder='max 250 characters'
                                                // onChange={(e) => {

                                                //     setCurrentSurveyDetails({
                                                //         ...currentSurveyDetails, survey_name: e.target.value.replace(
                                                //             /[^\x20-\x7E]+/g,
                                                //             ''
                                                //         )
                                                //     })
                                                //     // }
                                                // }}
                                                ref={survey_nameRef}
                                            />
                                        </Col>
                                    </Row>
                                </Container>

                                <Container
                                    fluid

                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}>

                                    <Row>
                                        <Col>
                                            <span className='question'>Description *</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <textarea
                                                required
                                                defaultValue={survey_desc}
                                                maxLength='1000'
                                                placeholder='max 1000 characters'
                                                className='form-control section-inputs'

                                                // onBlur={(e) => {

                                                //     handleSetDescription(e)
                                                //     // }
                                                // }}
                                                ref={survey_descRef}
                                            />
                                        </Col>
                                    </Row>
                                </Container>

                                <DragDropContext onDragEnd={handleOnDragEnd} >
                                    <Droppable droppableId="sections" >
                                        {(provided) => (
                                            <div className="sections" style={{ marginLeft: '1em' }} {...provided.droppableProps} ref={provided.innerRef} >
                                                {masterData?.sections?.map((element, index) => {



                                                    return (


                                                        <Draggable key={index.toString()} draggableId={index.toString()} index={index} >
                                                            {(provided) => (
                                                                // <div className="form-inline" ref={provided.innerRef} {...provided.draggableProps}  >

                                                                <div ref={provided.innerRef} {...provided.draggableProps}  >
                                                                    <div className='section-container'>
                                                                        <div {...provided.dragHandleProps}>
                                                                            <img className="grip-section-button" src={dndIcon} alt='dnd_button' />
                                                                        </div>

                                                                        <ViewSection
                                                                            key={element.sectionId}
                                                                            sectionId={element.sectionId}
                                                                            masterData={masterData}
                                                                            setMasterData={setMasterData}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            )}
                                                        </Draggable>

                                                    )
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}

                                    </Droppable>
                                </DragDropContext>



                                <Container
                                    fluid
                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    <div className="add-question-button-container" >
                                        <img style={{ height: '1.5em' }} src={addIcon} alt='add_button' onClick={() => {
                                            setMasterData(

                                                {
                                                    ...masterData,
                                                    sections: [...masterData?.sections, {
                                                        sectionId: masterData?.sections.length + 1,
                                                        section_name: '',
                                                        section_description: '',
                                                        chosenQuestions: [],
                                                        requiredQuestions: []
                                                    }]
                                                }

                                            )
                                        }} />

                                        <span className="add-button-text">Add Section</span>
                                    </div>
                                </Container>

                            </Tab>


                            <Tab eventKey="appearance" title="Appearance">

                                <Container
                                    fluid

                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}>

                                    <Row>
                                        <Col>
                                            <span className='question'>Submit Confirm Message:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <textarea
                                                // required
                                                defaultValue={submit_confirm_message}
                                                maxLength='1000'
                                                placeholder='max 1000 characters'
                                                className='form-control section-inputs'

                                                // onBlur={(e) => {

                                                //     handleSetDescription(e)
                                                //     // }
                                                // }}
                                                ref={submit_confirm_messageRef}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                                <Container
                                    fluid

                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}>

                                    <Row>
                                        <Col>
                                            <span className='question'>Icons:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <form >
                                                <input
                                                    type="file"
                                                    onChange={(e) => uploadImage(e)}
                                                />
                                            </form>
                                        </Col>
                                    </Row>
                                </Container>


                            </Tab>

                            <Tab eventKey="settings" title="Settings">
                                <Container
                                    fluid

                                >

                                    {/* <Row>
                                        <Col>
                                            <span className='question'>Start Date:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DatePicker
                                                selected={formatStartDate}
                                                timeInputLabel="Time:"
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                showTimeInput
                                                onChange={(date) => setCurrentSurveyDetails({
                                                    ...currentSurveyDetails, start_date: date
                                                })} />
                                        </Col>
                                    </Row>
                                </Container>
                                <Container
                                    fluid
                                >
                                    <Row>
                                        <Col>
                                            <span className='question'>End Date:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DatePicker
                                                selected={formatEndDate}
                                                timeInputLabel="Time:"
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                showTimeInput
                                                onChange={(date) => setCurrentSurveyDetails({
                                                    ...currentSurveyDetails, end_date: date
                                                }
                                                )}
                                            />
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col style={{ marginTop: '2em' }}>
                                            <span className='question'>Survey Status:</span>

                                            <select
                                                value={status}
                                                className="form-control survey-status-dropdown "
                                                onChange={(e) => setCurrentSurveyDetails({
                                                    ...currentSurveyDetails, status: e.target.value
                                                })}


                                            >
                                                {surveyStatusOptions.map(x => (
                                                    <option key={surveyStatusOptions.indexOf(x)} value={x.value}>
                                                        {x.display}
                                                    </option>
                                                ))}
                                            </select>


                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginTop: '2em' }}>
                                            <div style={{ display: 'flex' }}>
                                                <span className='question'>Survey Responses:</span>
                                                <span className='question'>Response Count: {selectedSurvey?.response_count}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >

                                            {/* REFACTOR FROM CLASS COMPONENT */}

                                            <div>
                                                {/* <input type="button" value="Export to CSV (Async)" onClick={downloadReport} /> */}
                                                <Button className='card-button'
                                                    variant='no-variant'
                                                    disabled={selectedSurvey?.response_count > 0 ? false : true}
                                                    onClick={downloadReport}> Download</Button>

                                                <CSVLink
                                                    // headers={headers}
                                                    filename={`${survey_name} Responses`}
                                                    data={csv}
                                                    ref={csvLinkEl}

                                                />


                                            </div>

                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>

                        </Tabs>)}

                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between', backgroundColor: '#F4F4F4' }}>

                    <Button
                        className="clear-button clear-button--secondary"
                        variant='no-variant'
                        onClick={() => {
                            duplicate();
                        }}
                    >
                        <span>Duplicate Survey</span>
                    </Button>
                    <div className='edit-survey-save-cancel-button-container'>

                        <div style={{ marginRight: '0.5em' }}
                            className="clear-button clear-button--secondary"
                            onClick={() => {

                                setChosenQuestions([])
                                setRequiredQuestions([])
                                // dispatch({
                                //     type: 'SELECT_SURVEY',
                                //     payload: {}
                                // })
                                setMasterData({

                                    allAvailableQuestions: [],
                                    sections: []
                                })
                                setEditModal(false);
                                setIsDuplicate(false)

                            }} >
                            <span>Cancel</span>
                        </div>
                        <input
                            type='submit'
                            value='Save Changes'
                            className='card-button'
                        />
                    </div>
                </Modal.Footer>
            </form >
        </Modal >
    );
};

export default EditSurveyModal;
