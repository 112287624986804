import React from 'react';
import './cohortitem.styles.scss';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch } from 'react-redux';


import CohortItemName from '../cohortItemName/cohortitemname.component';

function CohortItem({ id, cohort_name, created_at, cohort_desc }) {

    // onClick dispatch the passed in id as currently selected cohort

    const dispatch = useDispatch()

    const selectCohort = () => {
        dispatch({
            type: 'SELECT_COHORT',
            payload: { id, cohort_name, created_at, cohort_desc }
        })
    }


    return (
        <div className="cohort-item" onClick={selectCohort}>
            <div className="separation">
                <FontAwesomeIcon icon={faUsersCog} className="user-group-icon" />
                <div className="separator-line"></div>
            </div>
            <div className="overlap-group">
                <CohortItemName cohort_name={cohort_name}/>
                <div className="created opensans-normal-black-14px">Created: {created_at}</div>
            </div>
        </div>
    );
}

export default CohortItem;

