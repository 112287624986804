export const updateOrganisation = (organisations, editedOrganisation) => {




	const existingItem = organisations.find(
		(organisation) => organisation.id === editedOrganisation.id
	);



	if (existingItem) {
		return organisations.map((organisation) =>
			organisation.id === editedOrganisation.id
				?
				editedOrganisation
				: organisation
		);
	}

	return [...organisations]
};



export const removeOrganisation = (organisations, removedOrganisation) => {
	const existingOrganisation = organisations.find(
		(organisation) => organisation.id === removedOrganisation.id
	);

	return organisations.filter((organisation) =>
		organisation.id !== existingOrganisation.id
	);
};