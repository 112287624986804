import React, { useEffect } from 'react';
import './survey-schedule-builder.styles.scss';
import { useHistory } from "react-router-dom";
import SideNavBar from '../../components/Shared/SideNavBar/sidenavbar.component';
// import TabScheduleSurveySwitch from '../SurveyScheduleBuilder/tab-switch-survey-schedule.component'
import SurveyDisplay from '../../components/Survey-Components/SurveyDisplay/survey-display.component'


const SurveyScheduleBuilder = () => {

    const history = useHistory();



    useEffect(() => {

        const jwt = localStorage.getItem('jwt')
        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (!jwt) {
            history.push("/")
        }


    }, []);


    return (
        <div className='survey-schedule-builder'>


            <SideNavBar />
            <div className='survey-view'>
                {/* <TabScheduleSurveySwitch /> */}
                <SurveyDisplay />
            </div>

        </div>
    );
};

export default SurveyScheduleBuilder;