import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

const GridLikertQuestion = ({
	id,
	text,
	question_type,
	grid_likert_scale,
	setAnswers,
	answers,
	questionNumber,
	required_flag,
	onMultipleInputChanged
}) => {

	const { min_label, max_label, rows } = grid_likert_scale

	const radioName = uuidv4()

	const [chosenValues, setChosenValues] = useState([]);

	const handleAddValue = (e) => {

		// let inputRow = e.target.name.split(' ')[0] //Old version of only getting first word of row

		// This way cuts off the uuid, and just saves the full string with all the words:
		let str = e.target.name;
		let lastIndex = str.lastIndexOf(" ");
		let inputRow = str.substring(0, lastIndex);



		let foundExisting = false;

		const updatedChosenValues = chosenValues.map((x) => {
			if (inputRow === x.row) {
				x.col = e.target.value;
				foundExisting = true;

				return x;
			} else {
				return x;
			}
		});

		if (!foundExisting) {
			updatedChosenValues.push({
				row: inputRow,
				col: e.target.value,
			});
		}


		setChosenValues([...updatedChosenValues]);

	};

	useEffect(() => {
		if (chosenValues.length > 0) {
			const UpdateParentAnswer = () => {
				let response;

				response = {
					...answers,

					[`Question_${parseInt(id)}`]: {
						questionID: parseInt(id),
						questionType: `${question_type}`,
						response: chosenValues,
					},
				};

				setAnswers(response);


				if (required_flag) {
					onMultipleInputChanged(id, chosenValues, question_type)
				}


			};
			UpdateParentAnswer();
		}
	}, [chosenValues]);



	return (
		<Container
			fluid
			className='forms-box'
		>
			{required_flag ? <Row>
				<Col>
					<div className='question-title'>
						<span className='question'>{questionNumber}. {text} </span>
						<span style={{ color: 'red', fontSize: '1.5em' }}>*</span>
					</div>
				</Col>
			</Row> :
				<Row>
					<Col>
						<div className='question-title'>
							<span className='question'>{questionNumber}. {text}</span>
						</div>
					</Col>
				</Row>
			}

			<Row className='mcq-grid-all-inputs-container'>


				<Col>




					{/* JUST THE  FIRST ROW WITH COLUMN HEADERS (could rework instead of loop)*/}
					{rows.map((label) => {

						if (label.order === 0) {

							return (
								<>
									{/* <div className='label-max-min-grid-likert-container'>
										<span>{max_label}</span>
										<span>{min_label}</span>
									</div> */}
									<div key={label.id} className='mcq-grid-container' >
										<span className='linkert-label-left-mcq-grid' style={{ opacity: '0', height: '0', overflow: 'hidden' }}>
											{label.value}
										</span>
										<div className='label-max-min-grid-likert-container'>
											<span className='grid-likert-min-label'>{min_label}</span>
											<span className='grid-likert-max-label'>{max_label}</span>
										</div>
									</div>

									<div key={label.id} className='mcq-grid-container' >

										<span className='linkert-label-left-mcq-grid' style={{ opacity: '0', height: '0', overflow: 'hidden' }}>
											{label.value}
										</span>

										{label.cols.map((x) => {
											return (
												<div key={x.id} className='grid-likert-col-label-and-radio-input-same-width'>
													{label.order === 0
														? <div className='mcq-grid-col-headers'>{x.value}</div>
														: <div className='mcq-grid-col-headers' style={{ opacity: '0', height: '0', overflow: 'hidden' }}>{x.value}</div>}

												</div>
											);
										})}
									</div>
								</>
							);
						}

					})}

					{/* INPUT RADIO BUTTONS AND LEFT SIDE LABEL LOOP ALL ROWS*/}

					{rows.map((label) => {
						return (


							<div key={label.id} className='mcq-grid-container'>
								<span className='linkert-label-left-mcq-grid'>
									{label.value}
								</span>


								{label.cols.map((x) => {
									return (
										<div key={x.id} className='grid-likert-col-label-and-radio-input-same-width'>
											<div className='mcq-radio-container'>

												{required_flag ?
													<input
														required
														onChange={handleAddValue}
														type='radio'
														value={x.value}
														name={label.value + ' ' + radioName}
														className='linkert-radios'
													/>

													:

													<input
														onChange={handleAddValue}
														type='radio'
														value={x.value}
														name={label.value + ' ' + radioName}
														className='linkert-radios'
													/>

												}
											</div>
										</div>
									);
								})}
							</div>

						);
					})}

				</Col>
			</Row>
		</Container >
	);
};

export default GridLikertQuestion;