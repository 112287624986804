import React, { useState } from "react";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import OrganisationTable from './organisation-table.component'
// import OrganisationRegisterForm from './organisation-form.component.jsx'
import OrganisationDisplay from "./OrganisationDisplay/organisation-display.component";


const TabOrganisationSwitch = () => {
    //set state from url hash
    let history = useHistory();

    const { pathname } = history.location

    const [selectedTab, setSelectedTab] = useState(pathname.substring(pathname.lastIndexOf('/') + 1));
    //substring subtracts the pathnames last ending with slash "/"

    const user = useSelector((state) => state.user.user);
    const { organisations } = user

    const handleSelect = (e) => {

        //e is the eventKey in this case either question or theme string


        //set state
        setSelectedTab(e);
        //update url
        history.replace({ //need it for the pathname to stay in address
            pathname: `${e}`
        });
    }

    return (
        //set active key, if no url hash set default to home
        <Tabs
            activeKey={selectedTab ? selectedTab : 'question'}
            onSelect={(e) => handleSelect(e)}
        >
            <Tab eventKey="register" title="Organisations">
                {selectedTab === "register" ? <OrganisationDisplay /> : null}
            </Tab>
        </Tabs>
    );
}
export default TabOrganisationSwitch;