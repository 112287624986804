import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import clientReducer from './clients/client.reducer';
import userReducer from './users/user.reducer'
import cohortReducer from './cohorts/cohorts.reducer';
import themeReducer from './themes/theme.reducer'
import questionReducer from './questions/question.reducer'
import questionGroupReducer from './question-groups/question-group.reducer';
import surveyReducer from './surveys/survey.reducer'
import scheduleReducer from './schedules/schedule.reducer'


const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['survey', 'user']
}

const rootReducer = combineReducers({
	client: clientReducer,
	user: userReducer,
	cohort: cohortReducer,
	theme: themeReducer,
	question: questionReducer,
	questionGroup: questionGroupReducer,
	survey: surveyReducer,
	// schedule: scheduleReducer
});

export default persistReducer(persistConfig, rootReducer);
