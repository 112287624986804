import React, { useState, useEffect } from 'react';
import axios from "../../../misc/modifiedAxios";
import { Modal, Button } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux'

import { useHistory } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
import "../../../_vars.scss"

const EditOrganisationModal = ({ showEditModal, setEditModal }) => {


    const history = useHistory()

    const selectedOrganisation = useSelector((state) => state.user.selectedOrg);
    const [isLoading, setisLoading] = useState(false);


    // const questionGroupId = selectedOrganisation.id

    const [currentQuestionGroupDetails, setCurrentQuestionGroupDetails] = useState({

        org_desc: '',
        org_name: '',

    })

    const [initialName, setInitialName] = useState('')
    const [initialDesc, setInitialDesc] = useState('')


    const { org_desc, org_name } = currentQuestionGroupDetails

    useEffect(() => {


        const loadOrganisation = () => {

            if (selectedOrganisation !== null) {

                setCurrentQuestionGroupDetails({

                    org_desc: selectedOrganisation?.org_desc,
                    org_name: selectedOrganisation?.org_name,

                })

                setInitialName(selectedOrganisation?.org_name)
                setInitialDesc(selectedOrganisation?.org_desc)

            }

        }

        loadOrganisation();

    }, [selectedOrganisation]);


    const dispatch = useDispatch()
    const [updatedOrganisation, setUpdatedOrganisation] = useState(null)


    const submit = async (e) => {
        e.preventDefault();




        try {
            setisLoading(true)
            // const editedOrganisation = {
            //     org_desc: currentQuestionGroupDetails.org_desc,
            //     org_name: currentQuestionGroupDetails.org_name,

            // };

            let editedOrganisation = {}

            if (initialName !== currentQuestionGroupDetails?.org_name) {
                editedOrganisation = { ...editedOrganisation, org_name: currentQuestionGroupDetails?.org_name }
            }
            if (initialDesc !== currentQuestionGroupDetails?.org_desc) {
                editedOrganisation = { ...editedOrganisation, org_desc: currentQuestionGroupDetails?.org_desc }
            }



            const response = await axios.put(
                `/organisations/${selectedOrganisation.id}`,
                editedOrganisation
            ).catch(
                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                })

            const { id, org_desc, org_name } = response.data

            setUpdatedOrganisation({
                id,
                org_desc,
                org_name,
            })

            setisLoading(false)
            setEditModal(false);

        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }
    };
    useEffect(() => {


        if (updatedOrganisation !== null) {
            const setUpdatedOrganisation = () => {
                dispatch({
                    type: 'UPDATE_ORGANISATION',
                    payload: updatedOrganisation
                })
            }

            setUpdatedOrganisation();
        }

    }, [dispatch, updatedOrganisation]);


    return (

        <Modal
            show={showEditModal}
            backdrop="static"
            onHide={() => {
                setEditModal(false);
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Organisation</Modal.Title>
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body >
                    {isLoading ? (
                        <div className='spinner'>
                            <SpinnerCircularFixed
                                size={60}
                                thickness={140}
                                speed={120}
                                color='var(--spinner-color)'
                                secondaryColor='rgb(240, 240, 240)
                                            '
                            />
                            <div className='spinner-text'>
                                <span>Updating Organisation...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {/* <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <span className='question'>Name:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea
                                            required
                                            value={org_name}
                                            className='form-control'
                                            onChange={(e) => {


                                                setCurrentQuestionGroupDetails({
                                                    ...currentQuestionGroupDetails, org_name: e.target.value.replace(
                                                        /[^\x20-\x7E]+/g,
                                                        ''
                                                    )
                                                })
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container> */}

                            <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <span className='question'>Description:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea
                                            // required
                                            value={org_desc}
                                            className='form-control'
                                            onChange={(e) => {

                                                setCurrentQuestionGroupDetails({
                                                    ...currentQuestionGroupDetails, org_desc: e.target.value.replace(
                                                        /[^\x20-\x7E]+/g,
                                                        ''
                                                    )
                                                })
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>



                        </>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='no-variant'
                        className='modal-cancel-button'
                        onClick={() => {
                            setEditModal(false);
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        className='modal-create-button'
                        variant='no-variant-p'>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditOrganisationModal;
