import React, { useState } from 'react';
import ThemeSelectItem from '../ThemeSelectItem/themeselect-item.component';
import AllQuestionsThemes from '../ThemeSelectItem/all-questions-item.component copy';
import CreateThemeModal from '../../Theme-Components/CreateThemeModal/create-theme-modal.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import EditThemeModal from '../../Theme-Components/EditThemeModal/edit-theme-modal-component';
import EditSubtheme from '../../Theme-Components/EditSubthemeModal/edit-subtheme-modal-component'
import { useSelector } from 'react-redux'
import addIcon from '../../../assets/addcirclebutton.svg'


import './theme-selection.component.scss';


function ThemeSelection() {

    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setEditShowModal] = useState(false);
    const [showSubthemeModal, setSubthemeModal] = useState(false);

    //useSelector from cohortReducer to get all cohort names, timestamps, ids

    const themes = useSelector((state) => state.theme.themes);



    // const cohorts = []


    return (
        <div className='theme-selection'>
            <h1 >
                Question Themes
            </h1>
            <div className="add-theme-button-container" onClick={() => {
                setShowModal(true);
            }}  >
                <img style={{ height: '2em' }} src={addIcon} alt='add_button' />
                {/* <FontAwesomeIcon icon={faPlusCircle} className="add-cohort-button" onClick={() => {
                    setShowModal(true);
                }} /> */}
                <span className="add-theme-button-text">Create New Theme</span>
            </div>

            {/* on click of the cohort item ... */}
            <div className='theme-items'>


                <Accordion>
                    <AllQuestionsThemes />
                    {themes.filter(x => x.id >= 0).map((theme) =>
                        <ThemeSelectItem
                            key={theme.id}
                            theme={theme}
                            setEditShowModal={setEditShowModal}
                            setSubthemeModal={setSubthemeModal}

                        />)}
                </Accordion>
            </div>

            <CreateThemeModal
                setShowModal={setShowModal}
                showModal={showModal}
            />


            {showEditModal ? <EditThemeModal
                setEditShowModal={setEditShowModal}
                showEditModal={showEditModal} /> : null}
            {showSubthemeModal ? <EditSubtheme
                setEditShowModal={setSubthemeModal}
                showEditModal={showSubthemeModal} /> : null}



        </div>
    );

}

export default ThemeSelection;