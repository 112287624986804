import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useRowSelect, useMountedLayoutEffect } from 'react-table'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { ColumnFilter } from '../../../Question-Components/QuestionTable/column-filter.component';
import { DropdownFilter } from '../../../Question-Components/QuestionTable/q-type-dropdown-filter.component';
import { ThemeDropDown } from '../../../Question-Components/QuestionTable/theme.dropdown.component';
import { SubthemeDropdown } from '../../../Question-Components/QuestionTable/subtheme.dropdown.component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faGripLines, faEdit } from '@fortawesome/free-solid-svg-icons';
import Toggle from 'react-toggle'
// import "react-toggle/style.css"

// import makeData from './makeData'

const Styles = styled.div`
padding: 1rem;

overflow-x: auto;
table {
  width:100%;
  border-spacing: 0;
  // border: 1px solid black;
  }
 tr:nth-child(even){background-color: #f2f2f2;}
 tr:hover {background-color: #ddd;}

  th{
      border-bottom: 1px solid black;
  },

  td {
    margin: 0;
    padding: 0.5rem;
  //   border-bottom: 1px solid black;
  //   border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
`
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                {/* <input type="checkbox" ref={resolvedRef} {...rest} /> */}
                <label>
                    <Toggle
                        // defaultChecked={this.state.tofuIsReady}
                        icons={false}
                        // onChange={this.handleTofuChange}
                        ref={resolvedRef} {...rest} />
                    {/* <span>No icons</span> */}
                </label>

                {/* 
                <label class="switch">
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                    <span class="slider round"></span>
                </label> */}
                {/* <Form.Check
                    type="switch"
                    id="custom-switch"
                    ref={resolvedRef} {...rest}
                // onChange={toggleSwitch}
                /> */}
                {/* <FormCheck
                    type="switch"
                    // id="custom-switch"
                    // label="Check this switch"
                    ref={resolvedRef} {...rest}
                /> */}
            </>
        )
    }
)

const Table = ({ columns, data, setChosenQuestions, requiredQuestions, setRequiredQuestions, setSavedQuestions, savedQuestions, onSelectedRowsChange, selectedRows, dispatch }) => {


    const [records, setRecords] = React.useState(data)


    useEffect(() => {


        if (data !== null)
            setRecords(data)


    }, [setRecords, data]);

    useEffect(() => {


        if (records !== null) {
            setChosenQuestions(records)
            // savedQuestions.current = records
            // setSavedQuestions(records)
            // dispatch({
            //     type: 'UPDATE_CREATE_SURVEY_ORDER',
            //     payload: records
            // })
        }

        //setSavedQuestions
        //dispatch

    }, [records]);


    const getRowId = React.useCallback(row => {
        return row.id
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        selectedFlatRows,
        // visibleColumns,
        // preGlobalFilteredRows,
        // setGlobalFilter,


        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        // data,
        data: records,
        getRowId,
        initialState: {
            pageIndex: 0,
            selectedRowIds: selectedRows
        },
        autoResetPage: false,


    }, useFilters, useGlobalFilter, // useGlobalFilter!
        usePagination, useRowSelect,

        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
                            Required
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />

                        </div>
                    ),
                },
                ...columns,
            ])
        }

    )


    useMountedLayoutEffect(() => {
        onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
    }, [onSelectedRowsChange, selectedRowIds]);







    useEffect(() => {


        const requiredQuestionIds = Object.keys(selectedRowIds)

        const formattedIds = requiredQuestionIds.map(Number)


        setRequiredQuestions(formattedIds)


    }, [selectedRowIds, setRequiredQuestions]);








    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = records[dragIndex]

        // const selectedRowIdsshort = Object.keys(selectedRowIds)
        // const recordsWithRows = records.map(x => {
        //     if (selectedRowIdsshort.includes(x.id.toString())) {
        //         return { ...x, implicit_flag: true }
        //     }
        //     return x
        // })

        setRecords(
            update(records, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            })
        )
    }


    return (
        <DndProvider backend={HTML5Backend}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            <th></th>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, index) =>
                            prepareRow(row) || (
                                <Row
                                    index={index}
                                    row={row}
                                    moveRow={moveRow}
                                    {...row.getRowProps()}
                                />
                            )
                    )}
                </tbody>
            </table>

            <br />

            {/* <div className="pagination">
                <div className='pagination-buttons'>
                    <div className='button-container'>
                        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </Button>{' '}
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </Button>{' '}
                        <Button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </Button>{' '}
                        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </Button>{' '}
                    </div>
                    <div className='go-to-container'>
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <span>
                            <strong>| Go to page:{' '} </strong>
                        </span>
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                    </div>
                    <div className='dropdown-container'>
                        <select
                            value={pageSize}
                            className="form-select"
                            style={{ width: '100px' }}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>

                </div>
            </div> */}
        </DndProvider>
    )
}

const DND_ITEM_TYPE = 'row'

const Row = ({ row, index, moveRow }) => {
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)

    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
            if (!dropRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        type: DND_ITEM_TYPE,
        item: () => ({ index }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)

    return (
        <tr ref={dropRef} style={{ opacity }}>
            {/* <td ref={dragRef}>move</td> */}
            <td ref={dragRef} className="create-theme-button-container" >
                <FontAwesomeIcon icon={faGripLines} className="girp-theme-button" />
            </td>
            {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
        </tr>
    )
}


const removeSelectedQuestion = (e, chosenQuestions, setChosenQuestions) => {


    // const updatedSelectedQuestions = chosenQuestions.filter(x => {

    //     if (x?.original?.id !== e?.id) {
    //         return x
    //     }
    //     return x
    // }
    // )

    const updatedSelectedQuestions = chosenQuestions.filter(x => {

        if (x?.id !== e?.id) {
            return x
        }
    }
    )

    setChosenQuestions(updatedSelectedQuestions)

}




const App = ({ setEditModal, setChosenQuestions, chosenQuestions, setSavedQuestions, savedQuestions, requiredQuestions, setRequiredQuestions }) => {


    const dispatch = useDispatch()

    const [question, setQuestion] = useState(null)



    ///!!!!!!!!!!!!!!!!!!!!!!!!!! WILL NEED TO RENDER A QUESTION BACCK with USE SELECTOR THAT HAS BEEN SELECTED TO OLD FORMAT 
    ///......OR JUST REFORMAT THE QUESTION HERE RETURN NEW OBJECT

    useEffect(() => {


        if (question !== null) {
            const addNewQuestion = () => {
                dispatch({
                    type: 'SELECT_QUESTION',
                    payload: question
                })
            }

            addNewQuestion();
        }

    }, [dispatch, question]);

    const columns = React.useMemo(
        () => [
            // {
            //     Header: 'ID',
            //     accessor: 'id',
            // },

            {
                Header: 'Question',
                accessor: 'question_text',
                Filter: ColumnFilter,
                Cell: ({ cell }) => {
                    /* Add data-tip */
                    // return <span data-tip={row.value}>{row.value}</span>;

                    if (cell.row.original.question_type === 'likert_scale') {

                        return <>
                            <OverlayTrigger
                                key={cell.row.original.id}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={cell.row.original.id} >
                                        <div className="params">
                                            <span><strong >Min label:</strong>  {cell.row.original.likert_scale.min_label}</span>
                                            <span><strong >Min value:</strong>  {cell.row.original.likert_scale.min_value}</span>
                                            <span><strong >Max label:</strong> {cell.row.original.likert_scale.max_label}</span>
                                            <span><strong >Max value:</strong>  {cell.row.original.likert_scale.max_value}</span>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span>{cell.row.original.question_text}</span>
                            </OverlayTrigger>
                        </>;
                    }

                    if (cell.row.original.question_type === 'mcq' || cell.row.original.question_type === 'checkbox' || cell.row.original.question_type === 'dropdown') {

                        const choices = cell.row.original.choices.map(choice => choice.value)


                        return <>
                            <OverlayTrigger
                                key={cell.row.original.id}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={cell.row.original.id} >
                                        <div className="params">
                                            <span><strong >Choices:</strong></span>
                                            {choices.join(", ")}
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span>{cell.row.original.question_text}</span>
                            </OverlayTrigger>
                        </>;
                    }

                    if (cell.row.original.question_type === 'short_answer' || cell.row.original.question_type === 'paragraph') {

                        return <span>{cell.row.original.question_text}</span>
                    }


                    if (cell.row.original.question_type === 'grid_mcq') {



                        const rows = cell.row.original.grid_choices.filter(i => i.axis === 'row').map(value => {
                            if (value.axis === 'row') {
                                return value.value
                            }

                            return null

                        })


                        const columns = cell.row.original.grid_choices.filter(i => i.axis === 'col').map(value => {
                            if (value.axis === 'col') {
                                return value.value
                            }

                            return null

                        })

                        return <>
                            <OverlayTrigger
                                key={cell.row.original.id}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={cell.row.original.id} >
                                        <div className="params">
                                            <span><strong >Rows: </strong>{rows.join(", ")}</span>
                                            <span><strong >Columns: </strong>  {columns.join(", ")}</span>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span>{cell.row.original.question_text}</span>
                            </OverlayTrigger>
                        </>;
                    }


                    return <span>{cell.row.original.question_text}</span>

                }

            },

            {
                Header: 'Theme',
                // defaultCanFilter: true,
                disableFilters: true,
                Filter: ThemeDropDown,
                accessor: 'theme',
                Cell: ({ cell }) => {


                    if (cell.row.original.theme && cell.row.original.parent_theme) {
                        return cell.row.original.parent_theme
                    }
                    if (cell.row.original.theme && !cell.row.original.parent_theme) {
                        return cell.row.original.theme
                    }
                    if (!cell.row.original.theme && cell.row.original.parent_theme) {
                        return cell.row.original.parent_theme
                    }

                    return ''

                }
            },
            {
                Header: 'Subtheme',
                Filter: SubthemeDropdown,
                disableFilters: true,

                accessor: 'parent_theme',
                Cell: ({ cell }) => {



                    if (cell.row.original.theme && cell.row.original.parent_theme) {
                        return cell.row.original.theme
                    }
                    return ''


                }
            },
            {
                Header: 'Reverse Coded',
                accessor: 'reverse_coding_flag',
                Filter: ColumnFilter,
                disableFilters: true,
                Cell: ({ cell }) => {


                    if (cell.row.original.reverse_coding_flag) {
                        return 'Yes'
                    }
                    return 'No'

                }
            },


            {
                Header: 'Question Type',
                accessor: 'question_type', //NEED to change to CELL so that switches likert_scale to Liker Scale returns jsx
                // defaultCanFilter: true,
                Filter: DropdownFilter,
                filter: 'equals',
                Cell: ({ cell }) => {

                    if (cell.row.original.question_type === 'likert_scale') {
                        return 'Likert scale'
                    }

                    if (cell.row.original.question_type === 'mcq') {
                        return 'Multiple Choice'
                    }

                    if (cell.row.original.question_type === 'checkbox') {
                        return 'Checkbox'
                    }

                    if (cell.row.original.question_type === 'dropdown') {
                        return 'Dropdown'
                    }
                    if (cell.row.original.question_type === 'paragraph') {
                        return 'Paragraph'
                    }
                    if (cell.row.original.question_type === 'short_answer') {
                        return 'Short Answer'
                    }

                    if (cell.row.original.question_type === 'grid_mcq') {
                        return 'Grid Multiple Choice'
                    }
                    if (cell.row.original.question_type === 'grid_likert_scale') {
                        return 'Grid Likert scale'
                    }

                    return null //to be replaced by other question type conditionals and null wont happen

                }
            },
            // {
            //     Header: 'Required',
            //     // defaultCanFilter: true,
            //     Filter: false,
            //     Cell: ({ cell }) => {

            //         // return <Button value={cell.row.original} onClick={() => {
            //         //     setQuestion(cell.row.original)
            //         //     // setEditModal(true)
            //         // }}>
            //         //     Edit Question
            //         // </Button>

            //         // defaultChecked={cell.row.original.implicit_flag} 
            //         return (<div className="create-theme-button-container" >
            //             <input type="checkbox" value={cell.row.original} onClick={() => handleRequired(cell.row.original, chosenQuestions, setChosenQuestions, requiredQuestions, setRequiredQuestions)} />


            //         </div>)
            //     }
            // },
            // {
            //     Header: '  ',
            //     // defaultCanFilter: true,
            //     Filter: false,
            //     Cell: ({ cell }) => {



            //         return (<div className="create-theme-button-container" >
            //             <FontAwesomeIcon icon={faEdit} className="edit-theme-button" value={cell.row.original} onClick={() => {
            //                 setQuestion(cell.row.original)
            //                 // setEditModal(true)
            //             }}
            //             />
            //         </div>)
            //     }
            // },
            {
                Header: ' ',
                // defaultCanFilter: true,
                Filter: false,
                Cell: ({ cell }) => {




                    return (<div className="create-theme-button-container" >
                        <FontAwesomeIcon icon={faTrash} className="remove-theme-button" value={cell.row.original} onClick={() => {
                            // setQuestion(cell.row.original)
                            // setEditModal(true)
                            removeSelectedQuestion(cell.row.original, chosenQuestions, setChosenQuestions)
                        }} />
                    </div>)


                }
            }

        ],
        [chosenQuestions, setChosenQuestions]
    )




    // const formatedChosenQuestions = chosenQuestions.map(x => x.original
    //     // if (x?.original) {
    //     //     return x.original
    //     // }
    //     // return x
    // )





    const currentRows = requiredQuestions.reduce((a, v) => ({ ...a, [v]: true }), {})


    const [selectedRows, setSelectedRows] = useState(currentRows);





    return (
        <>
            <div className='selected-question-amount'>Selected Questions: {chosenQuestions.length}</div>
            <Styles>
                <Table columns={columns} data={chosenQuestions}
                    // setSavedQuestions={setSavedQuestions}
                    setChosenQuestions={setChosenQuestions}
                    setSavedQuestions={setSavedQuestions}
                    savedQuestions={savedQuestions}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                    requiredQuestions={requiredQuestions}
                    setRequiredQuestions={setRequiredQuestions}
                    dispatch={dispatch}

                />
            </Styles>
        </>
    )
}

export default React.memo(App)
