import axios from 'axios';


const modifiedAxios = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL //'https://phase3-survey-tool-dev.nw.r.appspot.com/'
});

export const cancelToken = axios.CancelToken

modifiedAxios.defaults.params = {};

modifiedAxios.interceptors.request.use( 

  function (config) {
    
    // const organisationId = useSelector((state) => state.user.organisationId); 

    const token = localStorage.getItem("jwt"); 
    const organisationId = localStorage.getItem("organisationId"); 


  
    
    let url = new URL(process.env.REACT_APP_API_BASE_URL + config.url)

 

    if (token) {

        
      // const organisationId = useSelector((state) => state.user.organisationId); 
      config.headers["Authorization"] = 'Bearer ' + token;
      config.headers['Content-Type'] = 'application/json';


     
  
      if (config.method !== 'delete' && url.pathname !== '/themes' && url.pathname.includes('/keep-alive') === false && url.pathname.includes('/surveys/') === false ) {
        config.data = { ...config.data, og: parseInt(organisationId) }
      }

      if (config.method !== 'delete' && url.pathname === '/themes' ) {
        config.data = { ...config.data, ogs: [parseInt(organisationId)] }

      }


      if (config.method === 'get'  && url.pathname.includes('/surveys/responses/') ) {
        config.params['og'] = organisationId;
        config.params['content'] = 'responses';
   
    } 

      // GET REQUEST FOR ALL EXCEPT SURVEY FORM AND THEMES AND SURVEY RESPONSES CSV DOWNLOAD
      
      //
      if (config.method === 'get'  && url.pathname !== '/surveys/form/'&& url.pathname.includes('/surveys/responses/') === false  && url.pathname !== '/themes' && url.pathname.includes('/surveys/') === false ) { // Added url pathname includes surveys to stop organisation ID being attached to findOne Survey Filters.
          config.params['og'] = organisationId;
          //config.params['_sort'] = 'id:DESC';
      } 


      

      //GET REQUEST FOR THEMES
      if (config.method === 'get' && url.pathname === '/themes' ) {
        config.params['ogs'] = organisationId;
        //config.params['_sort'] = 'id:DESC';
        
      } 

    
       if (url.pathname === '/surveys/form/') {

          let formattedUrl = url.search.split('/')
      

         config.url = `surveys/form/${formattedUrl[1]}`

   
         
        }

    }
    
    if (!token && url.pathname === '/surveys/form/') {

      let formattedUrl = url.search.split('/')

      
      config.headers['Content-Type'] = 'application/json';
      config.url = `surveys/form/${formattedUrl[1]}`


    }

  
      return config;

      
    },
    function(error) {
      return Promise.reject(error);
    }
);

  // modifiedAxios.interceptors.response.use(res => {
  //   // success
  //   return res
  // }, err => {
  //   const { status } = err.response
  
  //   if (status === 403 || status === 401) {
  //     // here we have access of the useHistory() from current Router
  //     localStorage.removeItem('jwt')
  //     localStorage.removeItem('organisationId')
  //     history.push('/')
  //     location.href = "'/login'


  //     // }
  //   }
  //   console.log('error', err)

  //   ErrorCatch(err)
  
  //   return Promise.reject(err)
  // })



// export const setupInterceptors = history => {
//   modifiedAxios.interceptors.response.use(res => {
//     // success
//     return res
//   }, err => {
//     const { status } = err.response
  
//     if (status === 403 || status === 401) {
//       // here we have access of the useHistory() from current Router
//       localStorage.removeItem('jwt')
//       localStorage.removeItem('organisationId')
//       history.push('/')


//     }

//     console.log('error', err)

//     ErrorCatch(err)
  
//     return Promise.reject(err)
//   })
// }
// setupInterceptors()

export default modifiedAxios;
// modifiedAxios.interceptors.response.use( 
  
//   function(response) {
//     return response

//   },
//   function (error) {


    


//     if (error.response.status === 403) {

//       const dispatch = useDispatch();


//       // const history = useHistory();
//       // history.push('/')

      
//     }
//     return Promise.reject(error);
    
//   }
// );



// Changes


// Added url pathname includes surveys to stop organisation ID being attached to findOne Survey Filters. - FM 
