import React, { useState, useEffect } from 'react';
// import Axios from 'axios';
import { Modal, Button, FormCheck } from 'react-bootstrap';
import Toggle from 'react-toggle'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import axios from "../../../misc/modifiedAxios";
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../../Question-Components/CreateQuestionModal/create-question.styles.scss";
import LikertScaleQuestion from '../../Question-Components/CreateQuestionModal/likert-question/likert-question.component';
import ChoiceQuestion from '../../Question-Components/CreateQuestionModal/choice-question/choice-questions.component'
import GridChoiceQuestion from '../../Question-Components/CreateQuestionModal/grid-question/grid-questions.component'
import GridLikertQuestion from '../../Question-Components/CreateQuestionModal/grid-likert-question/grid-likert-questions.component';
import ConfirmPopUp from '../../../misc/ConfirmPopUp';

const CreateSurveyQuestionModal = ({ showModal, setShowModal, setMasterData, masterData, setFirstInitiation }) => {
    const [key, setKey] = useState('type'); //Tab initial view

    const history = useHistory()

    const themes = useSelector((state) => state.theme.themes);


    const filteredThemes = themes.filter(x => x.type === 'regular')

    const organisationId = useSelector((state) => state.user.organisationId);


    const [selectedTheme, setSelectedTheme] = useState(null)

    const [selectedSubthemeId, setSelectedSubthemeId] = useState(null) //initialSelectedSubtheme?.id

    const dispatch = useDispatch()

    const [isLoading, setisLoading] = useState(false);


    const options = ['Likert scale', 'Multiple Choice', 'Checkbox', 'Dropdown', 'Paragraph', 'Short Answer', 'Grid Multiple Choice', 'Grid Likert scale']



    const [questionText, setQuestionText] = useState('')
    const [questionType, setQuestionType] = useState('')

    // const [addAnother, setAddAnother] = useState(false) //Add Another Question flag


    const [confirmCreated, setConfirmCreated] = useState(false)//Add Another Question Confirmation popup


    //_________LIKERT SCALE_________________
    const [minValue, setMinValue] = useState(1)
    const [maxValue, setMaxValue] = useState(7)
    const [reverse, setReverse] = useState(false)
    const [notApplicable, setNotApplicable] = useState(false)
    const [implicit, setImplicit] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startLabel, setStartLabel] = useState('Strongly Disagree')
    const [endLabel, setEndLabel] = useState('Strongly Agree')


    //_________CHOICE__________________________

    const [formValues, setFormValues] = useState([{ value: "" }])


    const values = formValues.map(value => ({ ...value, order: formValues.indexOf(value) }))


    //________GRID_________________________

    const [rowValues, setRowValues] = useState([{ row_value: "" }])
    const [columnValues, setColumnValues] = useState([{ column_value: "" }])


    const rows = rowValues.map(row => ({ ...row, order: rowValues.indexOf(row) }))
    const columns = columnValues.map(column => ({ ...column, order: columnValues.indexOf(column) }))

    const merged = [...rows, ...columns]

    const gridValues = merged.map(value => {

        if ('row_value' in value) {
            return { axis: 'row', value: value.row_value, order: value.order }
        }

        if ('column_value' in value) {
            return { axis: 'col', value: value.column_value, order: value.order }
        }

        return null
    }
    )


    const submitQuestionCreate = async (addAnotherFlag) => {
        // e.preventDefault();
        setisLoading(true)

        let themeId

        if (selectedTheme?.type === "allUnassigned" || selectedTheme?.type === "all" || !selectedTheme) {
            themeId = null
        }

        if (selectedTheme && selectedTheme.type === 'regular' && !selectedSubthemeId) {
            themeId = selectedTheme.id

        }
        if (selectedTheme && selectedTheme.type === 'regular' && selectedSubthemeId) {
            themeId = parseInt(selectedSubthemeId)
        }

        let newQuestion

        try {
            if (questionType === 'likert_scale') {

                //________________LIEKRT SCALE________________
                newQuestion =

                {
                    data: {
                        question_text: questionText,
                        reverse_coded_flag: reverse,
                        objective: implicit,
                        start_date: startDate.toISOString().slice(0, 10),
                        end_date: endDate.toISOString().slice(0, 10),
                        question_type: questionType,
                        theme: themeId,
                        likert_scale:
                        {
                            min_value: minValue,
                            max_value: maxValue,
                            min_label: startLabel,
                            max_label: endLabel,
                            na_flag: notApplicable,
                        },
                        choices: [],
                        grid_choices: [],
                        ogs: organisationId,
                    }

                }


            }

            if (questionType === 'paragraph' || questionType === 'short_answer') {


                //________________PARAGRAPH________________
                newQuestion =
                {
                    data: {
                        question_text: questionText,
                        // reverse_coded_flag: reverse,
                        objective: implicit,
                        start_date: startDate.toISOString().slice(0, 10),
                        end_date: endDate.toISOString().slice(0, 10),
                        question_type: questionType,
                        theme: themeId,
                        choices: [],
                        grid_choices: [],
                        likert_scale: null,
                        ogs: organisationId,

                    }
                }

            }
            //________________________________________________

            //________________CHOICE _______________
            if (questionType === 'mcq' || questionType === 'checkbox' || questionType === 'dropdown') {



                newQuestion =
                {
                    data: {
                        question_text: questionText,
                        // reverse_coded_flag: reverse,
                        objective: implicit,
                        start_date: startDate.toISOString().slice(0, 10),
                        end_date: endDate.toISOString().slice(0, 10),
                        question_type: questionType,
                        theme: themeId,

                        choices: values,
                        grid_choices: [],
                        likert_scale: null,
                        ogs: organisationId,
                    }
                }

            }

            if (questionType === 'grid_mcq') {

                newQuestion =
                {
                    data: {
                        question_text: questionText,
                        reverse_coded_flag: reverse,
                        objective: implicit,
                        start_date: startDate.toISOString().slice(0, 10),
                        end_date: endDate.toISOString().slice(0, 10),
                        question_type: questionType,
                        theme: themeId,

                        grid_choices: gridValues,
                        likert_scale: null,
                        choices: [],
                        ogs: organisationId,

                    }
                }
            }
            if (questionType === 'grid_likert_scale') {

                newQuestion =
                {
                    data: {
                        question_text: questionText,
                        reverse_coded_flag: reverse,
                        objective: implicit,
                        start_date: startDate.toISOString().slice(0, 10),
                        end_date: endDate.toISOString().slice(0, 10),
                        question_type: questionType,
                        theme: themeId,


                        grid_likert_scale: {

                            row_choices: gridValues.filter(function (obj) {
                                return obj.axis !== 'col';
                            }),//gridValues,
                            likert_scale:
                            {
                                min_value: minValue,
                                max_value: maxValue,
                                min_label: startLabel,
                                max_label: endLabel
                            },
                        },
                        grid_choices: [],
                        likert_scale: null,
                        choices: [],
                        ogs: organisationId,

                    }
                }

            }



            // //________________________________________________


            let response


            // if (!themeId) {


            if (questionText && questionType) {

                response = await axios.post(
                    `/questions`,
                    newQuestion
                ).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }

                    }

                )

                // could add flag variable firstInitiion here that if false, dont set state again
                setFirstInitiation(false)
                dispatch({
                    type: 'ADD_NEW_QUESTION',
                    payload: response.data
                })



            }


            setQuestionType('')
            setQuestionText('')
            setFormValues([{ value: "" }])
            setColumnValues([{ column_value: "" }])
            setRowValues([{ row_value: "" }])
            setReverse(false)
            setNotApplicable(false)
            setImplicit(false)
            setStartDate(new Date())
            setEndDate(new Date())

            // setSelectedTheme(initialSelectedTheme)
            // setSelectedSubthemeId(intialSelectedChildTheme?.id)

            setSelectedTheme('')
            setSelectedSubthemeId('')


            setisLoading(false)

            if (!addAnotherFlag) {
                setConfirmCreated(false)
                setShowModal(false);

                return
            }

            setConfirmCreated(true)

        } catch (err) {
            console.log(err)
            window.alert('Internal error, please contact admin');
        }
    };




    const toggleImplicit = (e) => {
        setImplicit(prevImplicit => !prevImplicit)

    }

    // Retrieve themes to populate in the dropdown
    useEffect(() => {
        let controller = new AbortController();
        let controllerTheme = new AbortController();
        (async () => {
            try {

                // setIsLoading(true)
                // 

                //GET AND SET THEMES

                const responseThemes = await axios.get(
                    `/themes?ogs=${organisationId}`,
                    {
                        signal: controllerTheme.signal
                    }
                ).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })
                    ;

                controllerTheme = null;

                dispatch({
                    type: 'SET_THEMES',
                    payload: responseThemes.data
                })

                // setIsLoading(false)
            } catch (err) {
                console.log(err)
                // setIsLoading(false)
            }
        })();

        return () => controller?.abort();
    }, [dispatch, organisationId]);


    // useEffect(() => {
    //     if (addAnother) {
    //         submitQuestionCreate();
    //     }

    // }, [addAnother]);

    return (
        <Modal
            show={showModal}
            backdrop="static"
            size='lg'
            onHide={() => {
                setShowModal(false);
                setQuestionType('')
                setReverse(false)
                setNotApplicable(false)
                setImplicit(false)
                setFormValues([{ value: "" }])
                setColumnValues([{ column_value: "" }])
                setRowValues([{ row_value: "" }])
                setStartDate(new Date())
                setEndDate(new Date())
                // if (initialSelectedTheme?.id === -1) {
                setSelectedTheme('')
                setSelectedSubthemeId('')
                // } else {
                //     setSelectedTheme(initialSelectedTheme)
                //     setSelectedSubthemeId(intialSelectedChildTheme)
                // }
                setKey('type')
                // setAddAnother(false)
                setConfirmCreated(false)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Create Question</Modal.Title>
            </Modal.Header>
            <div >
                <Modal.Body >

                    {isLoading ? (
                        <div className='spinner'>
                            <SpinnerCircularFixed
                                size={60}
                                thickness={140}
                                speed={120}
                                color='var(--spinner-color)'
                                secondaryColor='rgb(240, 240, 240)
                                            '
                            />
                            <div className='spinner-text'>
                                <span>Creating Question...</span>
                            </div>
                        </div>
                    ) : (

                        <>
                            <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <div className='question-text-container'>
                                            <span className='question'>Question Text:</span>
                                            {questionType === 'short_answer' ? (<span className='short-answer'>Short Answer is limited to 250 characters</span>) : (null)}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea
                                            required
                                            className='form-control section-inputs'
                                            maxLength='350'
                                            placeholder='max 350 characters'
                                            // maxLength={questionType === 'short_answer' ? ('10') : (null)}
                                            onChange={(e) => {

                                                // if (e.target.value.trim() !== '') {
                                                setQuestionText(e.target.value.replace(
                                                    /[^\x20-\x7E]+/g,
                                                    ''
                                                ))
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3 tabs-menu">

                                <Tab eventKey="type" title="Type" >
                                    <Container
                                        fluid
                                    // className='box'
                                    >
                                        <Row>
                                            <Col>
                                                <span className='question'>Type:</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <select
                                                    type='text'
                                                    className='form-control question-modal-any-dropdown'
                                                    onChange={(e) => {
                                                        if (e.target.value === 'Likert scale')
                                                            setQuestionType('likert_scale')
                                                        if (e.target.value === 'Multiple Choice')
                                                            setQuestionType('mcq')
                                                        if (e.target.value === 'Checkbox')
                                                            setQuestionType('checkbox')
                                                        if (e.target.value === 'Dropdown')
                                                            setQuestionType('dropdown')
                                                        if (e.target.value === 'Paragraph')
                                                            setQuestionType('paragraph')
                                                        if (e.target.value === 'Short Answer')
                                                            setQuestionType('short_answer')
                                                        if (e.target.value === 'Grid Multiple Choice') {
                                                            setColumnValues([{ column_value: "" }])
                                                            setRowValues([{ row_value: "" }])
                                                            setQuestionType('grid_mcq')
                                                        }

                                                        if (e.target.value === 'Grid Likert scale') {
                                                            setColumnValues([{ column_value: "" }])
                                                            setRowValues([{ row_value: "" }])
                                                            setQuestionType('grid_likert_scale')
                                                        }

                                                        if (e.target.value === '')
                                                            setQuestionType('')
                                                    }}
                                                    // selected=''
                                                    style={{
                                                        marginBottom: '10px',
                                                        marginTop: '10px',
                                                        width: '30%',
                                                    }}>
                                                    <option value=''>None Selected</option>
                                                    {options.map((option) => {
                                                        return (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Container>



                                    {questionType === 'likert_scale' ? (
                                        <LikertScaleQuestion
                                            setMinValue={setMinValue}
                                            setMaxValue={setMaxValue}
                                            setStartLabel={setStartLabel}
                                            setEndLabel={setEndLabel}
                                            setReverse={setReverse}
                                            setNotApplicable={setNotApplicable}
                                            startLabel={startLabel}
                                            endLabel={endLabel}
                                            minValue={minValue}
                                            maxValue={maxValue}
                                        />
                                    ) : (null)}


                                    {questionType === 'mcq' || questionType === 'checkbox' || questionType === 'dropdown' ?
                                        (<ChoiceQuestion
                                            // setChoices={setChoices}
                                            setFormValues={setFormValues}
                                            formValues={formValues} />) : (null)}

                                    {questionType === 'grid_mcq' ? (<GridChoiceQuestion
                                        setColumnValues={setColumnValues}
                                        setReverse={setReverse}
                                        setRowValues={setRowValues}
                                        rowValues={rowValues}
                                        columnValues={columnValues} />) : (null)}

                                    {questionType === 'grid_likert_scale' ? (<GridLikertQuestion

                                        setReverse={setReverse}
                                        // GRID ROW VALUES

                                        setRowValues={setRowValues}
                                        rowValues={rowValues}

                                        // LIKERT VALUES
                                        setMinValue={setMinValue}
                                        setMaxValue={setMaxValue}
                                        setStartLabel={setStartLabel}
                                        setEndLabel={setEndLabel}
                                        startLabel={startLabel}
                                        endLabel={endLabel}
                                        minValue={minValue}
                                        maxValue={maxValue}

                                    />) : (null)}



                                    <Container
                                        fluid
                                        // className='box'
                                        style={{
                                            paddingBottom: '15px',
                                            marginBottom: '15px',
                                        }}
                                    >
                                        <Row>
                                            <Col>
                                                <span className='question'>Perspective:</span>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>

                                                <input id="toggle-on" className="toggle toggle-left" name="siwtch" value="false" type="radio" defaultChecked onChange={toggleImplicit} />
                                                <label htmlFor="toggle-on" className="btn">Subjective</label>
                                                <input id="toggle-off" className="toggle toggle-right" name="siwtch" value="true" type="radio" onChange={toggleImplicit} />
                                                <label htmlFor="toggle-off" className="btn">Objective</label>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                                <Tab eventKey="theme" title="Theme">
                                    <Container
                                        fluid
                                    // className='box'
                                    >
                                        <Row>
                                            <Col>
                                                <span className='question'>Themes:</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <select
                                                    type='text'
                                                    className='form-control question-modal-any-dropdown'

                                                    onChange={(e) => {

                                                        if (e.target.value === '') {

                                                            setSelectedTheme('')
                                                            setSelectedSubthemeId('')
                                                        } else {
                                                            let theme = themes.filter((theme) => theme.id === parseInt(e.target.value))

                                                            setSelectedTheme(theme[0])
                                                            setSelectedSubthemeId('')
                                                        }


                                                    }}
                                                    style={{
                                                        marginBottom: '10px',
                                                        marginTop: '10px',
                                                        width: '30%',
                                                    }}>
                                                    <option value=''> None Selected</option>
                                                    {filteredThemes.map((option) => {
                                                        return (
                                                            // selected={initialSelectedTheme?.theme_name === option.theme_name ? true : false}
                                                            <option key={option.id} value={option.id}  >
                                                                {option.theme_name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Container>

                                    {selectedTheme && selectedTheme?.id !== -2 && selectedTheme?.id !== -1 ? <Container
                                        fluid
                                    // className='box'
                                    >
                                        <Row>
                                            <Col>
                                                <span className='question'>Subthemes:</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>


                                                {selectedTheme?.child_themes?.length > 0 ?

                                                    <select
                                                        type='text'
                                                        className='form-control question-modal-any-dropdown'
                                                        onChange={(e) => {

                                                            setSelectedSubthemeId(e.target.value)
                                                        }}
                                                        selected=''
                                                        style={{
                                                            marginBottom: '10px',
                                                            marginTop: '10px',
                                                            width: '30%',
                                                        }}>
                                                        <option value=''>None Selected</option>

                                                        {selectedTheme.child_themes?.map((option) => {
                                                            return (
                                                                // selected={intialSelectedChildTheme?.theme_name === option.theme_name ? true : false}
                                                                <option key={option.id} value={option.id} >
                                                                    {option.theme_name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select> : <div className='empty-subthemes-message'>This Theme does not have Subthemes</div>}
                                            </Col>
                                        </Row>
                                    </Container> : null}
                                </Tab>



                            </Tabs>
                        </>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button

                        variant='no-variant'
                        className='clear-button clear-button--secondary'
                        onClick={() => {

                            setStartLabel('Strongly Disagree')
                            setEndLabel('Strongly Agree')
                            setQuestionType('')
                            setFormValues([{ value: "" }])
                            setColumnValues([{ column_value: "" }])
                            setRowValues([{ row_value: "" }])
                            setReverse(false)
                            setNotApplicable(false)
                            setImplicit(false)
                            setStartDate(new Date())
                            setEndDate(new Date())
                            // if (initialSelectedTheme?.id === -1) {
                            setSelectedTheme('')
                            setSelectedSubthemeId('')
                            // } else {
                            //     setSelectedTheme(initialSelectedTheme)
                            //     setSelectedSubthemeId(intialSelectedChildTheme)
                            // }
                            // setAddAnother(false)
                            setConfirmCreated(false)
                            setKey('type')
                            setShowModal(false);
                            // setShortAnswer(false)
                        }}>
                        <span>Cancel</span>
                    </Button>

                    <Button
                        onClick={() => {
                            setKey('type')
                            // setAddAnother(false)
                            setConfirmCreated(false)
                            setShowModal(false)
                            submitQuestionCreate(false)
                        }}
                        disabled={questionText && questionType ? false : true}
                        // type='submit'
                        className='card-button'

                        variant='no-variant-p'>
                        <span>  Create</span>

                    </Button>

                    <Button

                        className='card-button'
                        variant='no-variant-p'
                        disabled={questionText && questionType ? false : true}
                        onClick={() => {
                            // setAddAnother(true)
                            submitQuestionCreate(true)

                        }}
                    // type='submit'
                    >
                        <span>Create & Add Another</span>
                    </Button>
                </Modal.Footer>
            </div>
            {confirmCreated ? <ConfirmPopUp
                confirmCreated={confirmCreated}
                setConfirmCreated={setConfirmCreated}
                message={questionText} />
                : null}
        </Modal >
    );
};

export default CreateSurveyQuestionModal;
