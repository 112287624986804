import { updateQuestionGroupDetails, removeQuestionGroup } from './question-group.utils'

const INITIAL_STATE = {
	questionGroups: [],
	selectedQuestionGroup: null,
};

const questionGroupReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_QUESTION_GROUPS':
			return {
				...state,
				questionGroups: action.payload,
			};
		case 'SELECT_QUESTION_GROUP':
			return {
				...state,
				selectedQuestionGroup: action.payload,
			};
		case 'ADD_NEW_QUESTION_GROUP':
			return {
				...state,
				questionGroups: [...state.questionGroups, action.payload ],
			};
		case 'UPDATE_QUESTION_GROUP_DETAILS':
				return {
					...state,
					questionGroups: updateQuestionGroupDetails(state.questionGroups, action.payload),
					selectedQuestionGroup: action.payload
			};
		case 'DELETE_QUESTION_GROUP':
			return {
				...state,
				questionGroups: removeQuestionGroup(state.questionGroups, action.payload),
				// selectedSurvey: action.payload
			};
		default:
			return state;
	}
};

export default questionGroupReducer;
