import React from 'react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Toggle from 'react-toggle'

import "react-datepicker/dist/react-datepicker.css";


const LikertScaleQuestion = ({ setMinValue, setMaxValue, setStartLabel, setEndLabel, startLabel, endLabel, minValue, maxValue, setReverse, setNotApplicable }) => {


    const toggleSwitch = (e) => {
        setReverse(prevReverse => !prevReverse)

    }


    const toggleNA = (e) => {
        setNotApplicable(prevNotApplicable => !prevNotApplicable)

    }

    // const minOptions = [[0, 18], [1, 16]]
    // const maxOptions = [[2, 19], [3, 20], [4, 21], [5, 22], [6, 23], [7, 17], [8, 24], [9, 25], [10, 26]]

    const minOptions = [0, 1]
    const maxOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10]

    return (

        <div>

            <Container
                fluid
            // className='box'
            >
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <span className='question'>Min:</span>
                            </Col>
                        </Row>
                        <select
                            value={minValue}
                            type='text'
                            className='form-control'
                            onChange={(e) => {
                                // setMinValue(e.target.value.split(',').map(x => parseInt(x))) // split on comma, into numbers array and also change them into integers as it makes strings initially 
                                setMinValue(parseInt(e.target.value)) // split on comma, into numbers array and also change them into integers as it makes strings initially 
                            }}
                            // selected=''

                            style={{
                                marginBottom: '10px',
                                marginTop: '10px',
                                width: '30%',
                            }}>
                            {/* <option></option> */}
                            {minOptions.map((option) => {
                                return (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                );
                            })}
                        </select>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <span className='question'>Max:</span>
                            </Col>
                        </Row>
                        <select
                            type='text'
                            className='form-control'
                            onChange={(e) => {
                                setMaxValue(e.target.value.split(',').map(x => parseInt(x))) // split on comma, into numbers array and also change them into integers as it makes strings initially 
                            }}
                            value={maxValue}
                            style={{
                                marginBottom: '10px',
                                marginTop: '10px',
                                width: '30%',
                            }}>
                            {/* <option></option> */}
                            {maxOptions.map((option) => {
                                return (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                );
                            })}
                        </select>
                    </Col>
                </Row>
            </Container>
            <Container
                fluid
                // className='box'
                style={{
                    paddingTop: '20px',
                }}>
                <Row>
                </Row>
                <Row>

                    <Col>

                        <Row> <span className='question' style={{ paddingBottom: '1em' }}>Min label:</span></Row>

                        <input
                            required
                            className='form-control'
                            value={startLabel}
                            onChange={(e) => {
                                // setError('');

                                //prevent only 'space' answers and clear out emojis (.replace())
                                // if (e.target.value.trim() !== '') {

                                setStartLabel(e.target.value.replace(
                                    /[^\x20-\x7E]+/g,
                                    ''
                                ))
                                // }
                            }}

                        />
                    </Col>
                </Row>
            </Container>
            <Container
                fluid
                // className='box'
                style={{
                    paddingTop: '20px',
                    paddingBottom: '15px',
                    marginBottom: '15px',
                }}>
                <Row>
                </Row>
                <Row>
                    <Row> <span className='question' style={{ paddingBottom: '1em' }}>Max label:</span></Row>
                    <Col>

                        <input
                            required
                            className='form-control'
                            value={endLabel}
                            onChange={(e) => {
                                // setError('');

                                //prevent only 'space' answers and clear out emojis (.replace())
                                // if (e.target.value.trim() !== '') {
                                setEndLabel(e.target.value.replace(
                                    /[^\x20-\x7E]+/g,
                                    ''
                                ))
                                // }
                            }}

                        />
                    </Col>
                </Row>
            </Container>

            <div style={{ display: 'flex' }}>
                <Container
                    fluid
                // className='box'
                >
                    <Row>
                        <Col>
                            <span className='question'>Reverse Coded:</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>

                            <input id="toggle-on-reverse-coded" className="toggle toggle-left" name="siwtch-reverse-coded" value="false" type="radio" defaultChecked onChange={toggleSwitch} />
                            <label htmlFor="toggle-on-reverse-coded" className="btn">No</label>
                            <input id="toggle-off-reverse-coded" className="toggle toggle-right" name="siwtch-reverse-coded" value="true" type="radio" onChange={toggleSwitch} />
                            <label htmlFor="toggle-off-reverse-coded" className="btn">Yes</label>
                        </Col>
                    </Row>
                </Container>
                <Container
                    fluid
                // className='box'
                >
                    <Row>
                        <Col>
                            <span className='question'>Provide 'Not Applicable (N/A)' option:</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>

                            <input id="toggle-on-na" className="toggle toggle-left" name="siwtch-na" value="false" type="radio" defaultChecked onChange={toggleNA} />
                            <label htmlFor="toggle-on-na" className="btn">No</label>
                            <input id="toggle-off-na" className="toggle toggle-right" name="siwtch-na" value="true" type="radio" onChange={toggleNA} />
                            <label htmlFor="toggle-off-na" className="btn">Yes</label>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    );
};

export default LikertScaleQuestion;
