import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';


const LinkertQuestion = ({
	id,
	text,
	question_type,
	setError,
	setAnswers,
	answers,
	likert_scale,
	questionNumber,
	required_flag,
	onInputChanged,
	setValidatedQs
}) => {

	const radioName = uuidv4()

	const { min_value, max_value, scale, na_flag } = likert_scale

	// NA logic, used for checking NA value, not the flag itself
	const [notApplicable, setNotApplicable] = useState(false)
	// Value before NA was set, and if it wasn't then its null
	const [previousChosenValue, setPreviousChosenValue] = useState(null)

	const toggleNotApplicable = (e) => {
		setNotApplicable(prevNotApplicable => !prevNotApplicable)

		if (!notApplicable) {
			setAnswers({
				...answers,
				[`Question_${parseInt(id)}`]: {
					questionID: parseInt(id),
					questionType: question_type,
					response: e.target.value,
				},
			})
		} else {
			setAnswers({
				...answers,
				[`Question_${parseInt(id)}`]: {
					questionID: parseInt(id),
					questionType: question_type,
					response: previousChosenValue,
				},
			})
		}

		if (required_flag) {

			if (!notApplicable) {
				onInputChanged(e, id)
			} else {
				// validation is looking for event.target.value, so therefore need to construct it into an object that mimicks that structure
				onInputChanged({ target: { value: previousChosenValue === null ? '' : previousChosenValue } }, id)
			}

		}

	}


	return (
		<Container
			fluid
			className='forms-box'
		> {required_flag ? <Row>
			<Col>
				<div className='question-title'>
					<span className='question'>{questionNumber}. {text} </span>
					<span style={{ color: 'red', fontSize: '1.5em' }}>*</span>
				</div>
			</Col>
		</Row> :
			<Row>
				<Col>
					<div className='question-title'>
						<span className='question'>{questionNumber}. {text}</span>
					</div>
				</Col>
			</Row>
			}
			<Row>
				{required_flag ? <Col>
					<div

						className='linkert-container'
						onChange={(e) => {
							setError('');
							setPreviousChosenValue(e.target.value)
							setAnswers({
								...answers,
								[`Question_${parseInt(id)}`]: {
									questionID: parseInt(id),
									questionType: question_type,
									response: e.target.value,
								},
							});

							onInputChanged(e, id)
						}}>
						<span className='linkert-label-left'>
							{likert_scale.min_label}
						</span>
						{scale?.map(x => {

							if (scale.indexOf(x) === 0) {
								return (
									<div className='linkert-radio-container' key={x}>
										{x}
										<input
											key={x}
											required
											type='radio'
											value={`${x}`}
											name={radioName + id}
											className='linkert-radios'
											disabled={notApplicable ? true : false}
											style={notApplicable ? { opacity: '0.5' } : null}
										/>
									</div>
								)
							}

							return (
								<div className='linkert-radio-container' key={x}>
									{x}
									<input
										key={x}
										type='radio'
										value={`${x}`}
										name={radioName + id}
										className='linkert-radios'
										disabled={notApplicable ? true : false}
										style={notApplicable ? { opacity: '0.5' } : null}
									/>
								</div>
							)

						})}


						<span className='linkert-label-right'>
							{likert_scale.max_label}
						</span>
					</div>


					{na_flag ? <div className='linkert-na-checkbox-container'>
						<input

							type='checkbox'
							value={'na'}
							onChange={toggleNotApplicable}
							name={radioName + id}
						/>

						<span>This question is not applicable to me.</span>
					</div> : null}
				</Col>
					:
					<Col>

						<div
							className='linkert-container'
							onChange={(e) => {
								setError('');
								setPreviousChosenValue(e.target.value)
								setAnswers({
									...answers,
									[`Question_${parseInt(id)}`]: {
										questionID: parseInt(id),
										questionType: question_type,
										response: e.target.value,
									},
								});
							}}>
							<span className='linkert-label-left'>
								{likert_scale.min_label}
							</span>

							{scale?.map(x => {

								return (
									<div className='linkert-radio-container' key={x}>
										{x}
										<input
											key={x}
											type='radio'
											value={`${x}`}
											name={radioName + id}
											disabled={notApplicable ? true : false}
											style={notApplicable ? { opacity: '0.5' } : null}
										/>
									</div>
								)

							})}
							<span className='linkert-label-right'>
								{likert_scale.max_label}
							</span>
						</div>

						{na_flag ?
							<div className='linkert-na-checkbox-container'>
								<input
									type='checkbox'
									value={'na'}
									onChange={toggleNotApplicable}
									name={radioName + id}
								/>
								<span>This question is not applicable to me.</span>
							</div>
							: null}
					</Col>}

			</Row>
		</Container>
	);
};

export default LinkertQuestion;
