import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import axios from "../../misc/modifiedAxios";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory, useLocation } from 'react-router-dom'; //Use history and location is used to change the url path of the app
import ErrorNotice from '../../misc/ErrorNotice';
import SuccessMessageAlert from '../../misc/successMessageAlert';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
import './Login.scss';
// import apiCall from '../../helpers/apiCall';

const LoginV4 = () => {
    // const [email, setEmail] = useState(); //Setting state for each variable
    // const [password, setPassword] = useState();
    const [error, setError] = useState();

    // member variables
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const location = useLocation();




    const submit = async (e) => {
        e.preventDefault(); //Prevents component refresh after function runs


        if (email !== "" && password !== "") {

            try {

                const data = {
                    identifier: email,
                    password: password
                }

                localStorage.removeItem('jwt')
                localStorage.removeItem('organisationId')


                setisLoading(true)
                const response = await axios.post("auth/local", data).catch(

                    (error) => {
                        if (error.response.status === 400) {

                            alert("Please ensure your email and password are correct.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                            return
                        }

                        if (error.response.status === 403) {

                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                        // <ErrorCatch error={error} />
                    })

                const jwt = response?.data?.jwt;

                console.log('jwt', jwt)

                const user = response?.data?.user;

                // Save the jwt in localstorage
                localStorage.setItem("jwt", jwt);

                dispatch({ type: "SET_USER", payload: user });


                //___________________________________ v4 user me Method ______________________________


                let ogsResponseData

                if (jwt) {
                    ogsResponseData = await axios.get("/users/me").catch(

                        (error) => {
                            if (error.response.status === 400) {

                                alert("Please ensure your email and password are correct.")
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')
                                return
                            }

                            if (error.response.status === 403) {
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')
                            }

                            if (error.response.status === 401) {

                                alert("Session has expired, please re-login.")
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')

                            }
                            // <ErrorCatch error={error} />
                        })

                }

                console.log('ogsResponseData', ogsResponseData)

                const organisationId = ogsResponseData?.data?.active_og ? ogsResponseData?.data?.active_og : ogsResponseData?.data?.ogs[0]?.id;
                // const organisationId = 1

                const defaultOrgId = response?.data?.default_og;

                // Save the Organisation details in localstorage
                localStorage.setItem("organisationId", organisationId);


                dispatch({ type: "SET_ORGANISATION", payload: organisationId });
                dispatch({ type: "SET_ALL_ORGANISATIONS", payload: ogsResponseData?.data?.ogs })



                if (defaultOrgId) {

                    const defaultOrg = ogsResponseData.ogs.filter(x => {
                        if (x.id === defaultOrgId) {
                            return x
                        }
                    })


                    dispatch({ type: "SET_DEFAULT_ORGANISATION", payload: defaultOrg[0] });

                }



                setisLoading(false)
                // Redirect to Home page.


                if (user && organisationId && jwt) {
                    history.push("/question-manager")
                }

            } catch (err) {
                console.log(err)


            }
        }

    };

    const forgotPassword = () => {
        history.push('/reset-password'); //Forces app to /reset-password path
    };


    useEffect(() => {

        const jwt = localStorage.getItem('jwt')
        const organisationId = localStorage.getItem('organisationId')
        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (jwt && organisationId) {
            history.push("/question-manager")
        }


    }, [history]);






    return (
        <div className='loginContainer'>
            {/* Checks the current location for state, if true then it returns the success message component else nothing */}
            {/* Passes userData to header component */}
            {/* {location.state ? (
                <SuccessMessageAlert message={location.state.msg} />
            ) : (
                <></>
            )} */}
            <div className='auth-inner'>

                {isLoading ? (
                    <div className='spinner'>
                        <SpinnerCircularFixed
                            size={60}
                            thickness={140}
                            speed={120}
                            color='var(--spinner-color)'
                            secondaryColor='rgb(240, 240, 240)
                                            '
                        />
                        <div className='spinner-text'>
                            <span>Signing in...</span>
                        </div>
                    </div>
                ) : (
                    <form className='form' onSubmit={submit}>
                        <h3>Login</h3>
                        {/* If error exists in state then pass it to the ErrorNotice component */}
                        {error && (
                            <ErrorNotice
                                message={error}
                                clearError={() => setError(undefined)}
                            />
                        )}
                        <div className='form-group'>
                            <label htmlFor='login-email'>Email</label>
                            <input
                                id='login-email'
                                type='email'
                                className='form-control'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {/* on input change set email to value of input */}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='login-password'>Password</label>
                            <input
                                id='login-password'
                                type='password'
                                className='form-control'
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <span id='tos'>
                            By logging in, you agree to the Privacy Policy and Terms
                            of Service.
                        </span>
                        <span id='buttonSpan'>
                            <button
                                type='submit'
                                className='submit-btn btn btn-block'>
                                Login
                            </button>
                            {/* <p className='forgot-password text-right'>
                            <a
                                style={{ cursor: 'pointer' }}
                                onClick={forgotPassword}
                                href='/reset-password'>
                                Forgot password?
                            </a>
                        </p> */}
                        </span>
                    </form>
                )}
            </div>

        </div>
    );
};

export default LoginV4;
/* Exports Login for use in other components */
