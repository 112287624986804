import React from 'react';
import SenseLogoNavy from '../assets/SenseLogoNavy.svg';
import '../pages/Form/Form.scss'

const ErrorPageNotFound = ({ message }) => {
    return (
        <div className='container-error-page-not-found'
        >
            <img src={SenseLogoNavy} className="sense-logo" alt='sense_logo' />
            <span>{message}</span>
        </div>
    );
};

export default ErrorPageNotFound;
