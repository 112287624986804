import { removeOrganisation, updateOrganisation } from './user.utils';

const INITIAL_STATE = {
	user: null,
	organisationId: null,
	organisations: [],
	defaultOrg: null,
	selectedOrg: null
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_USER':
			return {
				...state,
				user: action.payload,
			};
		case 'SET_ORGANISATION':
			return {
				...state,
				organisationId: action.payload,
			};
		case 'SELECT_ORGANISATION': //used in Organizations table
			return {
				...state,
				selectedOrg: action.payload,
			};
		case 'SET_DEFAULT_ORGANISATION':
			return {
				...state,
				defaultOrg: action.payload,
			};
		case 'ADD_NEW_ORGANISATION':
			return {
				...state,
				user: { ...state.user, organisations: [action.payload, ...state.user.organisations,] },
			};
		case 'DELETE_ORGANISATION':
			return {
				...state,
				user: { ...state.user, organisations: removeOrganisation(state.user.organisations, action.payload) },
				organisationId: state.defaultOrg.id
			};
		case 'UPDATE_ORGANISATION':
			return {
				...state,
				user: { ...state.user, organisations: updateOrganisation(state.user.organisations, action.payload) },
				selectedOrg: action.payload
			};
		//STRAPI V4
		case 'SET_ALL_ORGANISATIONS':
			return {
				...state,
				organisations: action.payload
			};

		default:
			return state;

	}
};

export default userReducer;
