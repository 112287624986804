import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

const ErrorPopUp = ({ showErrorModal, setShowErrorModal, setError, error }) => {
  const { message, timeStamp, sessionID, status } = error;

  const history = useHistory();

  return (
    <Modal
      show={showErrorModal}
      backdrop="static"
      onHide={() => {
        setShowErrorModal(false);
      }}
    >
      <Modal.Body>
        <div
          className="error-notice"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span>{message}</span>
          <span>{` Error code: ${status} `}</span>
          <span>{` Session ID: ${sessionID} `}</span>
          <span>{timeStamp}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="no-variant"
          className="clear-button clear-button--secondary"
          onClick={() => {
            setShowErrorModal(false);
            setError({ message: "", status: "", sessionID: "", timeStamp: "" });
            if (localStorage.getItem("jwt") !== null) {
              localStorage.removeItem("jwt");
              localStorage.removeItem("organisationId");
              history.push("/");
              return;
            }
          }}
        >
          <span>Close</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorPopUp;
