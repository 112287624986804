import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
// import Axios from 'axios';
import axios from "../../../misc/modifiedAxios";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './create-survey-modal.styles.scss';
import "../../../_vars.scss";
import { Modal, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import { useHistory } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import dndIcon from '../../../assets/dndicon.svg'
import addIcon from '../../../assets/addcirclebutton.svg'

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Section from './Section/section-component'
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/



const CreateSurveyModal = ({ showModal, setShowModal }) => {

    const [key, setKey] = useState('questions'); //Tab initial view
    const [isLoading, setisLoading] = useState(false);

    const questions = useSelector((state) => state.question.questions);



    const organisationId = useSelector((state) => state.user.organisationId);

    const [masterData, setMasterData] = useState({
        allAvailableQuestions: [],
        sections: [
            {
                sectionId: 1,
                section_name: '',
                section_description: '',
                chosenQuestions: [],
                requiredQuestions: []
            }
        ]
    })
    const [firstInitiation, setFirstInitiation] = useState(true)

    const [uploadedFooterImageID, setUploadedFooterImageID] = useState(null)

    console.log('masterData', masterData)

    //SECTIONS DND ======

    function handleOnDragEnd(result) {

        if (!result.destination) return;

        const items = Array.from(masterData?.sections);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let newMasterData = { ...masterData, sections: items }

        // updateChoices(items)
        setMasterData(newMasterData)
    }


    //SECTIONS DND END ___________________



    // Questions Modal
    const history = useHistory()

    const dispatch = useDispatch()



    const surveyStatusOptions = [
        {
            display: 'Live',
            value: 'live'
        },
        {
            display: 'Closed',
            value: 'closed'
        },
        {
            display: 'Draft',
            value: 'draft'
        }
    ]
    const [surveyStatus, setSuveryStatus] = useState('live')


    //Chosen Multiselect Questions local state
    const [selected, setSelected] = useState([]);

    // for POST object, only question ids
    const data = selected.map(question => question.value)
    //______________________________

    //name, description not used to prevent input delays
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const descriptionRef = useRef()
    const nameRef = useRef()

    const submitConfirmMessageRef = useRef()

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())


    const [createdSurvey, setCreatedSurvey] = useState(null)


    const submit = async (e) => {
        e.preventDefault();


        try {

            setisLoading(true)


            const newSurvey = {
                // IF Survey status is Live, the chosen question's should be locked from edditing and the IDs are attached in this list:
                questionsList: surveyStatus === 'live' ?

                    masterData.sections.map(section => {

                        return section.chosenQuestions.map(question => {
                            return question.id
                        })
                    }).flat()

                    : [],


                data: {
                    url: window.location.origin,
                    og: organisationId,
                    survey_desc: descriptionRef.current.value.replace(/[^\x20-\x7E\n]+/g, ''),
                    start_date: startDate.toISOString(),
                    end_date: endDate.toISOString(),
                    survey_name: nameRef.current.value.replace(/[^\x20-\x7E\n]+/g, ''),
                    submit_confirm_message: submitConfirmMessageRef.current.value.replace(/[^\x20-\x7E\n]+/g, ''),
                    footer_image: uploadedFooterImageID,
                    status: surveyStatus,
                    sections: masterData.sections.map(section => {

                        return {
                            order: masterData.sections.indexOf(section),
                            section_name: section?.section_name,
                            section_desc: section?.section_description.replace(
                                /[^\x20-\x7E\n]+/g,///[^\x20-\x7E]+/g,
                                ''
                            ),
                            section_questions: section.chosenQuestions.map(question => {
                                return {

                                    order: section.chosenQuestions.indexOf(question) + 1,
                                    question: question.id,
                                    required_flag: question?.required === true ? true : false
                                }
                            })



                        }

                    })
                }
            };

            const response = await axios.post(
                `/surveys/`,
                newSurvey
            ).catch(
                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                })

            setName('')
            setDescription('')
            setSelected([])
            setStartDate(new Date())
            setEndDate(new Date())


            const { id, survey_desc, survey_name, questions, start_date, end_date, url, sid, survey_responses, status, sections } = response.data

            setCreatedSurvey({
                id,
                survey_desc,
                survey_name,
                questions,
                start_date,
                end_date,
                url,
                status,
                sid,
                survey_responses,
                sections
            })

            setisLoading(false)
            setShowModal(false);
            setName('')
            setDescription('')
            setSelected([])
            setStartDate(new Date())
            setEndDate(new Date())

            setSuveryStatus('live')

        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            // window.alert('Internal error, please contact admin');
        }
    };


    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (createdSurvey !== null) {
            const setCreatedSurvey = () => {
                dispatch({
                    type: 'ADD_NEW_SURVEY',
                    payload: createdSurvey
                })
            }

            setCreatedSurvey();
        }

    }, [dispatch, createdSurvey]);



    useEffect(() => {


        // ###################          LOGIC FOR STRAPI V3        ##########################
        // const formatedQuestions = questions.map(x => {

        //     if (x.theme && x.parent_theme) {
        //         return { ...x, parent_theme: x.parent_theme.theme_name, theme: x.theme.theme_name }
        //     }
        //     if (x.theme && !x.parent_theme) {
        //         return { ...x, parent_theme: x.theme.theme_name, theme: null }
        //     }
        //     return x

        // })
        if (firstInitiation) {


            setMasterData({
                allAvailableQuestions: questions,//formatedQuestions,
                sections: [
                    {
                        sectionId: 1,
                        section_name: '',
                        section_description: '',
                        chosenQuestions: [],
                        requiredQuestions: []
                    }
                ]
            })

        } else {

            setMasterData({
                allAvailableQuestions: questions,//formatedQuestions,
                sections: masterData.sections
            })

        }

    }, [questions, showModal, firstInitiation]);



    const uploadImage = async (e) => {
        e.preventDefault();


        try {
            const formData = new FormData()

            formData.append('files', e.target.files[0])
            formData.append('path', "1")

            const response = await axios({
                method: 'post',
                url: '/upload',
                data: formData
            })

            setUploadedFooterImageID(response.data[0]?.id)

        } catch (err) {
            console.log(err)
        }
    }


    return (
        <Modal
            show={showModal}
            backdrop="static"
            dialogClassName="main-modal"

            onHide={() => {
                setShowModal(false);
                setName('')
                setDescription('')
                setSelected([])
                setStartDate(new Date())
                setEndDate(new Date())

                setSuveryStatus('live')
            }}>
            <Modal.Header style={{ backgroundColor: '#F4F4F4' }} closeButton>

                <Modal.Title>Create Survey</Modal.Title>
            </Modal.Header>
            {/* onSubmit={submit} */}
            <form onSubmit={submit} >
                <Modal.Body style={{ backgroundColor: '#F4F4F4' }}>


                    {isLoading ? (
                        <div className='spinner'>
                            <SpinnerCircularFixed
                                size={60}
                                thickness={140}
                                speed={120}
                                color='var(--spinner-color)'
                                secondaryColor='rgb(240, 240, 240)
                                            '
                            />
                            <div className='spinner-text'>
                                <span>Creating Survey...</span>
                            </div>
                        </div>
                    ) : (

                        <Tabs

                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3">

                            <Tab eventKey="questions" title="Questions" >
                                <Container
                                    fluid

                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}>
                                    <Row>
                                        <Col>
                                            <span className='question'>Name *</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <input
                                                required
                                                // value={name}
                                                className='form-control section-inputs'
                                                maxLength='250'
                                                placeholder='max 250 characters'
                                                // onChange={(e) => {

                                                //     setName(e.target.value.replace(
                                                //         /[^\x20-\x7E]+/g,
                                                //         ''
                                                //     ))
                                                //     // }
                                                // }}
                                                ref={nameRef}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                                <Container
                                    fluid

                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}>

                                    <Row>
                                        <Col>
                                            <span className='question'>Description *</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <div className="grow-wrap">
                                                <textarea
                                                    required
                                                    // value={description}
                                                    className='form-control section-inputs'
                                                    maxLength='1000'
                                                    placeholder='max 1000 characters'
                                                    ref={descriptionRef}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>

                                <DragDropContext onDragEnd={handleOnDragEnd} >
                                    <Droppable droppableId="sections" >
                                        {(provided) => (
                                            <div className="sections" style={{ marginLeft: '1em' }} {...provided.droppableProps} ref={provided.innerRef} >
                                                {masterData?.sections?.map((element, index) => {



                                                    return (
                                                        <Draggable key={index.toString()} draggableId={index.toString()} index={index} >
                                                            {(provided) => (
                                                                // <div className="form-inline" ref={provided.innerRef} {...provided.draggableProps}  >

                                                                <div ref={provided.innerRef} {...provided.draggableProps}  >
                                                                    <div className='section-container'>
                                                                        <div {...provided.dragHandleProps}>
                                                                            <img className="grip-section-button" src={dndIcon} alt='dnd_button' />
                                                                        </div>

                                                                        <Section
                                                                            key={element.sectionId}
                                                                            sectionId={element.sectionId}
                                                                            masterData={masterData}
                                                                            setMasterData={setMasterData}
                                                                            setFirstInitiation={setFirstInitiation}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            )}
                                                        </Draggable>

                                                    )
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}

                                    </Droppable>
                                </DragDropContext>

                                <Container
                                    fluid
                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    <div className="add-question-button-container" >
                                        <img style={{ height: '1.5em' }} src={addIcon} alt='add_button' onClick={() => {
                                            setMasterData(

                                                // [...masterData, {
                                                //     sectionId: masterData.length + 1,
                                                //     chosenQuestions: [],
                                                //     requiredQuestions: []
                                                // }]
                                                {
                                                    ...masterData,
                                                    sections: [...masterData?.sections, {
                                                        sectionId: masterData?.sections.length + 1,
                                                        section_name: '',
                                                        section_description: '',
                                                        chosenQuestions: [],
                                                        requiredQuestions: []
                                                    }]
                                                }

                                            )
                                        }} />

                                        <span className="add-button-text">Add Section</span>
                                    </div>
                                </Container>


                            </Tab>

                            <Tab eventKey="appearance" title="Appearance">
                                <Container
                                    fluid

                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}>

                                    <Row>
                                        <Col>
                                            <span className='question'>Submit Confirm Message:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <div className="grow-wrap">
                                                <textarea
                                                    // required
                                                    // defaultValue={submitConfirmMessageRef}
                                                    className='form-control section-inputs'
                                                    maxLength='1000'
                                                    placeholder='max 1000 characters'
                                                    ref={submitConfirmMessageRef}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>

                                <Container
                                    fluid

                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}>

                                    <Row>
                                        <Col>
                                            <span className='question'>Icons:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <form >
                                                <input
                                                    type="file"
                                                    onChange={(e) => uploadImage(e)}
                                                />
                                            </form>
                                        </Col>
                                    </Row>
                                </Container>



                            </Tab>

                            <Tab eventKey="settings" title="Settings" >
                                <Container
                                    fluid

                                >

                                    {/* <Row>
                                        <Col>
                                            <span className='question'>Start Date:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}
                                                timeInputLabel="Time:"
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                showTimeInput
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                                <Container
                                    fluid
                                    style={{
                                        paddingBottom: '15px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            <span className='question'>Close Date:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}
                                                timeInputLabel="Time:"
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                showTimeInput
                                            />
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col style={{ marginTop: '2em' }}>
                                            <span className='question'>Survey Status:</span>

                                            <select
                                                value={surveyStatus}
                                                className='form-control survey-status-dropdown'
                                                onChange={e => {
                                                    setSuveryStatus(e.target.value)
                                                }}
                                            >
                                                {surveyStatusOptions.map(x => (
                                                    <option key={surveyStatusOptions.indexOf(x)} value={x.value}>
                                                        {x.display}
                                                    </option>
                                                ))}
                                            </select>


                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                        </Tabs>

                    )}

                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#F4F4F4' }}>

                    {/* NEW CANCEL BUTTON */}

                    <div
                        className="clear-button clear-button--secondary"
                        onClick={() => {
                            setShowModal(false);
                            setName('')
                            setDescription('')
                            setSelected([])
                            setStartDate(new Date())
                            setEndDate(new Date())

                            setSuveryStatus('live')
                            setMasterData({
                                allAvailableQuestions: [],
                                sections: [
                                    {
                                        sectionId: 1,
                                        section_name: '',
                                        section_description: '',
                                        chosenQuestions: [],
                                        requiredQuestions: []
                                    }
                                ]
                            })
                        }} >
                        <span>Cancel</span> </div>

                    <input
                        type='submit'
                        value='Create Survey'
                        className='card-button'
                    />
                </Modal.Footer>
            </form>
        </Modal >
    );
};

export default CreateSurveyModal;




