import React from 'react'

export const ReverseDropdown = ({
    column
}) => {
    // Calculate the options for filtering
    // using the preFilteredRows

    const { filterValue, setFilter, preFilteredRows, id } = column


    //TRIED PASSING ID DIDNT WORK, need something else that filters

    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {


            // if (row.original.objective !== null) {
            options.add(row.original.objective)
            // }

        })
        return [...options.values()]
    }, [preFilteredRows])



    const displayOptions = options.map(option => {

        if (option) {
            return {
                label: 'Objective',
                value: true
            }

        }

        // if (option === null) {
        //     return {
        //         label: 'Not Specified',
        //         value: null
        //     }
        // }

        if (!option) {
            return {
                label: 'Subjective',
                value: false
            }
        }


    })


    return (
        <select
            className='formControl'
            value={filterValue}
            onChange={e => {



                if (e.target.value === 'true') {
                    setFilter(true || undefined)
                }

                if (e.target.value === 'false') {
                    setFilter(false)
                }

                // if (e.target.value === 'null') {
                //     setFilter(null)
                // }

                if (e.target.value === "") {
                    setFilter(e.target.value || undefined)
                }


            }}
        >
            <option value="">All</option>
            {displayOptions.map((option, i) => (

                <option key={i} value={option?.value}>
                    {option?.label}
                </option>
            ))}
        </select>
    )
}




