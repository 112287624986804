import React from 'react'
import './question-table.styles.scss';

export const ColumnFilter = ({ column }) => {

    const { filterValue, setFilter } = column

    const handleFilter = (e) => {

        setFilter(e.target.value)

    }

    return (
        <span>
            <input value={filterValue || ''} onChange={handleFilter} className='formControl' />

        </span>
    )
}