export const updateCohortDetails = (cohorts, editedCohort) => {
	const existingItem = cohorts.find(
		(cohort) => cohort.id === editedCohort.id
	);

	if (existingItem) {
		return cohorts.map((cohort) =>
			cohort.id === editedCohort.id
				? { ...cohort, cohort_name: editedCohort.cohort_name, cohort_desc: editedCohort.cohort_desc}
				: cohort
		);
	}

	return [...cohorts];
};


export const removeCohort = (cohorts, removedCohort) => {
	const existingCohort = cohorts.find(
		(theme) => theme.id === removedCohort.id
	);

	return cohorts.filter((theme) =>
		theme.id !== existingCohort.id
	);
};