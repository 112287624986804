import React, { useState, useEffect } from 'react';
// import Axios from 'axios';
import axios from "../../../misc/modifiedAxios";
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';


import "../../../_vars.scss"


const CreateCohortModal = ({ showModal, setShowModal }) => {


    //---useSelector get client Id
    //POST object

    const history = useHistory()
    const dispatch = useDispatch()
    const [newCohortSelection, setNewCohortSelection] = useState(null)

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const clientId = useSelector((state) => state.client.clientId);

    const [isEnabled, setEnabled] = useState(true)


    const submit = async (e) => {
        e.preventDefault();

        setEnabled(false)

        try {
            const newCohort = {
                cohort_name: name,
                cohort_desc: description,
                client_id: clientId
            };


            const response = await axios.post(
                `/cohorts`,
                newCohort
            ).catch(
                // (error) => {
                // if (error.response.status === 403 || error.response.status === 401) {
                //     localStorage.removeItem('jwt')
                //     localStorage.removeItem('organisationId')
                //    history.push('/')
                // }
                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                }
            );

            setEnabled(true)

            const { cohort_name, cohort_desc, created_at, id } = response.data

            setNewCohortSelection({
                id,
                cohort_name,
                cohort_desc,
                created_at
            })

            setShowModal(false);

        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }
    };

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (newCohortSelection !== null) {
            const setCohortsFromRequest = () => {
                dispatch({
                    type: 'ADD_NEW_COHORT',
                    payload: newCohortSelection
                })
            }

            setCohortsFromRequest();
        }

    }, [dispatch, newCohortSelection]);



    return (
        <Modal
            show={showModal}
            backdrop="static"
            onHide={() => {
                setShowModal(false);
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Create Cohort</Modal.Title>
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body >


                    <Container
                        fluid
                        // className='box'
                        style={{
                            paddingTop: '20px',
                            paddingBottom: '15px',
                            marginBottom: '15px',
                        }}>
                        <Row>
                            <Col>
                                <span className='question'>Name:</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <input
                                    required
                                    className='form-control'
                                    onChange={(e) => {
                                        // setError('');

                                        //prevent only 'space' answers and clear out emojis (.replace())
                                        if (e.target.value.trim() !== '') {
                                            setName(e.target.value.replace(
                                                /[^\x20-\x7E]+/g,
                                                ''
                                            ))
                                        }
                                    }}

                                />
                            </Col>
                        </Row>
                    </Container>
                    <Container
                        fluid
                        // className='box'
                        style={{
                            paddingTop: '20px',
                            paddingBottom: '15px',
                            marginBottom: '15px',
                        }}>
                        <Row>
                            <Col>
                                <span className='question'>Description:</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <textarea
                                    required
                                    className='form-control'
                                    onChange={(e) => {
                                        // setError('');

                                        //prevent only 'space' answers and clear out emojis (.replace())
                                        if (e.target.value.trim() !== '') {
                                            setDescription(e.target.value.replace(
                                                /[^\x20-\x7E]+/g,
                                                ''
                                            ))
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>



                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='no-variant'
                        className='modal-cancel-button'
                        onClick={() => {
                            setShowModal(false);
                        }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setShowModal(false);
                        }}
                        disabled={!isEnabled}
                        type='submit'
                        className='modal-create-button'
                        variant='no-variant-p'>
                        Create Cohort
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateCohortModal;
