import React, { useState, useEffect } from 'react';
import axios from "../../../misc/modifiedAxios";
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import ErrorCatch from '../../Shared/Error/error-catch';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/


import "../../../_vars.scss"

const CreateSubthemeModal = ({ showModal, setShowModal, themeId }) => {


    const history = useHistory();
    //---useSelector get client Id
    //POST object

    const dispatch = useDispatch()

    const selectedTheme = useSelector((state) => state.theme.selectedTheme);

    const [updatedThemeSelection, setUpdatedThemeSelection] = useState(null)
    const [isLoading, setisLoading] = useState(false);
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [summary, setSummary] = useState('')


    const [isEnabled, setEnabled] = useState(true)




    const submit = async (e) => {
        e.preventDefault();
        setEnabled(false)



        try {
            // const newSubtheme = {
            //     insertSubtheme: {

            //         subtheme_name: name,
            //         subtheme_desc: description,
            //         subtheme_summary: summary,
            //         client_id: clientId
            //     }
            // };


            setisLoading(true)
            const newTheme = {

                data: {
                    theme_name: name,
                    theme_desc: description,
                    theme_details: summary,
                    parent_theme: themeId,
                    sys_default: false,
                    parent_theme_flag: false
                }

            };

            const response = await axios.post(
                `/themes/`,
                newTheme
            ).catch(
                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                })

            setEnabled(true)




            const { theme_name, theme_desc, theme_summary, id, parent_theme, child_themes } = response.data


            // setUpdatedThemeSelection({
            //     id,
            //     theme_name,
            //     theme_desc,
            //     theme_summary,
            //     child_themes,
            //     parent_theme
            // })


            let formattedChiledThemes = child_themes.filter(x => x.parent_theme_flag !== true)

            // // NOTE: Parent Theme could already have questions, so need to spread in parents details

            setUpdatedThemeSelection({
                ...selectedTheme,
                child_themes: formattedChiledThemes,
                type: 'regular'

            })

            setName('')
            setDescription('')
            setSummary('')
            setisLoading(false)
            setShowModal(false);

        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }
    };

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (updatedThemeSelection !== null) {
            const updateThemeDetails = () => {
                dispatch({
                    type: 'UPDATE_THEME_DETAILS',
                    payload: updatedThemeSelection
                })
            }

            updateThemeDetails();
        }

    }, [dispatch, updatedThemeSelection]);



    return (
        <Modal
            size='lg'
            show={showModal}
            backdrop="static"
            onHide={() => {
                setShowModal(false);
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Create Subtheme</Modal.Title>
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body >
                    {isLoading ? (
                        <div className='spinner'>
                            <SpinnerCircularFixed
                                size={60}
                                thickness={140}
                                speed={120}
                                color='var(--spinner-color)'
                                secondaryColor='rgb(240, 240, 240)
                                            '
                            />
                            <div className='spinner-text'>
                                <span>Creating Subheme...</span>
                            </div>
                        </div>
                    ) : (

                        <>
                            <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <span className='question'>Name:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <input
                                            required
                                            className='form-control section-inputs'
                                            maxLength='50'
                                            placeholder='max 50 characters'
                                            onChange={(e) => {
                                                // setError('');

                                                //prevent only 'space' answers and clear out emojis (.replace())
                                                // if (e.target.value.trim() !== '') {

                                                setName(e.target.value.replace(
                                                    /[^\x20-\x7E]+/g,
                                                    ''
                                                ))
                                                // }
                                            }}

                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <span className='question'>Description:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea
                                            // required
                                            className='form-control section-inputs'
                                            maxLength='300'
                                            placeholder='max 300 characters'
                                            onChange={(e) => {
                                                // setError('');

                                                //prevent only 'space' answers and clear out emojis (.replace())
                                                // if (e.target.value.trim() !== '') {

                                                setDescription(e.target.value.replace(
                                                    /[^\x20-\x7E]+/g,
                                                    ''
                                                ))
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Container
                                fluid
                                // className='box'
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '15px',
                                    marginBottom: '15px',
                                }}>
                                <Row>
                                    <Col>
                                        <span className='question'>Summary:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea
                                            // required
                                            className='form-control section-inputs'
                                            maxLength='300'
                                            placeholder='max 300 characters'
                                            onChange={(e) => {
                                                // setError('');

                                                //prevent only 'space' answers and clear out emojis (.replace())
                                                // if (e.target.value.trim() !== '') {

                                                setSummary(e.target.value.replace(
                                                    /[^\x20-\x7E]+/g,
                                                    ''
                                                ))
                                                // }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </>)}


                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="clear-button clear-button--secondary"
                        onClick={() => {
                            setName('')
                            setDescription('')
                            setSummary('')
                            setShowModal(false);
                        }} >
                        <span>Cancel</span>
                    </div>


                    {name ? <input
                        type='submit'
                        disabled={false}
                        value='Create Theme'
                        className='card-button'
                        onClick={() => {
                            setShowModal(false);

                        }}
                    /> :
                        <input
                            type='submit'
                            disabled={true}
                            style={{ opacity: '65%' }}
                            value='Create Theme'
                            className='card-button'
                        />
                    }


                    {/* <Button
                        variant='no-variant'
                        className='modal-cancel-button'
                        onClick={() => {
                            setName('')
                            setDescription('')
                            setSummary('')
                            setShowModal(false);
                        }}>
                        Cancel
                    </Button>
                    {name ? <Button
                        onClick={() => {
                            setShowModal(false);

                        }}
                        disabled={false}
                        type='submit'
                        className='modal-create-button'
                        variant='no-variant-p'>
                        Create Subtheme
                    </Button> :
                        <Button
                            onClick={() => {
                                setShowModal(false);

                            }}
                            disabled={true}
                            type='submit'
                            className='modal-create-button'
                            variant='no-variant-p'>
                            Create Subtheme
                        </Button>} */}
                </Modal.Footer>
            </form>
        </Modal >
    );
};

export default CreateSubthemeModal;
