import React, { useState, useEffect, useRef } from 'react';
import axios, { cancelToken } from "../../../misc/modifiedAxios";
// import Axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import './theme-questions-display.component.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/spinner';
import "../../../_vars.scss";
import addIcon from '../../../assets/addcirclebutton.svg'

// import UserItem from '../UserItem/useritem.component';
import EditThemeModal from '../../Theme-Components/EditThemeModal/edit-theme-modal-component';
import EditSubtheme from '../../Theme-Components/EditSubthemeModal/edit-subtheme-modal-component'
import ThemeQuestionTable from '../ThemeQuestionTable/theme-question-table.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateQuestionModal from '../../Question-Components/CreateQuestionModal/create-question-modal.component'
import EditQuestionModal from '../../Question-Components/EditQuestionModal/edit-question-modal.component';

const ThemeQuestionsDisplay = () => {

    const CancelToken = useRef(cancelToken)
    const cancel = useRef(undefined)

    const history = useHistory()
    const dispatch = useDispatch()

    const [showCreateModal, setCreateShowModal] = useState(false);
    const [showEditModal, setEditModal] = useState(false);

    const [isLoading, setisLoading] = useState(false);

    const selectedTheme = useSelector((state) => state.theme.selectedTheme);


    const [selectedThemeId, setSelectedThemeId] = useState(null);
    const [selectedThemeType, setSelectedThemeType] = useState(null);



    const selectedChildTheme = useSelector((state) => state.theme.selectedChildTheme);

    const organisationId = useSelector((state) => state.user.organisationId);

    const questionDetails = useSelector((state) => state.theme.selectedTheme?.questions);


    useEffect(() => {

        setSelectedThemeType(selectedTheme?.type)

        if (selectedTheme && selectedThemeType === 'regular') {
            setSelectedThemeId(selectedTheme.id)
        }

        if (selectedTheme && selectedThemeType === 'all') {
            setSelectedThemeId('all')
        }

        if (selectedTheme && selectedThemeType === 'allUnassigned') {
            setSelectedThemeId('allUnassigned')
        }


    }, [selectedTheme, selectedThemeType])

    useEffect(() => {

        const getSelectedTheme = async () => {
            try {

                if (selectedThemeId) {

                    if (cancel.current !== undefined) {//uses .current property as this is how you manipulate the object in useRef
                        cancel.current(); // same as running cancel() because once it is defined it is assigned a function with new CancelToken (also has .current)

                    }
                    setisLoading(true)

                    const response = await axios.get(selectedThemeId === 'all' ?
                        `/questions?ogs=${organisationId}&theme=` : selectedThemeId === 'allUnassigned' ? `/questions?ogs=${organisationId}&theme=-1` : `/themes/${selectedThemeId}`,
                        {
                            cancelToken: new CancelToken.current(c => {
                                cancel.current = c; // c is the identifier of the request, ON THE FIRST request it DEFINES the cancel variable, meaning
                            }),
                        }
                    ).catch(

                        (error) => {
                            if (error.response.status === 403) {
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')
                            }

                            if (error.response.status === 401) {

                                alert("Session has expired, please re-login.")
                                localStorage.removeItem('jwt')
                                localStorage.removeItem('organisationId')
                                history.push('/')

                            }
                        }
                    );



                    if ((response && selectedThemeId === 'all')) {


                        // setQuestionDetails(response?.data)

                        dispatch({
                            type: 'SELECT_THEME',
                            payload: { ...selectedTheme, questions: response?.data }
                        })
                    }

                    if ((response && selectedThemeId === 'allUnassigned')) {
                        // setQuestionDetails(response?.data)

                        dispatch({
                            type: 'SELECT_THEME',
                            payload: { ...selectedTheme, questions: response?.data }
                        })
                    }

                    if (response && selectedThemeId && selectedThemeId !== 'all' && selectedThemeId !== 'allUnassigned') {



                        let allSpecificThemesQuestions = response?.data?.child_themes.map(x => {
                            return x.questions
                        }).flat(1)

                        // setQuestionDetails(allSpecificThemesQuestions)

                        console.log('WHAT IS HERE', response.data)

                        dispatch({
                            type: 'SELECT_THEME',
                            // NOTE [REVIEW], this creates a duplicate because child themes still got their questions, could leave selectedTheme instead,
                            // the one with metadata,  from initial themes
                            payload: { ...response.data, type: 'regular', questions: [...response.data.questions, ...allSpecificThemesQuestions] }

                        })
                    }

                }


                setisLoading(false)

            } catch (err) {
                console.log(err)

            }
        };

        getSelectedTheme();
    }, [history, selectedThemeId, selectedChildTheme]); //May need to take out selectedChildTheme, included because of 

    let theme_name
    let theme_desc
    let created_at
    if (selectedTheme) {

        theme_name = selectedTheme.theme_name
        theme_desc = selectedTheme.theme_desc
        created_at = selectedTheme.created_at
    }


    let child_theme_name = ''



    if (selectedChildTheme) {

        child_theme_name = selectedChildTheme.theme_name

    }


    useEffect(() => {

        // dispatch({
        //     type: 'SELECT_THEME',
        //     payload: null
        // })

        dispatch({
            type: 'SELECT_THEME',
            payload: {
                id: -2,
                theme_desc: "",
                theme_name: "All Questions",
                type: 'all'
            },
        })
        dispatch({
            type: 'SELECT_CHILD_THEME',
            payload: null
        })


    }, [dispatch, organisationId]);




    return (


        <div className='theme-question-display-container'>
            <h1 className='theme-question-header'>
                {child_theme_name ? `${child_theme_name} in ${theme_name}` : theme_name}

            </h1>
            {!selectedTheme ? <div className='theme-question-display-text'> No Theme Selected </div>

                :

                <div className='theme-question-display'>
                    <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>

                    </div>
                    <div className='buttons-container-theme-questions'>

                        <div className="add-question-button-container-question-display" >
                            <img style={{ height: '2em' }} src={addIcon} alt='add_button' onClick={() => {
                                setCreateShowModal(true);
                            }} />
                            <span className="add-button-text-question-display">Create New Question</span>
                        </div>

                    </div>


                    {/* TABLE AND CONDITIONAL MESSAGE/SPINNERS */}
                    {!isLoading ? (

                        (!questionDetails ? <div className='no-recipients'>Theme has no Questions</div> :

                            <ThemeQuestionTable questions={
                                selectedChildTheme ?
                                    questionDetails.filter(x => x.theme === selectedChildTheme.theme_name)
                                    :
                                    questionDetails

                            }
                                selectedThemeType={selectedThemeType}
                                selectedChildTheme={selectedChildTheme}
                                setEditModal={setEditModal}
                                selectedTheme={selectedTheme}
                                organisationId={organisationId}
                            />))
                        :
                        (<div className='spinner-container'><Spinner animation="border" />
                            <span>Refreshing Questions...</span></div>)
                    }

                </div>
            }
            <CreateQuestionModal
                setShowModal={setCreateShowModal}
                showModal={showCreateModal}
            />
            {showEditModal ? <EditQuestionModal
                setEditModal={setEditModal}
                showEditModal={showEditModal} /> : null}
        </div >
    );
};

export default ThemeQuestionsDisplay;




