import React from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'

// import makeData from './makeData'

const Styles = styled.div`
padding: 1rem;

overflow-x: auto;
table {
  width:95%;
  border-spacing: 0;
  // border: 1px solid black;
  }
 tr:nth-child(even){background-color: #f2f2f2;}
 tr:hover {background-color: #ddd;}

  th{
      border-bottom: 1px solid black;
  },

  td {
    margin: 0;
    padding: 0.5rem;
  //   border-bottom: 1px solid black;
  //   border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
`

function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

function ApiTable({ recipients }) {



    const recipientData = {
        initialData: recipients
    }


    // const columns = Object.keys(recipientData.initialData[0]).map((key, id) => {

    //     //id Cleaning here
    //     //key === 'first_name' Header has to be 'First Name'

    //     return {
    //         Header: key,
    //         accessor: key
    //     }
    // })

    const columns = Object.keys(recipientData.initialData[0])
        .filter((key, io) => key !== "id" && key !== "created_at" && key !== "updated_at")
        .map((key, id) => {

            if (key === 'first_name') {
                key = "First Name"

                return {
                    Header: key,
                    accessor: 'first_name'
                }
            }

            if (key === 'last_name') {
                key = "Last Name"

                return {
                    Header: key,
                    accessor: 'last_name'
                }
            }

            if (key === 'email') {
                key = "Email"

                return {
                    Header: key,
                    accessor: 'email'
                }
            }

            // if (key === 'organisation') {
            //     key = "Organisation"

            //     return {
            //         Header: key,
            //         accessor: 'organisation'
            //     }
            // }

            return {
                Header: key,
                accessor: key
            }
        })




    const columnsReady = React.useMemo(
        () => columns,
        [columns]
    )

    // const data = React.useMemo(() => makeData(150), [])

    return (
        <Styles>
            <Table columns={columnsReady} data={recipients} />
        </Styles>
    )
}

export default ApiTable
