import React, { useState, useEffect } from 'react';
// import Axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import axios from '../../../misc/modifiedAxios'
import ErrorCatch from '../../Shared/Error/error-catch';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import "../../../_vars.scss"


const EditCohortModal = ({ showModal, setShowModal }) => {
    // const clientId = useSelector((state) => state.client.clientId);


    //VERSION 2 

    //VERSION 1
    const history = useHistory()

    const [currentCohortDetails, setcurrentCohortDetails] = useState({
        cohort_name: '',
        cohort_desc: '',
    })

    const selectedCohortId = useSelector((state) => state.cohort.selectedCohort.id);
    const cohorts = useSelector((state) => state.cohort.cohorts);

    //VERSION 2 as soon as you edit it sets it
    const { cohort_name, cohort_desc } = currentCohortDetails

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        const loadCohortDetails = () => {

            if (selectedCohortId !== null) {
                return cohorts.map(cohort => cohort.id === selectedCohortId ?
                    setcurrentCohortDetails({
                        cohort_name: cohort.cohort_name,
                        cohort_desc: cohort.cohort_desc,
                    }) : cohort)
            }

        }

        loadCohortDetails();

    }, [cohorts, selectedCohortId]);


    //EDITED COHORT SELECTIOn OBJECT
    const dispatch = useDispatch()
    const [editedCohortSelection, setEditedCohortSelection] = useState(null)



    const submit = async (e) => {
        e.preventDefault();



        try {
            const editedCohort = {
                cohort_name: currentCohortDetails.cohort_name,
                cohort_desc: currentCohortDetails.cohort_desc,
                // client_id: clientId
            };

            const response = await axios.put(
                `/cohorts/${selectedCohortId}`,
                editedCohort
            ).catch(

                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                }
            );;


            const { cohort_name, cohort_desc, created_at, id } = response.data

            setEditedCohortSelection({
                id,
                cohort_name,
                cohort_desc,
                created_at
            })

            setShowModal(false);

        } catch (err) {
            console.log(err)
            // err && setError('Internal error, please contact survey admin');
            window.alert('Internal error, please contact admin');
        }
    };

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (editedCohortSelection !== null) {
            const setCohortsFromRequest = () => {
                dispatch({
                    type: 'UPDATE_COHORT_DETAILS',
                    payload: editedCohortSelection
                })
            }

            setCohortsFromRequest();
        }

    }, [dispatch, editedCohortSelection]);


    return (

        <Modal
            show={showModal}
            backdrop="static"
            onHide={() => {
                setShowModal(false);
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Cohort</Modal.Title>
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body >
                    <Container
                        fluid
                        // className='box'
                        style={{
                            paddingTop: '20px',
                            paddingBottom: '15px',
                            marginBottom: '15px',
                        }}>
                        <Row>
                            <Col>
                                <span className='question'>Name:</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <input
                                    required
                                    value={cohort_name}
                                    className='form-control'
                                    onChange={(e) => {
                                        //Maybe add to EDITED cohort local state
                                        // if (e.target.value.trim() !== '') {
                                        setcurrentCohortDetails({
                                            ...currentCohortDetails, cohort_name: e.target.value.replace(
                                                /[^\x20-\x7E]+/g,
                                                ''
                                            )
                                        })
                                        // }
                                    }}

                                />
                            </Col>
                        </Row>
                    </Container>
                    <Container
                        fluid
                        // className='box'
                        style={{
                            paddingTop: '20px',
                            paddingBottom: '15px',
                            marginBottom: '15px',
                        }}>
                        <Row>
                            <Col>
                                <span className='question'>Description:</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <textarea
                                    required
                                    value={cohort_desc}
                                    className='form-control'
                                    onChange={(e) => {

                                        // if (e.target.value.trim() !== '') {
                                        setcurrentCohortDetails({
                                            ...currentCohortDetails, cohort_desc: e.target.value.replace(
                                                /[^\x20-\x7E]+/g,
                                                ''
                                            )
                                        })
                                        // }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>



                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='no-variant'
                        className='modal-cancel-button'
                        onClick={() => {
                            setShowModal(false);
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        className='modal-create-button'
                        variant='no-variant-p'>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditCohortModal;
