export const updateSurveyDetails = (surveys, editedSurvey) => {




	const existingItem = surveys.find(
		(survey) => survey.id === editedSurvey.id
	);



	if (existingItem) {
		return surveys.map((survey) =>
			survey.id === editedSurvey.id
				?
				editedSurvey
				: survey
		);
	}

	return [...surveys]
};


export const removeSurvey = (surveys, removedSurvey) => {
	const existingSurvey = surveys.find(
		(survey) => survey.id === removedSurvey.id
	);

	return surveys.filter((survey) =>
		survey.id !== existingSurvey.id
	);
};