import React, { useState, useEffect } from 'react';
import './survey-url-modal.styles.scss'
import { Modal, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import { useSelector, useDispatch } from 'react-redux'
import { MultiSelect } from "react-multi-select-component";

import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';


const SurveyURLModal = ({ showUrlModal, setShowUrlModal }) => {


    const selectedSurvey = useSelector((state) => state.survey.selectedSurvey);


    const [surveyUrl, serSurveyUrl] = useState('');

    const submit = () => {

    }


    return (

        <Modal
            show={showUrlModal}
            // dialogClassName="modal-90w"
            backdrop="static"
            onHide={() => {
                setShowUrlModal(false);
                // serSurveyUrl('')
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Survey URL</Modal.Title>
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body >
                    <Container
                        fluid
                        // className='box'
                        style={{
                            paddingBottom: '15px',
                            marginBottom: '15px',
                            wordBreak: 'break-all'
                        }}>
                        <Row>
                            <Col>

                                <span className='question'>{selectedSurvey?.url}   </span>

                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <input
                        value="Copy"
                        type='button'
                        onClick={() => navigator.clipboard.writeText(selectedSurvey.url)}
                        className='card-button'
                    />
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default SurveyURLModal;
