import React from 'react'

export const SubthemeDropdown = ({
    column
}) => {
    // Calculate the options for filtering
    // using the preFilteredRows

    const { filterValue, setFilter, preFilteredRows, id } = column


    //TRIED PASSING ID DIDNT WORK, need something else that filters

    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {


            // if (row.original.parent_theme) {
            //     options.add(row.original.parent_theme)
            // }
            // if (!row.original.parent_theme && row.original.theme) {
            //     options.add(row.original.theme)
            // }
            if (row.original.theme) {
                options.add(row.original.theme)
            }

        })
        return [...options.values()]
    }, [preFilteredRows])



    return (

        <select
            className='formControl'
            value={filterValue}
            onChange={e => {


                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (

                // value={JSON.stringify(option)}
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

