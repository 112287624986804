import { updateSurveyDetails, removeSurvey } from './survey.utils';


const INITIAL_STATE = {
	surveys: [],
	selectedSurvey: null,
	newSurveyQsOrder: [],
	selectedSurveyQsOrder: []
};

const surveyReducer = (state = INITIAL_STATE, action) => {


	switch (action.type) {
		case 'SET_SURVEYS':
			return {
				...state,
				surveys: action.payload,
			};
		case 'SELECT_SURVEY':
			return {
				...state,
				selectedSurvey: action.payload,
			};
		case 'ADD_NEW_SURVEY':
			return {
				...state,
				surveys: [action.payload, ...state.surveys],
			};
		case 'DELETE_SURVEY':
			return {
				...state,
				surveys: removeSurvey(state.surveys, action.payload),
				// selectedSurvey: action.payload
			};
		case 'UPDATE_SURVEY_DETAILS':
			return {
				...state,
				surveys: updateSurveyDetails(state.surveys, action.payload),
				selectedSurvey: action.payload
			};

		default:
			return state;
	}
};

export default surveyReducer;
