import React, { useEffect } from 'react';
import axios from "../../misc/modifiedAxios";

import { useState } from 'react';
import '../QM/qm.styles.scss'
import { useDispatch, useSelector } from 'react-redux';
import SideNavBar from '../../components/Shared/SideNavBar/sidenavbar.component';
import ThemeSelection from '../../components/QM-Components/ThemeSelection/theme-selection.component';
import ThemeQuestionsDisplay from '../../components/QM-Components/ThemeQuestionsDisplay/theme-questions-display.component';
import { useHistory } from "react-router-dom";
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/

const QM = () => {

    const [themes, setThemes] = useState([])



    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()

    const history = useHistory();

    const organisationId = useSelector((state) => state.user.organisationId);

    useEffect(() => {

        const jwt = localStorage.getItem('jwt')
        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change

        if (!jwt) {
            localStorage.removeItem('jwt')
            localStorage.removeItem('organisationId')
            history.push("/")
        }


    }, []);


    useEffect(() => {
        const getThemes = async () => {
            try {

                setIsLoading(true)
                const response = await axios.get(
                    `/themes?ogs=${organisationId}`,
                ).catch(
                    (error) => {
                        if (error.response.status === 403) {
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')
                        }

                        if (error.response.status === 401) {

                            alert("Session has expired, please re-login.")
                            localStorage.removeItem('jwt')
                            localStorage.removeItem('organisationId')
                            history.push('/')

                        }
                    }
                    // <ErrorCatch />
                )


                    ;
                if (response.data) {
                    setThemes(response.data)
                    setIsLoading(false)
                }

            } catch (err) {
                console.log(err)
            }
        };


        getThemes();
    }, [history, organisationId]);

    useEffect(() => {

        // if cohorts is not empty then run a dispatch, otherwise useEffect will shoot twice the dispatch 1 on empty 1 on change
        // if (cohorts.length > 0) {



        const setTHemesFromRequest = () => {
            dispatch({
                type: 'SET_THEMES',
                payload: themes
            })
        }

        setTHemesFromRequest();
        // }

        setIsLoading(false)

    }, [themes, dispatch]);




    return (
        <div className='qm-page'>
            <>
                <SideNavBar />
                {isLoading ? (
                    <div className='spinner'>
                        <SpinnerCircularFixed
                            size={60}
                            thickness={140}
                            speed={120}
                            color='var(--spinner-color)'
                            secondaryColor='rgb(240, 240, 240)
												'
                        />
                        <div className='spinner-text'>
                            <span>Loading Question Manager...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <ThemeSelection />
                        <ThemeQuestionsDisplay />
                    </>)}
            </>

        </div>
    );
};

export default QM;