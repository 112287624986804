import React, { useState } from 'react';

import './theme-question-item.styles.scss';
import axios from "../../../misc/modifiedAxios";
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import editIcon from '../../../assets/editicon.svg'
import addIcon from '../../../assets/addcirclebutton.svg'
import label from '../../../assets/Frame.svg'
import ChildThemeSelectItem from './ChildThemeSelectItem/child-themeselect-item.component';
import Accordion from 'react-bootstrap/Accordion';
import CreateSubthemeModal from '../../Theme-Components/CreateSubthemeModal/create-subtheme-modal.component'
import ThemeItemName from '../cohortItemName/themeitemname.component';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';


function dateFormat(date) {
    var setdate = new Date(date)

    return setdate.toLocaleDateString()
}

function ThemeSelectItem({ theme, setEditShowModal, setSubthemeModal }) {

    const { id, theme_name, createdAt, theme_desc, child_themes, sys_default } = theme

    const [showCreateSubtheme, setShowCreateSubtheme] = useState(false);

    const selectedTheme = useSelector((state) => state.theme.selectedTheme);
    const selectedChildTheme = useSelector((state) => state.theme.selectedChildTheme);

    const history = useHistory();

    const dispatch = useDispatch()

    const selectTheme = async () => {


        if (selectedTheme?.id === id) {

            console.log('selectedThemeID', selectedTheme?.id, id)

            dispatch({
                type: 'SELECT_THEME',
                payload: selectedTheme
            })

            dispatch({
                type: 'SELECT_CHILD_THEME',
                payload: null
            })

            return
        }

        dispatch({
            type: 'SELECT_THEME',
            payload: { id, theme_name, theme_desc, createdAt, child_themes, type: 'regular' }
        })

        dispatch({
            type: 'SELECT_CHILD_THEME',
            payload: null
        })


    }


    return (
        <>
            <Accordion.Item eventKey={id} >
                <Accordion.Header onClick={selectTheme}>
                    <div className="theme-item-in-accordion" >
                        <div className="theme-icon" style={{ paddingRight: '15px' }}>
                            <img style={{ height: '2.5em', width: '2.5em' }} src={label} />
                        </div>
                        <div className="overlap-group-regular-theme">
                            <div className="theme-item-in-accordion-name-edit-button-container">
                                <span className="theme-item-in-accordion-name">
                                    {theme_name}
                                </span>
                                {!sys_default ? <img src={editIcon} alt='edit_button' style={{ height: '1.3em', paddingLeft: '10px' }} onClick={(event) => {
                                    event.stopPropagation()
                                    selectTheme()
                                    setEditShowModal(true);
                                }} /> : null}
                            </div>
                            <span className="theme-item-in-accordion-description">
                                {theme_desc}
                            </span>
                            <div className='theme-created-date'><span style={{ fontWeight: '500' }}>Created: </span>{dateFormat(createdAt)}</div>


                        </div>

                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="add-question-button-container" >
                        <img style={{ height: '1.5em' }} src={addIcon} alt='add_button' onClick={() => {

                            setShowCreateSubtheme(true)
                        }} />
                        <span className="add-button-text" style={{ fontSize: '16px' }}>Create New Subtheme</span>
                    </div>
                    <div>

                        {child_themes?.length > 0 ? child_themes.map(child_theme => {

                            //PUT INTO COMPONENT
                            //unassigned child theme has no id so passing in name as key
                            return (

                                <ChildThemeSelectItem
                                    key={child_theme.id}
                                    childTheme={child_theme}
                                    setSubthemeModal={setSubthemeModal}
                                    selected={selectedChildTheme?.id === child_theme.id ? true : false}
                                />
                            )
                        })

                            : null}
                    </div>
                </Accordion.Body>
            </Accordion.Item >
            <CreateSubthemeModal
                setShowModal={setShowCreateSubtheme}
                showModal={showCreateSubtheme}
                themeId={id}
            />

        </>

    );
}

export default ThemeSelectItem;

