import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import styled from 'styled-components'
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect } from 'react-table'
import { Modal, Button } from 'react-bootstrap';
import './survey-table.styles.scss'
import axios from "../../../misc/modifiedAxios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAltSquare } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle, faTrash, faGripLines, faEdit } from '@fortawesome/free-solid-svg-icons';
import { SpinnerCircularFixed } from 'spinners-react'; //https://adexin.github.io/spinners/
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import { ColumnFilter } from '../../Question-Components/QuestionTable/column-filter.component';
import { DropdownFilter } from './survey-status-dropdown-filter.component';


import editIcon from '../../../assets/editicon.svg'
import trashIcon from '../../../assets/trashicon.svg'


const Styles = styled.div`
padding: 1rem;

overflow-x: auto;
table {
  width:95%;
  border-spacing: 0;
  // border: 1px solid black;
  }
 tr:nth-child(even){background-color: #f2f2f2;}
 tr:hover {background-color: #ddd;}

  th{
    //   border-bottom: 1px solid black;
      width: 5em
  },

  td {
    margin: 0;
    padding: 0.5rem;
  //   border-bottom: 1px solid black;
  //   border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
`



function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    }, useFilters, useGlobalFilter, // useGlobalFilter!
        usePagination, useRowSelect)

    // Render the UI for your table
    return (
        <table {...getTableProps()}>

            <thead style={{ borderBottom: '0px' }}>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps({
                                style: { minWidth: column.minWidth, width: column.width },
                            })}>{column.render('Header')}
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td  {...cell.getCellProps({

                                    // style: {
                                    //     minWidth: cell.column.minWidth,
                                    //     width: cell.column.width,
                                    // },
                                })}><div className='td-customized' >{cell.render('Cell')}</div></td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
const deleteSurvey = async (e, history, dispatch, setisLoading) => {

    const surveyId = e.id

    try {
        let response

        setisLoading(true)

        response = await axios.delete(
            `/surveys/${surveyId}`).catch(

                (error) => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')
                    }

                    if (error.response.status === 401) {

                        alert("Session has expired, please re-login.")
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('organisationId')
                        history.push('/')

                    }
                    // <ErrorCatch error={error} />
                })
            ;




        dispatch({
            type: 'DELETE_SURVEY',
            payload: response.data.data
        })

        setisLoading(false)

    }
    catch (err) {
        console.log('error', err)
        // err && setError('Internal error, please contact survey admin');
        window.alert('Internal error, please contact admin');
    }


}

function SurveyTable({ surveys, setEditModal, setShowUrlModal }) {

    const dispatch = useDispatch()
    const history = useHistory()

    const [survey, setSurvey] = useState(null)

    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {


        if (survey !== null) {
            const selectSurvey = () => {



                dispatch({
                    type: 'SELECT_SURVEY',
                    payload: survey
                })
            }

            selectSurvey();
        }

    }, [dispatch, survey]);


    const columnsReady = React.useMemo(

        () => [
            {
                Header: 'Name',
                accessor: 'survey_name',
                maxWidth: 70,
                minWidth: 50,
                width: 60,
                Filter: ColumnFilter,
            },
            {
                Header: 'Description',
                accessor: 'survey_desc',
                maxWidth: 70,
                minWidth: 50,
                width: 60,
                Filter: ColumnFilter,
            },
            // {
            //     Header: 'Start Date',
            //     accessor: 'start_date',
            //     Cell: ({ cell }) => {

            //         if (cell.row.original.start_date) {


            //             var date = new Date(cell.row.original.start_date);
            //             var dd = String(date.getDate()).padStart(2, '0');
            //             var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            //             var yyyy = date.getFullYear();

            //             date = dd + '-' + mm + '-' + yyyy;


            //             // var date = new Date(cell.row.original.start_date).toISOString()
            //             //CUTS OFF TIME
            //             // date = cell.row.original.start_date.slice(0, 10)
            //             // date = date.split("-").reverse().join("-");
            //             return date
            //         }
            //         return null

            //     }
            // },
            // {
            //     Header: 'End Date',
            //     accessor: 'end_date',
            //     Cell: ({ cell }) => {

            //         if (cell.row.original.end_date) {

            //             var date = new Date(cell.row.original.end_date);
            //             var dd = String(date.getDate()).padStart(2, '0');
            //             var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            //             var yyyy = date.getFullYear();

            //             date = dd + '-' + mm + '-' + yyyy;

            //             return date
            //         }
            //         return null

            //     }
            // },
            {
                Header: 'Status',
                accessor: 'status',
                maxWidth: 70,
                minWidth: 50,
                width: 60,
                Filter: DropdownFilter,
                Cell: ({ cell }) => {


                    if (cell.row.original.status) {

                        if (cell.row.original.status === 'live') {
                            return (
                                <div className='status-container'>
                                    <span style={{ color: 'var(--live)' }}>Live</span>
                                    <span className="dot-live"></span>
                                </div>)
                        }

                        if (cell.row.original.status === 'draft') {
                            return (
                                <div className='status-container'>
                                    <span style={{ color: 'var(--draft)', opacity: 'var(--draft-opacity)' }}>Draft</span>
                                    <span className="dot-draft"></span>
                                </div>)

                        }

                        if (cell.row.original.status === 'closed') {
                            return (
                                <div className='status-container'>
                                    <span style={{ color: 'var(--closed)' }}>Closed</span>
                                    <span className="dot-closed"></span>
                                </div>)
                        }


                    }
                    return null

                }
            },

            {
                Header: ' ',
                Filter: false,
                maxWidth: 70,
                minWidth: '40px',
                width: '60px',
                Cell: ({ cell }) => {


                    return <input
                        value="Share"
                        type='button'
                        onClick={() => {
                            setSurvey(cell.row.original)

                            setShowUrlModal(true)
                        }}
                        className='card-share-button'
                    />

                }
            },
            {
                Header: '  ',
                Filter: false,
                maxWidth: 70,
                minWidth: '40px',
                width: '60px',
                Cell: ({ cell }) => {

                    return (<div className="create-theme-button-container" >
                        <img src={editIcon} className="edit-theme-button" alt='edit_button' onClick={() => {
                            setSurvey(cell.row.original)

                            setEditModal(true)
                        }} />

                    </div>)

                }
            },

            {
                Header: '   ',
                Filter: false,
                maxWidth: 70,
                minWidth: '40px',
                width: '60px',
                // defaultCanFilter: true,
                Cell: ({ cell }) => {


                    return (<div className="create-theme-button-container" >
                        <img src={trashIcon} alt='trash_button' className="remove-theme-button" style={{ height: "26px", width: "26px" }} onClick={() => {

                            window.confirm("Are you sure you wish to delete this item?") &&
                                deleteSurvey(cell.row.original, history, dispatch, setisLoading)
                        }} />
                    </div>)


                }
            }

        ],
        [setEditModal, setShowUrlModal]
    )


    return (


        isLoading ? (
            <div className='spinner'>
                <SpinnerCircularFixed
                    size={60}
                    thickness={140}
                    speed={120}
                    color='var(--spinner-color)'
                    secondaryColor='rgb(240, 240, 240)
                                            '
                />
                <div className='spinner-text'>
                    <span>Deleting Survey...</span>
                </div>
            </div>
        )
            : (

                <Styles>
                    <Table
                        columns={columnsReady}
                        data={surveys}
                    />
                </Styles>
            )
    )
}

export default SurveyTable
