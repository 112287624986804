import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';




import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import './organisation-display.styles.scss';
import CreateOrganisationModal from '../OrganisationCreateModal/create-organisation-modal.component';
import { SpinnerCircularFixed } from 'spinners-react';
import OrganisationTable from '../organisation-table.component'
import EditOrganisationModal from '../OrganisationEditModal/edit-organisation-modal-component';

const OrganisationDisplay = () => {

    const [isLoading, setisLoading] = useState(false);



    const user = useSelector((state) => state.user.user);

    const { organisations } = user
    const formatedOrganisations = organisations.map(x => {
        return {
            org_name: x.org_name,
            id: x.id
        }
    })



    const [showCreateModal, setCreateShowModal] = useState(false);
    const [showEditModal, setEditModal] = useState(false);
    const [showUrlModal, setShowUrlModal] = useState(false);


    return (

        <div className='survey-display'>


            <h1 className='survey-display-header'>
                Organisations
            </h1>
            <div className="create-theme-button-container" >
                <FontAwesomeIcon icon={faPlusCircle} className="add-theme-button" onClick={() => {
                    setCreateShowModal(true);
                }} />
                <span className="add-theme-text">Create New Organisation</span>
            </div>
            {/* {isLoading ? (
                <div className='spinner'>
                    <SpinnerCircularFixed
                        size={60}
                        thickness={140}
                        speed={120}
                        color='var(--spinner-color)'
                        secondaryColor='rgb(240, 240, 240)
												'
                    />
                    <div className='spinner-text'>
                        <span>Loading Organisations...</span>
                    </div>
                </div>
            ) : ( */}
            <OrganisationTable organisations={organisations}
                setEditModal={setEditModal}
                setShowUrlModal={setShowUrlModal}
            />
            {/* )} */}
            <CreateOrganisationModal
                setShowModal={setCreateShowModal}
                showModal={showCreateModal}
            />
            {showEditModal ? <EditOrganisationModal
                setEditModal={setEditModal}
                showEditModal={showEditModal} /> : null}

        </div >
    );

};

export default OrganisationDisplay;




